import { Flex, Text, Button, useToast, Skeleton } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import moment from "moment";
import { theme } from "../../partials/utils/Contants";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";
import { useStore } from "../../store/store";
import { downloadRFQ } from "../../partials/utils/Contants/useDownload";

const BidsList = ({ id }: { id: string }) => {
  const { selectedCompany } = useStore();
  const toast = useToast();
  const [bidslIst, setBidsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  function fetchBidsList() {
    setIsLoading(true);
    useFetch
      .get(`api/${selectedCompany}/get-interest/?bid_id=${id}`)
      .then((res) => {
        setBidsList(res?.data);
      })
      .catch((error) => console.log(error))
      .finally(() => setIsLoading(false));
  }
  const handleAction = (bidId: number, status: number) => {
    useFetch
      .post(
        `api/${selectedCompany}/${
          status === 1 ? "accept-interest" : "reject-interest"
        }/${bidId}/`
      )
      .then((res) => {
        fetchBidsList();
        toast({
          // title: `Accepted Successfully`,
          title: `Interest ${
            status === 1 ? "Accepted" : "Rejected"
          } Successfully`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Something went wrong",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  useEffect(() => {
    fetchBidsList();
  }, [id]);
  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      {isLoading ? (
        <Skeleton height="110px" w="100%" borderRadius="16px" />
      ) : bidslIst?.length ? (
        <Flex w="100%" direction="column" gap="20px">
          {bidslIst?.map((item: any) => (
            <Flex
              align="center"
              key={item?.id}
              minH="130px"
              pos="relative"
              shadow="md"
            >
              <Flex
                zIndex="1"
                direction="column"
                gap="12px"
                width={item?.status !== "pending" ? "90%" : "100%"}
                className="bid-card-selected"
                padding="12px"
                position="relative"
                height="100%"
                minH="130px"
                borderRadius="21px"
                // cursor="pointer"
              >
                <Flex width="100%" align="center" gap="8px" wrap="wrap">
                  <Text
                    fontSize="15px"
                    fontWeight="700"
                    color="white"
                    // whiteSpace="nowrap"
                    cursor="default"
                  >
                    {item?.company_name}
                  </Text>
                  {item?.bidder_name && (
                    <Text
                      cursor="default"
                      fontSize="15px"
                      fontWeight="700"
                      color="rgba(255, 255, 255, 0.5)"
                    >
                      | {item?.bidder_name}
                    </Text>
                  )}
                </Flex>
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  color="rgba(255, 255, 255, 1)"
                >
                  Request on{" "}
                  {item?.interest_date
                    ? moment(item?.interest_date).format("D MMMM, yyyy")
                    : "N/A"}{" "}
                </Text>
                {item?.status === "pending" && (
                  <Flex width="100%" justify="space-between" align="center">
                    {/* {item?.rfq ? (
                    <Flex
                      align="center"
                      gap="8px"
                      color="white"
                      fontSize="12px"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        downloadRFQ(selectedCompany, item?.rfq);
                      }}
                    >
                      <PdfIcon />
                      <Text>RFQ</Text>
                    </Flex>
                  ) : (
                    <Flex minW="20px" />
                  )} */}

                    <Button
                      colorScheme="green"
                      bg={theme.color.GREEN}
                      borderRadius="21px"
                      size="xs"
                      fontSize="10px"
                      px="15px"
                      onClick={() => handleAction(item?.id, 1)}
                    >
                      Approve
                    </Button>

                    <Button
                      colorScheme="white"
                      bg={theme.color.WHITE}
                      borderRadius="21px"
                      size="xs"
                      fontSize="10px"
                      px="15px"
                      color="black"
                      onClick={() => handleAction(item?.id, 2)}
                    >
                      Decline
                    </Button>
                  </Flex>
                )}
              </Flex>
              {item?.status !== "pending" && (
                <Flex
                  height="100%"
                  bg={item?.status === "accepted" ? theme.color.GREEN : "red"}
                  position="absolute"
                  right="0px"
                  w="100%"
                  borderRadius="21px"
                  align="center"
                  justify="end"
                  cursor="pointer"
                  transition="background-color 1s ease-in, width 0.3s ease-in"
                  padding="0px"
                >
                  <Flex
                    transform="rotate(-90deg)"
                    position="relative"
                    align="end"
                    justify="center"
                  >
                    <Text
                      color="white"
                      fontSize="12px"
                      fontWeight="600"
                      whiteSpace="nowrap"
                      pos="absolute"
                      textAlign="end"
                      pb="5px"
                      textTransform="capitalize"
                    >
                      {item?.status}
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          ))}
        </Flex>
      ) : (
        <Text color="white">No Records found</Text>
      )}
    </Flex>
  );
};

export default BidsList;
{
  /* <Tr key={item?.id}>
<Td>{id}</Td>
<Td>{item?.company_name}</Td>
<Td>{item?.product}</Td>
<Td>{item?.sum_insured}</Td>
<Td>{item?.bidder_name || " - "}</Td>
<Td>
  {item?.start_date
    ? moment(item?.start_date).format("MMMM DD,yyyy")
    : "-"}
</Td>
<Td>
  {item?.bid_status === 1 ? (
    <Badge colorScheme="green">Accepted</Badge>
  ) : item?.bid_status === 2 ? (
    <Badge colorScheme="red">Rejected</Badge>
  ) : (
    <Flex align="center" gap="8px">
      <Button
        bg="green"
        padding="5px"
        borderRadius="10px"
        colorScheme="green"
        onClick={() => handleAction(item?.id, 1)}
      >
        <Icon
          as={FaRegThumbsUp}
          fontSize="20px"
          color="white"
        />
      </Button>
      <Button
        bg="red"
        padding="5px"
        borderRadius="10px"
        colorScheme="red"
        onClick={() => handleAction(item?.id, 2)}
      >
        <Icon
          as={FaRegThumbsDown}
          fontSize="20px"
          color="white"
        />
      </Button>
    </Flex>
  )}
</Td>
</Tr> */
}
