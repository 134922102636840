import { ChangeEvent, useEffect, useRef, useState } from "react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import {
  Flex,
  Icon,
  Input,
  Text,
  Spinner,
  useToast,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useLocation } from "react-router-dom";
import FileThumnails from "../../components/Thumbnails/FileThumnails";
import { theme } from "../../partials/utils/Contants";
import { CiSearch } from "react-icons/ci";
import { useStore } from "../../store/store";

const CreatedFolderListing = () => {
  const toast = useToast();
  const { selectedCompany } = useStore();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const fetchData = useFetch;
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState<any>();
  const [name, setName] = useState<any>("");
  const [file, setFile] = useState<any>();
  const [defaultList, setDefaultList] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const filteredList = searchQuery
    ? defaultList?.filter((item: any) =>
        item?.path?.toLowerCase().includes(searchQuery)
      )
    : defaultList;
  useEffect(() => {
    const fetchListing = async () => {
      setIsLoading(true);
      const searchParams = new URLSearchParams(search);
      const idValue = searchParams.get("id");
      setId(idValue);
      const nameURL = searchParams.get("name");
      setName(nameURL);
      if (idValue && selectedCompany) {
        fetchListingData(idValue);
      }
    };

    fetchListing();
  }, [search, selectedCompany]);
  function fetchListingData(id: string) {
    fetchData(`api/${selectedCompany}/folder-details/?folder_id=${id}`)
      .then((response) => {
        setIsLoading(false);

        setDefaultList(response?.data?.documents);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const allowedTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // .xlsx
    "application/pdf", // .pdf
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ]; // .docx

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      if (!allowedTypes.includes(file?.type)) {
        toast({
          title: `Invalid file type.`,
          description: "Please upload .xlsx, .pdf, or .docx files only.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setFile(null);
        return;
      }
      setFile(file);

      const formData = new FormData();
      formData.append("file", file);
      // formData.append("category", name);

      try {
        const response = await useFetch.post(
          `api/${selectedCompany}/folders/upload-document/?folder_id=${id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        fetchListingData(id);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const downloadFile = async (id: string, name: string, extension: string) => {
    try {
      const response = await useFetch.get(
        `api/${selectedCompany}/documents-download/?doc_id=${id}`
      );
      const presignedUrl = response.data?.presigned_url;

      if (presignedUrl) {
        const link = document.createElement("a");
        link.href = presignedUrl;
        link.download = "";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  return (
    <Flex
      direction="column"
      w="100%"
      gap="30px"
      padding="35px"
      overflowY="auto"
    >
      <Flex w="100%" justify="space-between" align="center">
        <Text fontSize="30px" fontWeight="500" textTransform="capitalize">
          {name || ""}
        </Text>
        {/* <Flex align="center" gap="20px">
              <Button
                borderRadius="21px"
                bg="black"
                size="sm"
                color="white"
                colorScheme="black"
                _hover={{ filter: "opacity(0.75)" }}
                leftIcon={<IoCloudDownloadOutline />}
                //   onClick={onOpen}
                //   ref={btnRef}
                fontSize="0.9rem"
                fontWeight="500"
              >
                Claim Form
              </Button>
              <Button
                borderRadius="21px"
                bg="black"
                size="sm"
                color="white"
                colorScheme="black"
                _hover={{ filter: "opacity(0.75)" }}
                leftIcon={<IoCloudDownloadOutline />}
                fontSize="0.9rem"
                fontWeight="500"
                //   onClick={onOpen}
                //   ref={btnRef}
              >
                Zip all
              </Button>
            </Flex> */}
      </Flex>
      <Flex position="relative" w="100%">
        <InputGroup>
          <InputLeftElement>
            <CiSearch />
          </InputLeftElement>
          <Input
            borderRadius="16px"
            type="text"
            w="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            placeholder="Search"
            fontSize="12px"
            fontWeight="500"
            value={searchQuery}
            onChange={handleSearch}
          />
        </InputGroup>
      </Flex>
      <Flex w="100%" justify="space-between">
        <Text fontSize="24px" fontWeight="500">
          Files
        </Text>
        {/* <Menu>
              <MenuButton
                as={Button}
                rightIcon={<IoIosArrowDown />}
                borderRadius="16px"
                bg="white"
                border="1px solid #dadada"
                _active={{
                  bg: "black",
                  color: "white",
                  border: "1px solid black",
                }}
              >
                Filter
              </MenuButton>
              <MenuList
                bg="black"
                color="white"
                padding="25px 5px 15px 15px"
                gap="20px"
                borderRadius="16px"
              >
                <Text p="0px 10px 10px 15px">Filter by:</Text>
                {["By Entity", "By Policy", "By Bid"].map((item) => (
                  <MenuItem
                    bg="black"
                    color="rgba(246, 246, 246, 0.50)"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    <Flex align="center" justify="space-between" gap="8px" w="100%">
                      <Text fontSize="14px" fontWeight="500">
                        {item}
                      </Text>
                      <Icon as={IoIosArrowForward} fontSize="12px" />
                    </Flex>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu> */}
      </Flex>
      {!isLoading && filteredList?.length ? (
        <Flex wrap="wrap" gap="24px" justifyContent="flex-start" width="100%">
          {filteredList?.map((item: any) => {
            const extension = item?.path?.split(".");
            const name = item?.path?.split("/");
            return (
              <FileThumnails
                key={item.id}
                name={name[2]}
                data={item}
                type={extension[1]}
                handleClick={downloadFile}
                id={item?.id}
                handleDownload={downloadFile}
                mapType="created"
              />
            );
          })}
          <Flex
            w="244px"
            height="180px"
            borderRadius="12px"
            border="1px dotted #6E6E6E"
            padding="16px"
            gap="20px"
            direction="column"
            align="center"
            color={theme.color.WHITE}
            bg="black"
            onClick={handleUploadClick}
            cursor="pointer"
          >
            <input
              ref={fileInputRef}
              style={{ display: "none" }}
              type="file"
              onChange={handleFileChange}
              accept="*"
            />
            <Text fontWeight="500" fontSize="16px">
              Upload Files
            </Text>
            <Icon as={IoCloudDownloadOutline} fontSize="28px" />
            <Text fontWeight="400" fontSize="10px" textAlign="center">
              Click to browse or drag and drop your files here to upload. Max
              file size: 25 MB.{" "}
            </Text>
          </Flex>
        </Flex>
      ) : (
        <Flex w="100%" align="center" gap="12px">
          {isLoading ? (
            <Spinner />
          ) : (
            <Flex
              w="244px"
              height="180px"
              borderRadius="12px"
              border="1px dotted #6E6E6E"
              padding="16px"
              gap="20px"
              direction="column"
              align="center"
              color={theme.color.WHITE}
              bg="black"
              onClick={handleUploadClick}
              cursor="pointer"
            >
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                onChange={handleFileChange}
                accept="*"
              />
              <Text fontWeight="500" fontSize="16px">
                Upload Files
              </Text>
              <Icon as={IoCloudDownloadOutline} fontSize="28px" />
              <Text fontWeight="400" fontSize="10px" textAlign="center">
                Click to browse or drag and drop your files here to upload. Max
                file size: 25 MB.{" "}
              </Text>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default CreatedFolderListing;
