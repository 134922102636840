import * as XLSX from "xlsx";
import {
  Box,
  FormControl,
  Input,
  Select,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
  Text,
  VStack,
  HStack,
  Flex,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";
import { FaCloudDownloadAlt, FaInfoCircle } from "react-icons/fa";
import { useStore } from "../../../store/store";
import "../form.css";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { useEffect, useState } from "react";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";
interface FormValues {
  insured: string;
  Want_to_cover_parents: string;
  employeenumber: number;
  spousenumber: number;
  kidsnumber: number;
  policytype: string;
  insurer: string;
  comp_location: string;
  coverage_type: string;
  coverage_type_name_other?: string;
  sum_insured: number;
  payment_mode: string;
  previous_insurer?: string; // Optional
  previous_policy_expiry?: string;
  sum_insured_category: string;
  base_sum_insured: string | number;
  TTD: string;
  Age_Limit: number;
  Medical_Expenses: string;
  Education_Fund_for_child: number;
  Ambulance_Charges: number;
  Accidenatal_burns: number;
  Accidenatal_Broken_burns: number;
  Funeral_Expenses: number;
  Compassionate_visit: number;
  Jurisdiction: string;
  AD: string;
  PTD: string;
  Terrorism: string;
  start_date: string;
  end_date: string;
  policy_start_date: string;
  policy_end_date: string;
  expected_premium: number;
  claim_data: any;
  emp_data?: FileList | File;
}
const GroupPersonalAccident = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useToast();
  const { step, setStep } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      sum_insured_category: "flat",
      coverage_type: "Flat cover",
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      Age_Limit: 60,
    },
  });
  const onSubmit = async (data: any) => {
    if (
      !!(watch("sum_insured_category") === "specific") &&
      !watch("emp_data")
    ) {
      setError("emp_data", { message: "Employee Information is required" });
      return;
    }
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });
    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    try {
      setIsSubmitting(true);
      if (editData?.id) {
        const response = await useFetch.put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCreatedBid(response.data?.data?.id);
        toast({
          title: `Bid updated successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setStep(5);
      } else {
        const response = await useFetch.post(
          `/api/${selectedCompany}/store-bid/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        setCreatedBid(response.data?.id);
        toast({
          title: `Bid ${editData?.id ? "updated" : "created"} successfully`,
          description: "Bid will be in draft till confirmation",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        setStep(5);
      }
    } catch (error: any) {
      console.error("Error:", error);

      toast({
        title: error?.response?.data?.detail || "Something Went wrong",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const file = e.target.files ? e.target.files[0] : null;

    if (!file) return;
    setValue("emp_data", file);
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>): void => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: "array" });

      const sheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(sheet);

      let totalSumInsured = 0;
      let employeeCount = 0;

      // Loop through all rows in the JSON data
      jsonData.forEach((row: any) => {
        const relationShip = row?.Relationship;
        const sumInsured = row?.["Sum Insured"];

        if (relationShip === "Employee" && sumInsured && !isNaN(sumInsured)) {
          totalSumInsured += parseFloat(sumInsured);
          employeeCount++;
        }

        if (relationShip === "Employee") {
          setValue("insured", "Employee");
        } else if (relationShip === "Spouse") {
          setValue("insured", "Employee & Spouse");
        } else if (relationShip === "Kids") {
          setValue("insured", "Employee ,Spouse & Kids");
        }
      });
      setValue("sum_insured_category", "specific");
      setValue("employeenumber", employeeCount);
      setValue("base_sum_insured", "0");
      setValue("sum_insured", totalSumInsured);
    };

    reader.readAsArrayBuffer(file);
  };
  const baseSumInsured = +watch("base_sum_insured");
  const employeeNumber = +watch("employeenumber");
  useEffect(() => {
    if (!watch("emp_data") || watch("sum_insured_category") !== "specific") {
      const sum = baseSumInsured * employeeNumber;
      setValue("sum_insured", sum);
    }
  }, [baseSumInsured, employeeNumber]);

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack gap={4} align="stretch">
          {/* Insured */}
          <HStack gap={4}>
            <FormControl id="insured" isInvalid={Boolean(errors?.insured)}>
              <HoverableFormLabel>
                Whom do you want to insure?*
              </HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("insured", {
                  required: "Insured is required",
                })}
                placeholder="Whom do you want to insure ?"
              >
                <option value="Employee">Employee</option>
                <option value="Employee & Spouse">Employee & Spouse</option>
                <option value="Employee ,Spouse & Kids">
                  Employee ,Spouse & Kids
                </option>
              </Select>
              {errors?.insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.insured?.message}
                </Text>
              )}
            </FormControl>

            {/* Want to cover parents */}
            <FormControl
              id="Want_to_cover_parents"
              isInvalid={Boolean(errors?.Want_to_cover_parents)}
            >
              <HoverableFormLabel>Want to cover parents?*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("Want_to_cover_parents", {
                  required: "This field is required",
                })}
                defaultValue="No"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
              {errors?.Want_to_cover_parents && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Want_to_cover_parents?.message}
                </Text>
              )}
            </FormControl>
          </HStack>

          <HStack gap={4}>
            {/* Employee Count */}
            <FormControl
              id="employeenumber"
              isInvalid={Boolean(errors?.employeenumber)}
            >
              <HoverableFormLabel>Employee Count*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("employeenumber", {
                  required: "Employee count is required",
                })}
              />
              {errors?.employeenumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.employeenumber?.message}
                </Text>
              )}
            </FormControl>

            {/* Spouse Count */}
            <FormControl
              id="spousenumber"
              isInvalid={Boolean(errors?.spousenumber)}
            >
              <HoverableFormLabel>
                {`Spouse Count
            ${
              watch("insured") === "Employee & Spouse" ||
              watch("insured") === "Employee ,Spouse & Kids"
                ? "*"
                : ""
            }`}
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("spousenumber", {
                  required:
                    watch("insured") === "Employee & Spouse" ||
                    watch("insured") === "Employee ,Spouse & Kids"
                      ? "Spouse count is required"
                      : false,
                })}
              />
              {errors?.spousenumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.spousenumber?.message}
                </Text>
              )}
            </FormControl>
          </HStack>

          <HStack gap={4}>
            {/* Kids Count */}
            <FormControl
              id="kidsnumber"
              isInvalid={Boolean(errors?.kidsnumber)}
            >
              <HoverableFormLabel>
                {`Kids Count${
                  watch("insured") === "Employee ,Spouse & Kids" ? "*" : ""
                }`}
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("kidsnumber")}
              />
              {errors?.kidsnumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.kidsnumber?.message}
                </Text>
              )}
            </FormControl>
            {/* Policy Type */}
            <FormControl
              id="policytype"
              isInvalid={Boolean(errors?.policytype)}
            >
              <HoverableFormLabel>Policy Type*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("policytype", {
                  required: "Policy type is required",
                })}
                placeholder="Select policy type"
              >
                <option value="New">New</option>
                <option value="Renewal">Renewal</option>
              </Select>
              {errors?.policytype && (
                <Text color="red.500" fontSize="sm">
                  {errors?.policytype?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
          <HStack gap={4}>
            {/* Bidder Preference */}
            <FormControl id="insurer" isInvalid={Boolean(errors?.insurer)}>
              <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("insurer", {
                  required: "Bidder preference is required",
                })}
                placeholder="Preference"
              >
                <option value="Both">Both</option>
                <option value="Brokers">Brokers</option>
                <option value="INS_COMP">Insurance Company</option>
              </Select>
              {errors?.insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.insurer?.message}
                </Text>
              )}
            </FormControl>
            {/* Company Location */}
            <FormControl
              id="comp_location"
              isInvalid={Boolean(errors?.comp_location)}
            >
              <HoverableFormLabel>
                Where is your company located?*
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("comp_location", {
                  required: "Company location is required",
                })}
              />
              {errors?.comp_location && (
                <Text color="red.500" fontSize="sm">
                  {errors?.comp_location?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
          {/* Benefits Section */}
          <Box borderTop="1px solid" mt={6} pt={4}>
            <Text fontWeight="bold" color="darkorchid">
              Optional coverage
            </Text>
          </Box>
          <HStack gap={4}>
            <FormControl isInvalid={Boolean()}>
              <HoverableFormLabel>Sum insured</HoverableFormLabel>
              <Controller
                name="sum_insured_category"
                control={control}
                rules={{ required: "This field is required" }}
                defaultValue="flat"
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      <Radio value="flat">Sum insured</Radio>
                      <Radio value="specific">
                        Sum insured based on category
                      </Radio>
                    </Stack>
                  </RadioGroup>
                )}
              />
              {watch("sum_insured_category") === "specific" ? (
                <Box style={{ marginTop: "7px" }}>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    type="number"
                    min={0}
                    defaultValue={0}
                    {...register("base_sum_insured")}
                    isDisabled={true}
                  />
                  {errors?.base_sum_insured && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.base_sum_insured?.message}
                    </Text>
                  )}
                </Box>
              ) : (
                <Box style={{ marginTop: "7px" }}>
                  <Controller
                    name="base_sum_insured"
                    rules={{ required: "This field is required" }}
                    control={control}
                    defaultValue=""
                    render={({ field }) => (
                      <Select
                        bg="white"
                        borderRadius="21px"
                        {...field}
                        isDisabled={
                          watch("sum_insured_category") === "specific"
                        }
                      >
                        <option value="" disabled>
                          Select employee sum insured
                        </option>
                        <option value="100000">100000</option>
                        <option value="200000">200000</option>
                        <option value="300000">300000</option>
                        <option value="400000">400000</option>
                        <option value="500000">500000</option>
                      </Select>
                    )}
                  />
                  {errors?.base_sum_insured && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.base_sum_insured?.message}
                    </Text>
                  )}
                </Box>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errors.TTD)}>
              <Flex align="start">
                <HoverableFormLabel>
                  Temporary Total Disability (TTD)
                </HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Disability which completely prevents Insured
person from carrying out all of the responsibilities associated with his line of work."
                  />
                )}
              </Flex>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("TTD", { required: true })}
                defaultValue="1% of S.I. Or Rs.5,000"
              />
            </FormControl>
          </HStack>

          <HStack gap={4}>
            <FormControl isInvalid={Boolean(errors.Age_Limit)}>
              <HoverableFormLabel>Age Limit*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                {...register("Age_Limit", {
                  required: "Age limit is required",
                })}
                defaultValue={60}
              />
              {errors?.Age_Limit && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Age_Limit?.message}
                </Text>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errors.Medical_Expenses)}>
              <HoverableFormLabel>Medical Expenses</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("Medical_Expenses", { required: true })}
                defaultValue="10% of SI or 40% of claim Amount or actual whichever less"
              />
            </FormControl>
          </HStack>

          <HStack gap={4}>
            <FormControl isInvalid={Boolean(errors.Education_Fund_for_child)}>
              <HoverableFormLabel>
                Education Fund for child (Upto 2)
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                {...register("Education_Fund_for_child", { required: true })}
                defaultValue={0}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.Ambulance_Charges)}>
              <HoverableFormLabel>Ambulance Charges*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                {...register("Ambulance_Charges", {
                  required: "Ambulance charges are required",
                })}
                defaultValue={0}
              />
              {errors?.Ambulance_Charges && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Ambulance_Charges?.message}
                </Text>
              )}
            </FormControl>
          </HStack>

          <HStack gap={4}>
            <FormControl isInvalid={Boolean(errors.Accidenatal_burns)}>
              <HoverableFormLabel>Accidenatal burns</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                {...register("Accidenatal_burns", { required: true })}
                defaultValue={15000}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.Accidenatal_Broken_burns)}>
              <HoverableFormLabel>Accidental Broken Bones</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                {...register("Accidenatal_Broken_burns", { required: true })}
                defaultValue={15000}
              />
            </FormControl>
          </HStack>

          <HStack gap={4}>
            <FormControl isInvalid={Boolean(errors.Funeral_Expenses)}>
              <HoverableFormLabel>
                Funeral Expenses & repatriation
              </HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                {...register("Funeral_Expenses", { required: true })}
                defaultValue={5000}
              />
            </FormControl>
            <FormControl isInvalid={Boolean(errors.Compassionate_visit)}>
              <Flex align="start">
                <HoverableFormLabel> Compassionate visit</HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Cover the cost of your loved one's transportation ,who come to visit you in the hospital and stay with you."
                  />
                )}
              </Flex>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                {...register("Compassionate_visit", { required: true })}
                defaultValue={25000}
              />
            </FormControl>
          </HStack>

          <Box borderTop="1px solid" mt={6} pt={4}>
            <Text fontWeight="bold" color="darkorchid">
              Base coverage
            </Text>
          </Box>

          <HStack gap={4}>
            <FormControl isInvalid={Boolean(errors.Jurisdiction)}>
              <Flex align="start">
                <HoverableFormLabel> Jurisdiction</HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Limitation of jurisdiction for your insurance policy"
                  />
                )}
              </Flex>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("Jurisdiction", { required: true })}
                defaultValue="Worldwide"
              />
            </FormControl>

            <FormControl isInvalid={Boolean(errors.AD)}>
              <HoverableFormLabel>Accidental Death (AD)</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("AD", { required: true })}
                defaultValue="100 % of Sum Insured"
              />
            </FormControl>
          </HStack>

          <HStack gap={4}>
            <FormControl isInvalid={Boolean(errors.PTD)}>
              <Flex align="start">
                <HoverableFormLabel>
                  Permanent Total Disability (PTD)
                </HoverableFormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Permanent loss of physical
function or anatomical loss of a body part's capacity for use,"
                  />
                )}
              </Flex>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("PTD", { required: true })}
                defaultValue="100 % of Sum Insured"
              />
            </FormControl>

            <FormControl isInvalid={Boolean(errors.Terrorism)}>
              <HoverableFormLabel>Terrorism</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("Terrorism", { required: true })}
                defaultValue="Covered except Nuclear, Biological & Chemical Means"
              />
            </FormControl>
          </HStack>

          <Box borderTop="1px solid " mt={4} pt={4}>
            {/* Bid Dates */}
            <HStack gap={4}>
              {/* Bid Start Date */}
              <FormControl
                id="start_date"
                isInvalid={Boolean(errors?.start_date)}
              >
                <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  min={todayDate}
                  {...register("start_date", {
                    required: "Bid's start date is required",
                  })}
                />
                {errors?.start_date && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.start_date?.message}
                  </Text>
                )}
              </FormControl>

              {/* Bid End Date */}
              <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
                <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  min={watch("start_date")}
                  {...register("end_date", {
                    required: "Bid's end date is required",
                  })}
                />
                {errors?.end_date && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.end_date?.message}
                  </Text>
                )}
              </FormControl>
            </HStack>

            {/* Policy Dates */}
            <HStack gap={4}>
              {/* Policy Start Date */}
              <FormControl id="policy_start_date">
                <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  min={todayDate}
                  {...register("policy_start_date")}
                />
              </FormControl>

              {/* Policy End Date */}
              <FormControl id="policy_end_date">
                <HoverableFormLabel>Policy End Date</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  min={watch("policy_start_date")}
                  {...register("policy_end_date")}
                />
              </FormControl>
            </HStack>
            <HStack gap={4}>
              {/* Anticipated Premium */}
              <FormControl
                id="expected_premium"
                isInvalid={Boolean(errors?.expected_premium)}
              >
                <Flex align="start">
                  <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Anticipated premium for acquiring this policy."
                    />
                  )}
                </Flex>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  defaultValue="0"
                  {...register("expected_premium", {
                    required: "Anticipated premium is required",
                  })}
                />
                {errors?.expected_premium && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.expected_premium?.message}
                  </Text>
                )}
              </FormControl>

              {/* Total Coverages */}
              <FormControl
                id="sum_insured"
                isInvalid={Boolean(errors?.sum_insured)}
              >
                <HoverableFormLabel>Total coverages*</HoverableFormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  defaultValue="0"
                  {...register("sum_insured", {
                    required: "Total coverages is required",
                  })}
                />
                {errors?.sum_insured && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.sum_insured?.message}
                  </Text>
                )}
              </FormControl>
            </HStack>
            {/* File Uploads */}
            <HStack gap={4} my="3">
              {/* Claim Data */}
              <FormControl id="claim_data" flex={1}>
                <HoverableFormLabel>Claim Data of last year</HoverableFormLabel>

                <Input
                  bg="white"
                  borderRadius="21px"
                  type="file"
                  // display="none"
                  pt="5px"
                  {...register("claim_data")}
                />
              </FormControl>

              {/* Employee Data */}
              <HStack flex={1} gap={4} align="center">
                <FormControl id="emp_data" flex={2}>
                  <HoverableFormLabel>{`Employee Information${
                    !!(watch("sum_insured_category") === "specific") ? "*" : ""
                  }`}</HoverableFormLabel>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    type="file"
                    pt="5px"
                    accept=".xlsx, .xls, .csv"
                    onChange={handleFileUpload}
                  />
                  {errors?.emp_data && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.emp_data?.message}
                    </Text>
                  )}
                </FormControl>
                <FormControl flex={1}>
                  <Button
                    as="a"
                    href="https://d1un9qwa1edhdg.cloudfront.net/member-data-template/MemberData.xlsx"
                    download
                    leftIcon={<FaCloudDownloadAlt />}
                    colorScheme="green"
                    bg="green.500"
                    borderRadius="21px"
                    size="sm"
                  >
                    <Text>Sample File</Text>
                  </Button>
                </FormControl>
              </HStack>
            </HStack>

            {/* Previous Insurer and Policy Expiry */}
            {watch("policytype") === "Renewal" && (
              <HStack gap={4}>
                {/* Previous Insurer */}
                <FormControl
                  id="previous_insurer"
                  isInvalid={Boolean(errors?.previous_insurer)}
                >
                  <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    {...register("previous_insurer", {
                      required: "Previous insurer is required",
                    })}
                  />
                  {errors?.previous_insurer && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.previous_insurer?.message}
                    </Text>
                  )}
                </FormControl>

                {/* Previous Policy Expiry */}
                <FormControl
                  id="previous_policy_expiry"
                  isInvalid={Boolean(errors?.previous_policy_expiry)}
                >
                  <HoverableFormLabel>
                    Previous policy expiry*
                  </HoverableFormLabel>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    type="date"
                    {...register("previous_policy_expiry", {
                      required: "Previous policy expiry is required",
                    })}
                  />
                  {errors?.previous_policy_expiry && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.previous_policy_expiry?.message}
                    </Text>
                  )}
                </FormControl>
              </HStack>
            )}
          </Box>

          <Flex w="100%" justify="space-between" align="center" mt="20px">
            <Button
              type="button"
              borderRadius="21px"
              onClick={() => setStep(step - 1)}
            >
              Previous
            </Button>
            <Button
              type="submit"
              borderRadius="21px"
              colorScheme="green"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Next
            </Button>
          </Flex>
        </VStack>
      </form>
    </Box>
  );
};

export default GroupPersonalAccident;
