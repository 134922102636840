import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { theme } from "../../../partials/utils/Contants";
import moment from "moment";
import { FaArrowLeft } from "react-icons/fa6";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import BidderSanbotAI from "../../../components/Bidder/Bidder-Sanbot";
import { ReactComponent as PdfIcon } from "../../../partials/assets/icons/pdf-icon.svg";

import { IoCloudDownloadOutline } from "react-icons/io5";
import { BiDownload } from "react-icons/bi";
import Select from "react-select";
import { InsuranceCompany } from "../../../partials/utils/BidCreation";
import { downloadUsingURL } from "../../../partials/utils/Contants/useDownload";
import { addCommas } from "../../../partials/utils/helper";
import "./bidder-quote.css";

interface UploadFormValues {
  companyName: string;
  claimAmount: number;
  quoteFile: File;
}
interface OptionType {
  label: string;
  value: string;
}
const BidderQuote = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<UploadFormValues>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState<any>();
  const [quotesList, setQuotesList] = useState<any>([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("quoteFile", file, { shouldValidate: true });
    }
  };
  const onSubmit = async (data: UploadFormValues) => {
    const formData = new FormData();
    formData.append("quote_file", data.quoteFile);
    formData.append("insurance_company_name", data.companyName);
    formData.append("premium_amount", data.claimAmount.toString());
    setIsSubmitting(true);
    useFetch
      .post(`api/upload-quote/${id}/`, formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast({
          title: "Upload successful!",
          description: "Your quote has been uploaded.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
        fetchQuoteData();
        reset();
      })
      .catch((error) => {
        console.log("error", error?.response);
        let message =
          error?.response?.data?.error ||
          error?.response?.data?.detail ||
          "There was an issue uploading your policy. Please try again.";
        toast({
          title: "Upload failed!",
          description: message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsSubmitting(false));
  };
  function fetchQuoteData() {
    setIsLoading(true);
    useFetch
      .get(`api/get-quotes/?bid_id=${id}`)
      .then((res) => {
        setQuotesList(res?.data?.details);
        setStartDate(res?.data?.start_date);
        setEndDate(res?.data?.end_date);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    fetchQuoteData();
  }, [id]);

  const hasAccepted = quotesList?.find((x: any) => x?.is_accepted === 1);
  return (
    <>
      {isLoading ? (
        <Flex width="100%" height="40vh" align="end" justify="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex w="full">
          <Flex
            direction="column"
            padding="30px 80px 30px 30px"
            flex={3}
            gap="20px"
            // w="100%"
          >
            <Flex align="center" w="100%" justify="space-between">
              <Icon
                as={FaArrowLeft}
                onClick={() => navigate("/bid-centre")}
                cursor="pointer"
              />
              <Button
                borderRadius="24px"
                bg="green"
                colorScheme="green"
                color="white"
                w="143px"
                height="24px"
                fontSize="10px"
                fontWeight="500"
                onClick={onOpen}
              >
                Add Quote
              </Button>
            </Flex>
            <Text as="h2" fontSize="24px" fontWeight="500" textAlign="start">
              Quote List
            </Text>
            {quotesList?.length ? (
              <Flex
                direction="column"
                w="100%"
                align="center"
                gap="12px"
                height="82vh"
                overflowY="auto"
                className="hide-scrollbar"
              >
                {quotesList.map((data: any) => (
                  <Flex
                    direction="column"
                    gap="17px"
                    width="100%"
                    className={
                      selectedQuote === data.id
                        ? "bid-card-selected"
                        : "bid-card-bg"
                    }
                    boxShadow="md"
                    padding="12px"
                    position="relative"
                  >
                    <Flex width="100%" justify="space-between" align="center">
                      <Flex width="100%" align="center" gap="8px">
                        <Flex
                          bg={theme.color.GREEN}
                          height="15px"
                          width="15px"
                          borderRadius="full"
                        />
                        <Text
                          fontSize="11px"
                          fontWeight="400"
                          color={
                            selectedQuote?.id === data.id
                              ? "rgba(131, 131, 131, 1)"
                              : "rgba(0, 0, 0, 0.50)"
                          }
                        >
                          Active |{" "}
                          {startDate
                            ? moment(startDate).format("D MMMM, yyyy")
                            : "N/A"}{" "}
                          -{" "}
                          {endDate
                            ? moment(endDate).format("D MMMM, yyyy")
                            : "N/A"}
                        </Text>
                      </Flex>

                      <Text
                        bg={
                          data?.quote_status === "Accepted"
                            ? theme.color.GREEN
                            : data?.quote_status === "Pending"
                            ? theme.color.YELLOW
                            : "red"
                        }
                        // bg={
                        //   hasAccepted && data?.is_accepted === 1
                        //     ? theme.color.GREEN
                        //     : !hasAccepted && data?.is_accepted === 0
                        //     ? theme.color.YELLOW
                        //     : "red"
                        // }
                        color="white"
                        shadow="md"
                        padding="5px 12px 5px 12px"
                        borderRadius="12px"
                        // height="21px"
                        // width="60px"
                        fontWeight="500"
                        textAlign="center"
                        whiteSpace="nowrap"
                        fontSize="11px"
                      >
                        {data?.quote_status}
                        {/* {hasAccepted && data?.is_accepted === 1
                          ? "Quote Approved"
                          : !hasAccepted && data?.is_accepted === 0
                          ? "Pending"
                          : "Quote Declined"} */}
                      </Text>
                    </Flex>
                    <Flex width="100%" align="center" gap="8px">
                      <Text
                        fontSize="15px"
                        fontWeight="700"
                        color={
                          selectedQuote?.id === data.id ? "white" : "black"
                        }
                        whiteSpace="nowrap"
                      >
                        {data?.insurance_company_name}
                      </Text>
                      {data?.bidder_name && (
                        <Text
                          fontSize="15px"
                          fontWeight="700"
                          color={theme.color.GRAY_LABEL}
                          whiteSpace="nowrap"
                        >
                          | {data?.bidder_name}
                        </Text>
                      )}
                    </Flex>
                    <Flex width="100%" align="center" justify="space-between">
                      <Text
                        fontSize="12px"
                        fontWeight="700"
                        color={
                          selectedQuote?.id === data.id
                            ? "rgba(131, 131, 131, 1)"
                            : theme.color.GRAY_LABEL
                        }
                        whiteSpace="nowrap"
                      >
                        {data?.premium_amount &&
                          `₹${addCommas(data?.premium_amount)}`}
                      </Text>
                      {data?.quote_file && (
                        <Flex
                          align="center"
                          gap="3px"
                          onClick={() => downloadUsingURL(data?.quote_file)}
                          cursor="pointer"
                          bg="black"
                          borderRadius="16px"
                          p="4px 8px"
                        >
                          <PdfIcon
                            height="16px"
                            color="white"
                            width="auto"
                            fontWeight="700"
                          />
                          <Text
                            fontWeight="500"
                            fontSize="12px"
                            color="#dadada"
                            // color="rgba(255, 255, 255, 0.70)"
                          >
                            Proposal
                          </Text>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            ) : (
              <Flex w="100%" height="40vh" align="center" justify="center">
                <Text>No quote files found for this bid </Text>
              </Flex>
            )}
            <Modal isOpen={isOpen} onClose={onClose} size="lg">
              <ModalOverlay />
              <ModalContent borderRadius="21px" pb="35px">
                <ModalHeader>Add Quote</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <FormControl isInvalid={!!errors.companyName}>
                      <FormLabel htmlFor="companyName">
                        Insurance Company*
                      </FormLabel>

                      <Controller
                        name="companyName"
                        control={control}
                        defaultValue=""
                        rules={{ required: "Please select a company name" }}
                        render={({ field }) => (
                          <Select<OptionType, false>
                            {...field}
                            placeholder="Search Company Name"
                            isSearchable={true}
                            options={InsuranceCompany.map((q) => ({
                              label: q,
                              value: q,
                            }))}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderRadius: "21px",
                                borderColor: errors.companyName
                                  ? "red"
                                  : provided.borderColor,
                              }),
                              placeholder: (provided) => ({
                                ...provided,
                                textAlign: "start",
                              }),
                              menu: (provided) => ({
                                ...provided,
                                borderRadius: "21px",
                                background: "white",
                                textAlign: "start",
                              }),
                              menuList: (provided) => ({
                                ...provided,
                                padding: "5px",
                              }),
                              singleValue: (provided) => ({
                                ...provided,
                                fontSize: "12px",
                              }),
                              option: (provided) => ({
                                ...provided,
                                fontSize: "11px",
                                padding: "5px",
                                textAlign: "start",
                              }),
                            }}
                            onChange={(option) =>
                              field.onChange(option ? option.value : "")
                            }
                            value={
                              InsuranceCompany.find(
                                (option) => option === field.value
                              )
                                ? { label: field.value, value: field.value }
                                : null
                            }
                          />
                        )}
                      />
                      {errors.companyName && (
                        <FormErrorMessage>
                          {errors.companyName?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl mt={4} isInvalid={!!errors.claimAmount}>
                      <FormLabel htmlFor="claimAmount">
                        Premium Amount*
                      </FormLabel>
                      <Input
                        borderRadius="16px"
                        id="claimAmount"
                        type="number"
                        placeholder="Enter Premium amount"
                        {...register("claimAmount", {
                          required: "Claim Amount is required",
                        })}
                      />
                      {errors.claimAmount && (
                        <FormErrorMessage>
                          {errors.claimAmount?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <FormControl mt={4} isInvalid={!!errors.quoteFile}>
                      <FormLabel htmlFor="quoteFile">Upload PDF*</FormLabel>
                      <Flex w="100%" justify="center">
                        <Flex
                          w="295px"
                          borderRadius="12px"
                          border="2px dotted gray"
                          padding="20px"
                          gap="12px"
                          direction="column"
                          align="center"
                          color="black"
                          onClick={handleUploadClick}
                          cursor="pointer"
                        >
                          <input
                            ref={fileInputRef}
                            style={{ display: "none" }}
                            type="file"
                            accept="application/pdf"
                            onChange={handleFileChange}
                          />
                          <Text fontWeight="500" fontSize="16px">
                            Upload PDF
                          </Text>
                          <Icon
                            as={IoCloudDownloadOutline}
                            fontSize="28px"
                            color={theme.color.NAVY_BLUE}
                          />
                          <Text
                            fontWeight="400"
                            fontSize="10px"
                            textAlign="center"
                          >
                            Click to browse. Max file size: 25 MB.{" "}
                          </Text>
                          {watch("quoteFile") && (
                            <Text
                              fontWeight="400"
                              fontSize="10px"
                              textAlign="center"
                              color={theme.color.NAVY_BLUE}
                            >
                              <strong>Uploaded File :</strong>{" "}
                              {watch("quoteFile")?.name}
                            </Text>
                          )}
                        </Flex>
                      </Flex>

                      {errors.quoteFile && (
                        <FormErrorMessage>
                          {errors.quoteFile?.message}
                        </FormErrorMessage>
                      )}
                    </FormControl>

                    <Button
                      type="submit"
                      w="fit-content"
                      borderRadius="21px"
                      colorScheme="green"
                      mt="12px"
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting}
                    >
                      Upload
                    </Button>
                  </form>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
          <BidderSanbotAI />
        </Flex>
      )}
    </>
  );
};
export default BidderQuote;
