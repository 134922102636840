import { useEffect, useState } from "react";
import SanbotLogo from "../../partials/assets/images/galaxy-logo.png";
import {
  Flex,
  Icon,
  Image,
  Text,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { HiOutlineSparkles } from "react-icons/hi2";
import BidDetails from "../../pages/BidDetails";
import Proposals from "../Proposals";
import BidsList from "../../pages/BidsList";
import Insights, { InsightCard } from "./Insights";
import { RxCross1 } from "react-icons/rx";
export type InsightType = "bid" | "quote" | "policy";
export type Insight = {
  id: string | number | null;
  type: InsightType;
};
type SanbotAIProps = {
  selectedBid: any;
  setSelectedBid: any;
  onComparisonOpen: () => void;
  onComparisonClose: () => void;
};
type Enum<T> = T[keyof T];
export const ActionType = {
  Insights: 0,
  Requests: 1,
  Requirements: 2,
  PolicyInsights: 3,
  Proposals: 4,
};
export type ActionType = Enum<typeof ActionType>;

const SanbotAI = ({
  selectedBid,
  setSelectedBid,
  onComparisonOpen,
  onComparisonClose,
}: SanbotAIProps) => {
  const [insightType, setInsightType] = useState<Insight>({
    id: null,
    type: "bid",
  });

  const getDefaultTabIndex = () => {    
    if (selectedBid?.action === ActionType.Requirements) {
      return 2;
    }
    if (selectedBid?.action === ActionType.Insights) {
      setInsightType({
        id: selectedBid.id,
        type: "bid",
      });
      return 0;
    }
    if (selectedBid?.action === ActionType.PolicyInsights) {
      setInsightType({
        id: selectedBid.policyId,
        type: "policy",
      });
      return 0;
    }
    if (selectedBid?.action === ActionType.Proposals) {
      return 3;
    }
    if (selectedBid?.action === ActionType.Requests) {
      return 1;
    }
    return 0;
  };

  const [tabIndex, setTabIndex] = useState<number>();

  useEffect(() => {
    setTabIndex(getDefaultTabIndex());
    // setInsightType({
    //   id: null,
    //   type: "bid",
    // });
  }, [selectedBid]);

  return (
    <>
      {selectedBid?.id && (
        <Flex
          direction="column"
          padding={selectedBid?.data ? "10px 20px" : "40px 25px 20px"}
          flex={1}
          bg="black"
          gap="20px"
          w="fit-content"
          maxW="420px"
          height="100%"
          minH="100vh"
          position="relative"
        >
          <Flex position="absolute" right="15px" top="15px">
            <RxCross1
              color="white"
              onClick={() =>
                setSelectedBid({
                  id: null,
                  bidId: null,
                  action: "",
                  data: {},
                })
              }
              cursor="pointer"
            />
          </Flex>
          <Flex
            align="center"
            gap="14px"
            m={selectedBid?.data ? "16px 12px" : "0px"}
          >
            <Image src={SanbotLogo} alt="sanbot" height="60px" width="60px" />
            <Flex direction="column" gap="5px">
              <Text fontSize="22px" fontWeight="500" color="white">
                SanBotAI:{" "}
              </Text>
              <Text
                fontSize="22px"
                fontWeight="500"
                color="rgba(246, 246, 246, 0.60)"
              >
                Bid Intelligence{" "}
              </Text>
            </Flex>
          </Flex>
          {!selectedBid?.id ? (
            <InsightCard
              text="Click on a bid to view more details and get insights from SanbotAI"
              themeMode="dark"
            />
          ) : (
            <Tabs
              position="relative"
              index={tabIndex}
              onChange={(index) => setTabIndex(index)}
              variant="unstyled"
              width="100%"
            >
              <TabList gap="2px" px="0px">
                <Tab
                  height="47px"
                  fontSize="0.75rem"
                  fontWeight="500"
                  color="rgba(246, 246, 246, 0.50)"
                  _selected={{
                    color: "white",
                  }}
                >
                  <Flex align="center" gap="2px">
                    <Icon as={HiOutlineSparkles} fontSize="12px" />
                    <Text>Insights</Text>
                  </Flex>
                </Tab>
                <Tab
                  height="47px"
                  fontSize="0.75rem"
                  fontWeight="500"
                  color="rgba(246, 246, 246, 0.50)"
                  _selected={{
                    color: "white",
                  }}
                >
                  Requests
                </Tab>
                <Tab
                  height="47px"
                  fontSize="0.75rem"
                  fontWeight="500"
                  color="rgba(246, 246, 246, 0.50)"
                  _selected={{
                    color: "white",
                  }}
                >
                  Requirements
                </Tab>

                <Tab
                  height="47px"
                  fontSize="0.75rem"
                  fontWeight="500"
                  color="rgba(246, 246, 246, 0.50)"
                  _selected={{
                    color: "white",
                  }}
                >
                  Proposals
                </Tab>
                {/* <Tab
              height="47px"
              fontSize="0.75rem"
              fontWeight="500"
              color="rgba(246, 246, 246, 0.50)"
              _selected={{
                color: "white",
              }}
            >
              Comments
            </Tab> */}
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="white"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <Insights
                    selectedBid={selectedBid}
                    insightType={insightType}
                  />
                </TabPanel>
                <TabPanel px="4px">
                  <Flex
                    direction="column"
                    w="100%"
                    align="start"
                    gap="5px"
                    maxH="64vh"
                    // maxH="85%"
                    // flex={1}
                    overflowY="auto"
                    className="hide-scrollbar"
                    px="10px"
                  >
                    <Text
                      color="white"
                      fontWeight="600"
                      fontSize="16px"
                      py="10px"
                    >
                      Bidder Requests
                    </Text>
                    <BidsList id={selectedBid?.id} />
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    maxH="64vh"
                    // maxH="85%"
                    // flex={1}
                    overflowY="auto"
                    className="hide-scrollbar"
                  >
                    <BidDetails id={selectedBid?.id} />
                  </Flex>
                </TabPanel>
                <TabPanel>
                  <Flex
                    direction="column"
                    w="100%"
                    align="center"
                    gap="12px"
                    maxH="64vh"
                    // maxH="85%"
                    // flex={1}
                    overflowY="auto"
                    className="hide-scrollbar"
                  >
                    <Proposals
                      id={selectedBid?.bidId}
                      onComparisonOpen={onComparisonOpen}
                      onComparisonClose={onComparisonClose}
                      setInsightType={setInsightType}
                      setSanbotTabIndex={setTabIndex}
                    />
                  </Flex>
                </TabPanel>
                {/* <TabPanel>
              <Flex
                direction="column"
                w="100%"
                align="center"
                gap="12px"
                maxH="75vh"
                // maxH="85%"
                // flex={1}
                overflowY="auto"
                className="hide-scrollbar"
              >
                Comments Comming soon
              </Flex>
            </TabPanel> */}
              </TabPanels>
            </Tabs>
          )}
        </Flex>
      )}
    </>
  );
};

export default SanbotAI;
