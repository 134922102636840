import { Select } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { CompanyType, useStore } from "../../store/store";

const CompanyDropdown = () => {
  const {
    userCompanies,
    selectedCompany,
    setSelectedCompany,
    fetchUserCompanies,
  } = useStore();

  useEffect(() => {
    fetchUserCompanies();
  }, []);

  return (
    <Select
      placeholder="Select Company"
      borderRadius="21px"
      background="black"
      color="white"
      w="fit-content"
      fontSize="12px"
      size="sm"
      value={selectedCompany}
      onChange={(e) => setSelectedCompany(e.target.value)}
      sx={{
        option: {
          background: "black",
          color: "white",
          borderRadius: "12px",
        },
        "&:focus": {
          borderColor: "white",
        },
      }}
    >
      {userCompanies?.map((c) => (
        <option key={c?.id} value={c?.id}>
          {c?.c_name}
        </option>
      ))}
    </Select>
  );
};

export default CompanyDropdown;
