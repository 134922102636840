import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import { RxCross1 } from "react-icons/rx";
import { FiEye, FiArchive } from "react-icons/fi";
import { HiOutlineSparkles } from "react-icons/hi2";

import { RiEditCircleLine } from "react-icons/ri";
import { FaCircleCheck, FaRegTrashCan } from "react-icons/fa6";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";

import { productMapping, theme } from "../../partials/utils/Contants";
import { ChangeEvent, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";

import { createPortal } from "react-dom";
import moment from "moment";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useStore } from "../../store/store";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { convertProductName } from "../../partials/utils/helper";
import "./BidCard.css";
import { downloadUsingURL } from "../../partials/utils/Contants/useDownload";
import { AiOutlineMessage } from "react-icons/ai";
import { ReactComponent as ConversationIcon } from "../../partials/assets/icons/brand-wechat.svg";
import { ActionType } from "../SanbotAI";

type BidCardProps = {
  data: any;
  type: "draft" | "live" | "engaged" | "completed" | "inactive";
  fetchData?: any;
  fetchAfterComplete?: any;
  selectedBid?: any;
  setSelectedBid?: any;
  showActions?: boolean;
  form: any;
  completedPolicy?: any;
  quoteForCompleted?: any;
  fetchAll: any;
};
const ActionDropdown = [
  { icon: FiEye, label: "Preview", event: "test" },
  { icon: HiOutlineSparkles, label: "Insights", event: "test" },
  { icon: HiOutlineSparkles, label: "Policy Insights", event: "test" },
  { icon: RiEditCircleLine, label: "Edit", event: "test" },
  { icon: FiArchive, label: "Archive", event: "test" },
  { icon: FiEye, label: "Proposals", event: "test" },
  { icon: FaCircleCheck, label: "Mark as Complete", event: "test" },
];
const filterActions = (type: BidCardProps["type"]) => {
  switch (type) {
    case "live":
      return ActionDropdown.filter((action) =>
        ["Preview", "Insights", "Edit", "Archive"].includes(action.label)
      );
    case "engaged":
      return ActionDropdown.filter((action) =>
        ["Preview", "Insights", "Proposals", "Mark as Complete"].includes(
          action.label
        )
      );
    case "draft":
      return ActionDropdown.filter((action) =>
        ["Edit", "Archive"].includes(action.label)
      );
    case "completed":
      return ActionDropdown.filter((action) =>
        ["Preview", "Policy Insights"].includes(action.label)
      );
    default:
      return [];
  }
};
const BidCard = ({
  data,
  type,
  fetchData,
  fetchAll,
  selectedBid,
  setSelectedBid,
  fetchAfterComplete,
  showActions = true,
  form,
  completedPolicy,
  quoteForCompleted,
}: BidCardProps) => {
  const product = productMapping[data?.product] || data?.product;

  const toast = useToast();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  const {
    isOpen: isPolicyOpen,
    onOpen: onPolicyOpen,
    onClose: onPolicyClose,
  } = useDisclosure();
  const { selectedCompany } = useStore();
  const [file, setFile] = useState<File | null>(null);
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isOpen, setIsOpen] = useState({ id: "", value: false });
  const buttonRef = useRef<any>(null);
  const menuRef = useClickOutside(() => setIsOpen({ id: "", value: false }));
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });

  const filteredActions = filterActions(type);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // trigger click on file input
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };
  const handleUploadPDF = () => {
    if (!file) {
      toast({
        title: "Please select a pdf to upload",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("policy_file", file);
    useFetch
      .post(
        `/api/${selectedCompany}/upload-policy-client/${data?.id}/`,
        formData,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        handleCompletBid();
        toast({
          title: "Upload successful!",
          description: "Policy uploaded successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onPolicyClose();
        fetchData();
      })
      .catch((error) => {
        console.log("error", error?.response);
        let message =
          error?.response?.data?.error ||
          "There was an issue uploading your policy. Please try again.";
        toast({
          title: message,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  const mouseClick = (e: any) => {
    e.stopPropagation();
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      const availableSpaceBelow = window.innerHeight - rect.bottom;
      const dropdownHeight = 235;
      if (availableSpaceBelow >= dropdownHeight) {
        setMenuPosition({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX - 100,
        });
      } else {
        setMenuPosition({
          top: rect.top + window.scrollY - dropdownHeight,
          left: rect.left + window.scrollX - 100,
        });
      }
    }
  };
  const toggleDropdown = () => {
    if (isOpen.value) {
      setIsOpen({ id: "", value: false });
    } else {
      setIsOpen({ id: data?.id || "1", value: true });
    }
  };
  const validateCompleteBid = () => {
    const hasPolicy = data?.policy_for_bid?.length > 0;
    const isQuoteAccepted = data?.quote_files?.some(
      (q: any) => q?.quote_status === "Accepted"
    );
    if (hasPolicy) {
      handleCompletBid();
    } else if (!hasPolicy && isQuoteAccepted) {
      onPolicyOpen();
    } else {
      toast({
        title: "Bid cannot be marked as complete",
        description: "Quote has not been uploaded yet.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleCompletBid = () => {
    useFetch
      .post(`api/${selectedCompany}/mark-as-complete/${data?.id}/`)
      .then((response) => {
        toast({
          title: `Bid #${data?.id} Successfully Marked as completed`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchAfterComplete();
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: error.response?.data?.error || "Something went wrong",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const handleDelete = () => {
    setIsSubmitting(true);
    useFetch
      .delete(
        `api/${selectedCompany}/${
          type === "live" ? "live-bids-client" : "drafted-bids"
        }/?bid_id=${data?.id}`
      )
      .then((res) => {
        setIsSubmitting(false);

        toast({
          title: "Bid Deleted Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        fetchAll();
      })
      .catch((error) => {
        setIsSubmitting(false);

        console.log("error", error);
        toast({
          title: error.response.data?.detail || "Something Went wrong",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const showDetail = (data: any) => {
    setSelectedBid({
      id: data?.id,
      bidId: data?.id,
      action: ActionType.Requirements,
      data: data,
    });
    toggleDropdown();
  };
  const showProposals = (data: any) => {
    setSelectedBid({
      id: data?.id,
      bidId: data?.id,
      action: ActionType.Proposals,
      data: data,
    });
    toggleDropdown();
  };
  const showRequests = (data: any) => {
    setSelectedBid({
      id: data?.id,
      bidId: data?.id,
      action: ActionType.Requests,
      data: data,
    });
    // toggleDropdown();
  };
  const openInsights = () => {
    setSelectedBid({
      id: data?.id,
      bidId: data?.id,
      action: ActionType.Insights,
      data: data,
    });
  };
  const handleAction = (label: string) => {    
    if (label === "Preview") {
      showDetail(data);
    } else if (label === "Insights") {
      openInsights();
      toggleDropdown();
    } else if (label === "Policy Insights") {
      const policy = data?.policy;
      setSelectedBid({
        id: data?.id,
        bidId: data?.id,
        policyId: policy.id,
        action: ActionType.PolicyInsights,
        data: data,
        // policyId: policy?.id
      });
      toggleDropdown();
    } else if (label === "Archive") {
      onDeleteOpen();
    } else if (label === "Edit") {
      navigate(`/edit-bid/${data?.id}`);
    } else if (label === "Proposals") {
      showProposals(data);
    } else if (label === "Poilcy Upload") {
      onPolicyOpen();
    } else if (label === "Mark as Complete") {
      validateCompleteBid();
      // handleCompletBid(data?.id);
    }
  };
  return (
    <Flex
      key={data}
      direction="column"
      gap="17px"
      width="100%"
      className={
        selectedBid?.data?.id === data.id ? "bid-card-selected" : "bid-card-bg"
      }
      padding="12px"
      position="relative"
      boxShadow="md"
      cursor="pointer"
      onClick={openInsights}
    >
      <Flex width="100%" justify="space-between" align="center">
        <Flex width="100%" align="center" gap="8px">
          <Flex
            bg={
              type === "draft" || type === "inactive"
                ? theme.color.YELLOW
                : theme.color.GREEN
            }
            height="15px"
            width="15px"
            borderRadius="full"
          />
          <Text
            fontSize="11px"
            fontWeight="400"
            color={
              selectedBid?.data?.id === data.id
                ? "rgba(131, 131, 131, 1)"
                : "rgba(0, 0, 0, 0.50)"
            }
          >
            {type === "engaged" || "live"
              ? "Active"
              : type === "completed"
              ? "Completed"
              : "Paused"}{" "}
            |{" "}
            {data?.start_date
              ? moment(data?.start_date).format("D MMMM, yyyy")
              : "N/A"}
            -
            {data?.end_date
              ? moment(data?.end_date).format("D MMMM, yyyy")
              : "N/A"}
          </Text>
        </Flex>
        <Text
          bg="black"
          color={selectedBid?.data?.id === data.id ? "white" : "#F6F6F6"}
          shadow="md"
          padding="2px 10px 3px 10px"
          borderRadius="12px"
          height="21px"
          width="auto"
          fontWeight="500"
          textAlign="center"
          whiteSpace="nowrap"
          border={
            selectedBid?.data?.id === data.id
              ? "1px solid rgba(131, 131, 131, 1)"
              : "1px solid #F6F6F6"
          }
          fontSize="11px"
          // onClick={() => navigate(`/bid-list/${data.id}`)}
          onClick={(e) => {
            e.stopPropagation();
            showRequests(data);
          }}
          cursor="pointer"
        >
          {data?.interests?.length || data?.no_of_bidders || "0"}
          {data?.interests?.length > 1 ? " bidders" : " bidder"}
        </Text>
      </Flex>
      <Flex width="100%" align="center" gap="8px" wrap="wrap">
        <Text
          fontSize="15px"
          fontWeight="700"
          color={selectedBid?.data?.id === data.id ? "white" : "black"}
          whiteSpace="nowrap"
        >
          #{data?.id} {product} |
        </Text>
        <Text
          fontSize="12px"
          fontWeight="700"
          color={
            selectedBid?.data?.id === data.id
              ? "rgba(131, 131, 131, 1)"
              : theme.color.GRAY_LABEL
          }
          whiteSpace="nowrap"
        >
          Expected Premium: ₹{form?.expected_premium}
        </Text>
      </Flex>

      <Flex width="100%" align="center" justify="space-between">
        <Flex width="100%" align="center" gap="8px">
          <Flex
            padding="4px 14px"
            align="center"
            justify="center"
            borderRadius="12px"
            bg="white"
            fontSize="10px"
            fontWeight="500"
            color="black"
          >
            Type: {form?.policytype}
          </Flex>
          <Flex gap="10px">
            <Flex
              padding="4px 14px"
              align="center"
              justify="center"
              borderRadius="12px"
              bg={
                type === "draft" || type === "inactive"
                  ? theme.color.YELLOW
                  : theme.color.GREEN
              }
              fontSize="10px"
              fontWeight="500"
              color="#F6F6F6"
              textTransform="capitalize"
            >
              {type === "draft" ? "Drafted" : type}
              {/* Match: {data % 2 === 0 ? "Good" : "Fair"} */}
            </Flex>
          </Flex>
        </Flex>
        <Flex align="center" gap="10px">
          <Icon
            // colorScheme="green"
            aria-label="chat"
            // bg={theme.color.GREEN}
            bg="transparent"
            color={selectedBid?.data?.id === data.id ? "white" : "black"}
            height="22px"
            width="22px"
            as={ConversationIcon}
            onClick={() => navigate("/conversations")}
          />

          {showActions ? (
            <Flex direction="column" position="relative">
              <Flex
                ref={buttonRef}
                w="99px"
                transition="all 0.2s"
                borderRadius="24px"
                borderWidth="1px"
                _hover={{ bg: "gray.700", color: "white" }}
                _focus={{ boxShadow: "outline" }}
                _expanded={{ bg: "black", color: "white" }}
                bg={isOpen.value ? "black" : "white"}
                color={isOpen.value ? "white" : "black"}
                onClick={(e) => {
                  toggleDropdown();
                  mouseClick(e);
                }}
                justify="space-evenly"
                align="center"
                cursor="pointer"
                zIndex={isOpen ? 1000 : "auto"}
              >
                <Text fontSize="12px" fontWeight="500">
                  Actions
                </Text>
                {isOpen.value ? (
                  <Icon as={RxCross1} fontSize="14px" />
                ) : (
                  <Icon as={IoIosArrowDown} fontSize="16px" />
                )}
              </Flex>
              {isOpen.value &&
                createPortal(
                  <Flex
                    ref={menuRef}
                    direction="column"
                    p="20px 0px 10px"
                    w="207px"
                    bg="black"
                    color="white"
                    position="absolute"
                    top={`${menuPosition.top}px`}
                    left={`${menuPosition.left}px`}
                    gap="12px"
                    borderRadius="16px"
                    zIndex="1000"
                  >
                    <Text color="white" px="20px" fontSize="15px">
                      Actions:
                    </Text>
                    <Flex direction="column" gap="6px">
                      {filteredActions?.map((item: any) => {
                        if (
                          data?.interests?.length >= 1 &&
                          (item.label === "Archive" || item.label === "Edit")
                        )
                          return;
                        // if (
                        //   data?.interests?.length < 1 &&
                        //   item.label === "Insights"
                        // )
                        //   return;
                        return (
                          <Flex
                            align="center"
                            w="100%"
                            justify="space-between"
                            color="white"
                            p="10px 20px"
                            _hover={{ bg: "gray.600", color: "gray.100" }}
                            cursor="pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAction(item?.label);
                            }}
                          >
                            <Flex align="center" gap="8px">
                              <Icon as={item.icon} fontSize="16px" />
                              <Text fontSize="12px" fontWeight="500">
                                {item.label}
                              </Text>
                            </Flex>
                            {/* <Icon as={IoIosArrowForward} fontSize="12px" /> */}
                          </Flex>
                        );
                      })}
                    </Flex>
                  </Flex>,
                  document.body
                )}
            </Flex>
          ) : (
            <Flex minW="40px" />
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalCloseButton />
          <ModalBody pt="50px">
            <Flex direction="column" align="center">
              <Text fontWeight="600">
                Are you sure you want to Delete this Bid
              </Text>
              <Text fontSize="14px">
                Once deleted, this bid will be inactivated!
              </Text>
            </Flex>
            <Flex align="center" gap="20px" w="full" justify="center" mt="20px">
              <Button borderRadius="21px" onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                borderRadius="21px"
                colorScheme="red"
                onClick={handleDelete}
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Delete
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isPolicyOpen} onClose={onPolicyClose} size="lg">
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalHeader>Upload Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" w="100%" align="center" textAlign="center">
              <Text fontSize="12px" textAlign="center" color="gray.500">
                The Policy is not uploaded for current bid, In order to Mark Bid
                as completed{" "}
              </Text>
              <Text fontSize="12px" textAlign="center" color="gray.500">
                Please Upload Policy
              </Text>
            </Flex>

            <Flex direction="column" padding="10px" gap="10px" align="center">
              <Flex w="100%" justify="center">
                <Flex
                  w="295px"
                  borderRadius="12px"
                  border="2px dotted gray"
                  padding="20px"
                  gap="12px"
                  direction="column"
                  align="center"
                  color="black"
                  onClick={handleUploadClick}
                  cursor="pointer"
                >
                  <input
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                  <Text fontWeight="600" fontSize="16px">
                    Upload PDF
                  </Text>
                  <Icon
                    as={IoCloudDownloadOutline}
                    fontSize="28px"
                    color={theme.color.NAVY_BLUE}
                  />
                  <Text fontWeight="400" fontSize="10px" textAlign="center">
                    Click to browse. Max file size: 25 MB.
                  </Text>
                  {file && (
                    <Text
                      fontWeight="400"
                      fontSize="10px"
                      textAlign="center"
                      color={theme.color.NAVY_BLUE}
                    >
                      <strong>Uploaded File :</strong>
                      {file?.name}
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Button
                onClick={handleUploadPDF}
                w="fit-content"
                borderRadius="21px"
                colorScheme="green"
                mt="12px"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Upload
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default BidCard;
