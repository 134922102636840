import {
  Box,
  FormControl,
  Input,
  Select,
  VStack,
  Heading,
  Text,
  HStack,
  Flex,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { useEffect, useState } from "react";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";

interface FormData {
  Type_of_Company: string;
  Type_of_Company_other?: string;
  Nature_of_operation: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Territorial_Scope: string;
  AnyMergers: string;
  acquisition: string;
  Company_subsidiary: string;
  Company_subsidiary_USA: string;
  total_gross_assets: number;
  ADR: string;
  Officers_Liability: string;
  Pending_Litigation: string;
  Prior_Acts_Exclusion: string;
  Discovery_Period: string;
  Discovery_Period_Person: string;
  Sales_Turnover: number;
  Claim_Experience: string;
  Any_other_details?: string;
  OutsideDirectorshipLiability?: number;
  FormerDirectorsOfficers?: number;
  CompanySecuritiesLiability?: number;
  CounsellingCosts?: number;
  Civilfinespenalties?: number;
  CrisisMitigationCosts?: number;
  Defenseexpensesbreakinglaws?: number;
  DeprivationofAssetsCosts?: number;
  AbductionResponseCosts?: number;
  EmergencyCosts?: number;
  ExtraditionCosts?: number;
  InvestigationCosts?: number;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
}

const DirectorsOfficerInsurance = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { step, setStep } = useStore();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      total_gross_assets: 0,
      Discovery_Period: "60 Days",
      Discovery_Period_Person: "Lifetime",
      Sales_Turnover: 0,
      OutsideDirectorshipLiability: 0,
      FormerDirectorsOfficers: 0,
      CompanySecuritiesLiability: 0,
      CounsellingCosts: 0,
      Civilfinespenalties: 0,
      CrisisMitigationCosts: 0,
      Defenseexpensesbreakinglaws: 0,
      DeprivationofAssetsCosts: 0,
      AbductionResponseCosts: 0,
      EmergencyCosts: 0,
      ExtraditionCosts: 0,
      InvestigationCosts: 0,
      expected_premium: 0,
      sum_insured: 0,

      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }
    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormData, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4" align="stretch">
        {/* Type of Company */}
        <HStack gap={4}>
          <FormControl
            id="Type_of_Company"
            isInvalid={Boolean(errors?.Type_of_Company)}
          >
            <HoverableFormLabel>Type of Company*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Type_of_Company", {
                required: "Type of Company is required",
              })}
              placeholder="Select Type of Company"
            >
              <option value="Private">Private</option>
              <option value="Public">Public</option>
              <option value="Government">Government</option>
              <option value="Non-Profit">Non-Profit</option>
              <option value="Public-Unlisted">Public-Unlisted</option>
              <option value="Others">Others</option>
            </Select>
            {errors?.Type_of_Company && (
              <Text color="red.500" fontSize="sm">
                {errors?.Type_of_Company?.message}
              </Text>
            )}
          </FormControl>
          {watch("Type_of_Company") === "Others" && (
            <FormControl isInvalid={Boolean(errors?.Type_of_Company_other)}>
              <HoverableFormLabel>Type Name*</HoverableFormLabel>
              <Input
                {...register("Type_of_Company_other", {
                  required: "This field is required",
                })}
                borderRadius="16px"
                background="white"
              />
              {errors?.Type_of_Company_other && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Type_of_Company_other?.message}
                </Text>
              )}
            </FormControl>
          )}
          {/* Nature of operation of the company and subsidiaries */}
          <FormControl isInvalid={Boolean(errors?.Nature_of_operation)}>
            <HoverableFormLabel>
              Nature of operation of the company and subsidiaries*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Nature_of_operation", {
                required: "Nature of Operation is required",
              })}
            />
            {errors?.Nature_of_operation && (
              <Text color="red.500" fontSize="sm">
                {errors?.Nature_of_operation?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Policy Type */}
          <FormControl isInvalid={Boolean(errors?.policytype)}>
            <HoverableFormLabel>Policy Type*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("policytype", {
                required: "Policy Type is required",
              })}
            >
              <option value="" disabled selected>
                Select policy type
              </option>
              <option value="New">New</option>
              <option value="Renewal">Renewal</option>
            </Select>
            {errors?.policytype && (
              <Text color="red.500" fontSize="sm">
                {errors?.policytype?.message}
              </Text>
            )}
          </FormControl>

          {/* Bidder Preference */}
          <FormControl isInvalid={Boolean(errors?.insurer)}>
            <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("insurer", {
                required: "Bidder Preference is required",
              })}
            >
              <option value="" disabled selected>
                Preference
              </option>
              <option value="Both">Both</option>
              <option value="Brokers">Brokers</option>
              <option value="INS_COMP">Insurance Company</option>
            </Select>
            {errors?.insurer && (
              <Text color="red.500" fontSize="sm">
                {errors?.insurer?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Where is your company located? */}
          <FormControl isInvalid={Boolean(errors?.comp_location)}>
            <HoverableFormLabel>
              Where is your company located?*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("comp_location", {
                required: "company locattion is required",
              })}
            />
            {errors?.comp_location && (
              <Text color="red.500" fontSize="sm">
                {errors?.comp_location?.message}
              </Text>
            )}
          </FormControl>

          {/* Territorial Scope and Jurisdiction */}
          <FormControl isInvalid={Boolean(errors?.Territorial_Scope)}>
            <HoverableFormLabel>
              Territorial Scope and Jurisdiction*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Territorial_Scope", {
                required: "Territorial Scope and Jurisdiction is required",
              })}
            >
              <option value="" disabled selected>
                Territorial Scope
              </option>
              <option value="Worldwide excluding US Canada">
                Worldwide excluding US Canada
              </option>
              <option value="Worldwide Including US Canada">
                Worldwide Including US Canada
              </option>
            </Select>
            {errors?.Territorial_Scope && (
              <Text color="red.500" fontSize="sm">
                {errors?.Territorial_Scope?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <Box borderTop="1px solid " mt={4} pt={4}>
          <Text fontWeight="bold" color="darkorchid" mb="4">
            Special conditions
          </Text>
        </Box>
        <HStack gap={4}>
          {/* Any Mergers, Acquisitions & Structural Change */}
          <FormControl isInvalid={Boolean(errors?.AnyMergers)}>
            <HoverableFormLabel>
              Any Mergers, Acquisitions & Structural Changes (if Yes)*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("AnyMergers", {
                required: "This field is required",
              })}
            />
            {errors?.AnyMergers && (
              <Text color="red.500" fontSize="sm">
                {errors?.AnyMergers?.message}
              </Text>
            )}
          </FormControl>

          {/* Acquisition */}
          <FormControl isInvalid={Boolean(errors?.acquisition)}>
            <HoverableFormLabel>
              Has the Company any acquisition, tender offers, or mergers pending
              or under consideration*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("acquisition", {
                required: "This field is required",
              })}
            >
              <option value="" disabled selected>
                Acquisition
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.acquisition && (
              <Text color="red.500" fontSize="sm">
                {errors?.acquisition?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Company a subsidiary of another company */}
          <FormControl isInvalid={Boolean(errors?.Company_subsidiary)}>
            <HoverableFormLabel>
              Is the Company a Subsidiary of Another Company?*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Company_subsidiary", {
                required: "This field is required",
              })}
            >
              <option value="" disabled selected>
                Company a subsidiary of another company
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Company_subsidiary && (
              <Text color="red.500" fontSize="sm">
                {errors?.Company_subsidiary?.message}
              </Text>
            )}
          </FormControl>

          {/* Does the Company have any subsidiaries in the USA */}
          <FormControl isInvalid={Boolean(errors?.Company_subsidiary_USA)}>
            <HoverableFormLabel>
              Does the Company Have Subsidiaries in the USA?*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Company_subsidiary_USA", {
                required: "This field is required",
              })}
            />
            {errors?.Company_subsidiary_USA && (
              <Text color="red.500" fontSize="sm">
                {errors?.Company_subsidiary_USA?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Total gross assets the Company in the USA */}
          <FormControl isInvalid={Boolean(errors?.total_gross_assets)}>
            <HoverableFormLabel>
              What are the total gross assets the Company in the USA ($)*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("total_gross_assets", {
                required: "This field is required",
              })}
            />
            {errors?.total_gross_assets && (
              <Text color="red.500" fontSize="sm">
                {errors?.total_gross_assets?.message}
              </Text>
            )}
          </FormControl>

          {/* ADR */}
          <FormControl isInvalid={Boolean(errors?.ADR)}>
            <HoverableFormLabel>
              Does the Company or any of its subsidiaries have any American
              Depositary Receipts (ADR’s) on issue? *
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("ADR", { required: "This field is required" })}
            >
              <option value="" disabled selected>
                ADR
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.ADR && (
              <Text color="red.500" fontSize="sm">
                {errors?.ADR?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Officers Liability */}
          <FormControl isInvalid={Boolean(errors?.Officers_Liability)}>
            <HoverableFormLabel>
              Has the Company's Directors & Officers Liability policy ever been
              subject to any unique terms or conditions?*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Officers_Liability", {
                required: "This field is required",
              })}
            >
              <option value="" disabled selected>
                Liability policy
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Officers_Liability && (
              <Text color="red.500" fontSize="sm">
                {errors?.Officers_Liability?.message}
              </Text>
            )}
          </FormControl>

          {/* Prior and Pending Litigation */}
          <FormControl isInvalid={Boolean(errors?.Pending_Litigation)}>
            <HoverableFormLabel>
              Prior and Pending Litigation?*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              style={{ marginTop: "21px" }}
              {...register("Pending_Litigation", {
                required: "This field is required",
              })}
            >
              <option value="" disabled selected>
                Prior and Pending Litigation
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Pending_Litigation && (
              <Text color="red.500" fontSize="sm">
                {errors?.Pending_Litigation?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Prior Acts Exclusion */}
          <FormControl isInvalid={Boolean(errors?.Prior_Acts_Exclusion)}>
            <Flex align="start">
              <HoverableFormLabel>Prior Acts Exclusion*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Excludes coverage for claims arising from incidents that occurred before a specified retroactive date"
                />
              )}
            </Flex>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Prior_Acts_Exclusion", {
                required: "This field is required",
              })}
            >
              <option value="" disabled selected>
                Prior Acts Exclusion
              </option>
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Prior_Acts_Exclusion && (
              <Text color="red.500" fontSize="sm">
                {errors?.Prior_Acts_Exclusion?.message}
              </Text>
            )}
          </FormControl>

          {/* Discovery Period */}
          <FormControl isInvalid={Boolean(errors?.Discovery_Period)}>
            <HoverableFormLabel>Discovery Period*</HoverableFormLabel>

            <Input
              bg="white"
              borderRadius="21px"
              {...register("Discovery_Period", {
                required: "This field is required",
              })}
            />
            {errors?.Discovery_Period && (
              <Text color="red.500" fontSize="sm">
                {errors?.Discovery_Period?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Discovery Period for Retired Insured Person */}
          <FormControl isInvalid={Boolean(errors?.Discovery_Period_Person)}>
            <Flex align="start">
              <HoverableFormLabel>
                Discovery Period for Retired Insured Person*
              </HoverableFormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="If the Policy is not renewed or replaced with insurance covering the same/similar risk, then
a retired Insured Person will automatically be granted a Discovery Period"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Discovery_Period_Person", {
                required: "This field is required",
              })}
            />
            {errors?.Discovery_Period_Person && (
              <Text color="red.500" fontSize="sm">
                {errors?.Discovery_Period_Person?.message}
              </Text>
            )}
          </FormControl>

          {/* Sales Turnover */}
          <FormControl
            id="Sales_Turnover"
            isInvalid={Boolean(errors?.Sales_Turnover)}
          >
            <HoverableFormLabel>Sales Turnover*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("Sales_Turnover", {
                required: "Turnover is required",
              })}
            />
            {errors?.Sales_Turnover && (
              <Text color="red.500" fontSize="sm">
                {errors?.Sales_Turnover?.message}
              </Text>
            )}
          </FormControl>
        </HStack>
        <HStack gap={4}>
          {/* Claim Experience for past 3 years */}
          <FormControl isInvalid={Boolean(errors?.Claim_Experience)}>
            <HoverableFormLabel>
              Claim Experience for past 3 years*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Claim_Experience", {
                required: "This field is required",
              })}
            />
            {errors?.Claim_Experience && (
              <Text color="red.500" fontSize="sm">
                {errors?.Claim_Experience?.message}
              </Text>
            )}
          </FormControl>

          {/* Any other details */}
          <FormControl>
            <HoverableFormLabel>Any other details</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Any_other_details")}
            />
          </FormControl>
        </HStack>
        <Box borderTop="1px solid " mt={4} pt={4}>
          <Text fontWeight="bold" color="darkorchid" mb="4">
            Optional Coverages{" "}
            <span style={{ fontSize: "10px", color: "gray" }}>(Sublimit)</span>
          </Text>
        </Box>
        <HStack gap={4}>
          {/* Optional fields */}
          <FormControl>
            <HoverableFormLabel>
              Outside Directorship Liability
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("OutsideDirectorshipLiability")}
            />
          </FormControl>

          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Former Directors Officers</HoverableFormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Liability incurred due to wrongful act of the former directors and officers"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("FormerDirectorsOfficers")}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>
              Company Securities Liability
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("CompanySecuritiesLiability")}
            />
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Counselling Costs</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("CounsellingCosts")}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Civil fines penalties</HoverableFormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Payment for fines and penalties resulting from claims made against the insured persons"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("Civilfinespenalties")}
            />
          </FormControl>

          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Crisis Mitigation Costs</HoverableFormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Cover the expense arise due to unexpected events such as legal fees ,public relation consultant and other resource to utilized"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("CrisisMitigationCosts")}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <HoverableFormLabel>
              Defense expenses for breaking laws
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("Defenseexpensesbreakinglaws")}
            />
          </FormControl>

          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>
                Deprivation of Assets Costs
              </HoverableFormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Expenses incurred by executives for housing, utilities, and personal insurance services when their personal assets are at risk due to legal liabilities ."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("DeprivationofAssetsCosts")}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Abduction Response Costs</HoverableFormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Provide financial protection for insured individuals facing kidnapping, hijacking, or wrongful detention situations during the policy period."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("AbductionResponseCosts")}
            />
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Emergency Costs</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("EmergencyCosts")}
            />
          </FormControl>
        </HStack>
        <HStack gap={4}>
          <FormControl>
            <Flex align="start">
              <HoverableFormLabel>Extradition Costs</HoverableFormLabel>

              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="legal fees and expenses incurred by an insured person to oppose extradition proceedings brought against them"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("ExtraditionCosts")}
            />
          </FormControl>

          <FormControl>
            <HoverableFormLabel>Investigation Costs</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              {...register("InvestigationCosts")}
            />
          </FormControl>
        </HStack>
        <Box borderTop="1px solid " mt={4} pt={4} />
        {/* Bid Dates */}
        <HStack gap={4}>
          {/* Bid Start Date */}
          <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
            <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("start_date", {
                required: "Bid's start date is required",
              })}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>

          {/* Bid End Date */}
          <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
            <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("start_date")}
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>
        </HStack>

        <HStack gap={4}>
          {/* Policy Start Date */}
          <FormControl id="policy_start_date">
            <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("policy_start_date")}
            />
          </FormControl>

          {/* Policy End Date */}
          <FormControl id="policy_end_date">
            <HoverableFormLabel>Policy End Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch("policy_start_date")}
              {...register("policy_end_date")}
            />
          </FormControl>
        </HStack>

        {/* Expected premium */}
        <HStack gap={4}>
          {/* Anticipated Premium */}
          <FormControl
            id="expected_premium"
            isInvalid={Boolean(errors?.expected_premium)}
          >
            <Flex align="start">
              <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors?.expected_premium?.message}
              </Text>
            )}
          </FormControl>

          {/* Total Coverages */}
          <FormControl
            id="sum_insured"
            isInvalid={Boolean(errors?.sum_insured)}
          >
            <HoverableFormLabel>Limit of Liability*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("sum_insured", {
                required: "Total coverages is required",
              })}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>
        </HStack>

        {/* Previous insurer */}
        {watch("policytype") === "Renewal" && (
          <HStack gap={4}>
            {/* Previous Insurer */}
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>

            {/* Previous Policy Expiry */}
            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
        )}
        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
          <Button
            type="submit"
            borderRadius="21px"
            colorScheme="green"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </Flex>
      </VStack>
    </form>
  );
};

export default DirectorsOfficerInsurance;
