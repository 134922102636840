import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Avatar,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";
import { IoCloudDownloadOutline } from "react-icons/io5";

import { theme } from "../../partials/utils/Contants";

import useFetch from "../../partials/utils/Interceptor/Interceptor";
import "./createFolder.css";

const CreateFolder = ({ fetchData, onClose, selectedCompany }: any) => {
  const toast = useToast();
  const [folderName, setFolderName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [role, setRole] = useState<any>();
  const [roleList, setRoleList] = useState([]);
  const [errors, setErrors] = useState<{
    folderName: string;
    description: string;
    file: string;
    role: string;
  }>({
    folderName: "",
    description: "",
    file: "",
    role: "",
  });

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // trigger click on file input
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };
  function fetchTeamsList() {
    useFetch
      .get(`api/${selectedCompany}/add-client-member/`)
      .then((res) => {
        setRoleList(res.data);
      })
      .catch((error) => console.log(error));
  }
  const handleValidation = () => {
    let formIsValid = true;

    // Reset errors
    setErrors({
      folderName: "",
      description: "",
      file: "",
      role: "",
    });

    if (!folderName.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        folderName: "Please fill out this field",
      }));
      formIsValid = false;
    }

    if (!description.trim()) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        description: "Please fill out this field",
      }));
      formIsValid = false;
    }

    // if (!file) {
    //   setErrors((prevErrors) => ({ ...prevErrors, file: 'Please fill out this field' }));
    //   formIsValid = false;
    // }

    // if (!role) {
    //   setErrors((prevErrors) => ({
    //     ...prevErrors,
    //     role: "Please fill out this field",
    //   }));
    //   formIsValid = false;
    // }

    return formIsValid;
  };
  const handleSubmit = () => {
    // Prepare data to be submitted
    // const formData = new FormData();
    // formData.append("folderName", folderName);
    // formData.append("description", description);
    // if (file) {
    //   formData.append("file", file);
    // }
    if (!handleValidation()) {
      return;
    }
    setIsSubmitting(true);
    const Body = {
      name: folderName,
      description,
    };
    useFetch
      .post(`/api/${selectedCompany}/folders/create/`, Body)
      .then((res) => {
        onClose();
        fetchData();

        toast({
          title: "Folder created.",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: error?.response?.data?.error || `Something went wrong`,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsSubmitting(false));
  };
  useEffect(() => {
    fetchTeamsList();
  }, []);
  return (
    <Flex direction="column" align="center" gap="16px">
      <FormControl color="white" gap="12px">
        <FormLabel className="form-label-create">Folder name:</FormLabel>
        <InputGroup className="custom-input" shadow="md" bg="#191b21">
          <Input
            type="text"
            className="custom-input"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            bg="#191b21"
          />
          {folderName && (
            <InputRightElement>
              <Icon
                as={FaCheckCircle}
                fontSize="21px"
                color={theme.color.GREEN}
              />{" "}
            </InputRightElement>
          )}
        </InputGroup>
        {!folderName && errors?.folderName && (
          <Text color="red.500" fontSize="small">
            {errors.folderName}
          </Text>
        )}
      </FormControl>
      <FormControl color="white" gap="12px">
        <FormLabel className="form-label-create">Description:</FormLabel>
        <Textarea
          bg="#191b21"
          className="custom-input"
          padding="16px 12px"
          height="130px"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          resize="none"
          shadow="md"
        />
        {!description && errors?.description && (
          <Text color="red.500" fontSize="small">
            {errors.description}
          </Text>
        )}
      </FormControl>
      <Flex direction="column" w="100%" align="start" gap="16px">
        <Text
          color={theme.color.WHITE}
          fontSize="16px"
          fontWeight="500"
          mb="6px"
        >
          Access:
        </Text>
        <Flex
          direction="column"
          w="100%"
          align="start"
          gap="16px"
          maxH="230px"
          overflowY="auto"
        >
          {roleList.map((item: any) => (
            <Flex
              align="center"
              gap="20px"
              w="100%"
              borderRadius="16px"
              px="2px"
              onClick={() => setRole(item)}
              bgColor={role === item ? "gray.400" : ""}
              cursor="pointer"
            >
              <Avatar
                borderRadius="8px"
                height="64px"
                width="64px"
                name={item?.name}
                src={item?.image}
              />
              <Text
                color={theme.color.WHITE}
                fontSize="16px"
                fontWeight="500"
                mb="6px"
              >
                {item?.name}
              </Text>
            </Flex>
          ))}
        </Flex>
        {errors?.role && (
          <Text color="red.500" fontSize="small">
            {errors.role}
          </Text>
        )}
      </Flex>
      {/* <Flex
        w="295px"
        height="150px"
        borderRadius="12px"
        border="1px dotted #6E6E6E"
        padding="20px"
        gap="15px"
        direction="column"
        align="center"
        color={theme.color.WHITE}
        onClick={handleUploadClick}
        cursor="pointer"
      >
        <input
          ref={fileInputRef}
          style={{ display: "none" }}
          type="file"
          onChange={handleFileChange}
          accept="*"
        />
        <Text fontWeight="500" fontSize="16px">
          Upload Files
        </Text>
        <Icon as={IoCloudDownloadOutline} fontSize="28px" />
        <Text fontWeight="400" fontSize="10px" textAlign="center">
          Click to browse or drag and drop your files here to upload. Max file
          size: 25 MB.{" "}
        </Text>
      </Flex> */}
      <Button
        color="black"
        borderRadius="12px"
        className="btn-bg"
        w="104px"
        h="40px"
        fontSize="12px"
        fontWeight="500"
        onClick={handleSubmit}
        isLoading={isSubmitting}
        isDisabled={isSubmitting}
      >
        Create Folder
      </Button>
    </Flex>
  );
};

export default CreateFolder;
