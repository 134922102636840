import { Link, useLocation, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import {
  Avatar,
  Divider,
  Flex,
  HStack,
  Icon,
  Image,
  Text,
  useToast,
} from "@chakra-ui/react";

import FullDarkLogo from "../../partials/assets/images/full_logo_dark.svg";
import { useStore } from "../../store/store";
import Cookies from "js-cookie";
import useLogout from "../../hooks/useLogout";
import { useFetchUser } from "../../hooks/User";
import { useEffect, useState } from "react";
import { FaCheck, FaRegBuilding } from "react-icons/fa6";
import useClickOutside from "../../hooks/useClickOutside";
import { createPortal } from "react-dom";

const Sidebar = ({ children, sidebaroptions }: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useClickOutside(() => setIsOpen(false));
  const navigate = useNavigate();
  const { logoutHandler } = useLogout();
  const { pathname } = useLocation();
  const user = useFetchUser();
  const {
    step,
    setStep,
    selectedCategories,
    setSelectedCategories,
    userCompanies,
    selectedCompany,
    setSelectedCompany,
    fetchUserCompanies,
  } = useStore();
  const toast = useToast();
  const handleLogout = () => {
    toggleDropdown();
    logoutHandler();
    Cookies.remove("jwt");
    Cookies.remove("user");
    Cookies.remove("company");
    localStorage.clear()
    window.location.href = "/login";
  };

  useEffect(() => {
    if (!userCompanies?.length) {
      fetchUserCompanies();
    }
  }, [pathname]);
  useEffect(() => {
    if (userCompanies?.length > 0 && !userCompanies.find((c)=> c.id === +selectedCompany)) {
      setSelectedCompany(userCompanies?.[0]?.id);
    }
  }, [userCompanies]);

  useEffect(() => {
    if (selectedCompany) {
      Cookies.set("company", JSON.stringify(selectedCompany));
    }
  }, [selectedCompany]);
  const innerHeight = window.innerHeight;
  const toggleDropdown = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };
  return (
    <>
      <Flex
        height="100vh"
        overflowY="auto"
        width="100%"
        bg="rgb(75 235 241)"
        direction="column"
      >
        <Flex minH="100vh" height="100%" width="100%" className="home-bg">
          <Flex
            direction="column"
            w="fit-content"
            maxW="295px"
            padding="10px 25px 2px"
            gap="6px"
            height="100%"
            minHeight="100vh"
            overflow="auto"
            className="hide-scrollbar"
            position="relative"
          >
            <Image src={FullDarkLogo} height="4.3rem" width="177px" />
            <Flex direction="column" gap="3px" height="100%">
              {sidebaroptions?.map((item: any, index: number) => (
                <Link key={index} to={item.link}>
                  <Flex
                    align="center"
                    gap="10px"
                    borderRadius="12px"
                    padding="12px 20px"
                    bg={pathname.includes(item.link) ? "white" : "transparent"}
                    onClick={() => {
                      if (item.label === "Create Bid") {
                        setSelectedCategories([]);
                        setStep(1);
                      }
                    }}
                  >
                    <Icon as={item.icon} fontSize="1.4rem" />
                    <Text fontSize="0.88rem" fontWeight="600">
                      {item.label}
                    </Text>
                  </Flex>
                </Link>
              ))}
            </Flex>
            <Flex ps="20px">
              {/* <Menu>
                <MenuButton> */}
              <Avatar
                name={user?.email || ""}
                src=""
                size="sm"
                cursor="pointer"
                onClick={toggleDropdown}
              />
              {/* </MenuButton> */}

              {isOpen &&
                createPortal(
                  <Flex
                    direction="column"
                    bg="white"
                    ref={menuRef}
                    minW={user?.role === 2 ? "330px" : "270px"}
                    maxW="350px"
                    borderRadius="16px"
                    position="absolute"
                    bottom="45px"
                    left="40px"
                    zIndex="999"
                    shadow="md"
                    padding="5px 8px"
                    gap="5px"
                  >
                    <Flex px="7px" align="center" gap="5px">
                      <Avatar name={user?.email || ""} src="" size="sm" />{" "}
                      <Text>{user?.email || ""}</Text>
                    </Flex>
                    <Text fontWeight="600" fontSize="14px">
                      Profile
                    </Text>
                    {user?.role === 2 ? (
                      <Text
                        w="100%"
                        cursor="pointer"
                        _hover={{ bg: "gray.50" }}
                        onClick={() => {
                          navigate("/profile");
                          toggleDropdown();
                        }}
                      >
                        Companies Management
                      </Text>
                    ) : (
                      <Text
                        w="100%"
                        cursor="pointer"
                        _hover={{ bg: "gray.50" }}
                        onClick={() => {
                          navigate("/setupProfile");
                          toggleDropdown();
                        }}
                      >
                        Edit Profile
                      </Text>
                    )}
                    {user?.role === 2 && userCompanies?.length > 0 && (
                      <Flex
                        direction="column"
                        py="10px"
                        gap="5px"
                        maxH="180px"
                        overflow="auto"
                      >
                        <Text fontWeight="600" fontSize="14px">
                          User Companies :
                        </Text>
                        {userCompanies?.map((c) => (
                          <Flex
                            px="10px"
                            align="center"
                            justify="space-between"
                            key={c.id}
                            onClick={() => {
                              setSelectedCompany(c?.id);
                              toggleDropdown();
                            }}
                            cursor="pointer"
                            _hover={{ bg: "gray.50" }}
                          >
                            <HStack>
                              <Icon as={FaRegBuilding} fontSize="16px" />
                              <Text>{c?.c_name}</Text>
                            </HStack>
                            {+c.id === +selectedCompany && (
                              <Icon as={FaCheck} fontSize="16px" />
                            )}
                          </Flex>
                        ))}
                      </Flex>
                    )}
                    <Divider />
                    <Text
                      onClick={handleLogout}
                      w="100%"
                      cursor="pointer"
                      _hover={{ bg: "gray.50" }}
                    >
                      Logout
                    </Text>
                  </Flex>,
                  document.body
                )}
            </Flex>
          </Flex>
          <Flex
            bg="white"
            borderRadius="24px 0px 0px 24px"
            flex="1"
            ps="5px"
            height="100%"
            overflow="auto"
            flexDirection="column"
          >
            <Flex maxHeight="100%" overflow="auto" flexDirection="column">
              {children}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      {/* <HelpDeskWidget /> */}
      <Toaster />
    </>
  );
};

export default Sidebar;
