import { useRef, useEffect, useState } from "react";
import { Flex, Text, Button, Icon } from "@chakra-ui/react";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";
import { ReactComponent as ClipboardCheck } from "../../partials/assets/icons/clipboard-check.svg";
import { ReactComponent as ClipboardX } from "../../partials/assets/icons/clipboard-x.svg";
import { HiOutlineSparkles } from "react-icons/hi2";
import { addCommas } from "../../partials/utils/helper";
import { theme } from "../../partials/utils/Contants";

interface CardData {
  id: string;
  know_your_policy?: string;
  insurance_company_name: string;
  bidder_name?: string;
  bidder_company_name: string;
  match_status?: string;
  premium_amount: number;
  quote_file?: string;
  policy_file?: string;
  policy: any;
  bidder_type: string;
}

// Define the type for the props
interface ProposalCardProps {
  data: CardData;
  showSelection: string | null;
  setShowSelection: (id: any) => void;
  status: string;
  setSelectedQuote: (quote: any) => void;
  onOpen: () => void;
  handleDownloadPDF: (url: any) => void;
  handleQuoteInsight: (id: string) => void;
}
const ProposalCard = ({
  data,
  showSelection,
  setShowSelection,
  status,
  setSelectedQuote,
  onOpen,
  handleDownloadPDF,
  handleQuoteInsight,
}: ProposalCardProps) => {
  const cardRef = useRef<HTMLDivElement | null>(null);
  const [cardHeight, setCardHeight] = useState<number>(0);

  // Update the card height whenever the component mounts or data changes
  useEffect(() => {
    if (cardRef.current) {
      setCardHeight(cardRef.current.getBoundingClientRect().height - 1);
    }
  }, [data, showSelection]);

  return (
    <Flex
      align="center"
      key={data?.id}
      pos="relative"
      maxH="fit-content"
      direction="row"
      w="100%"
      boxShadow="md"
    >
      <Flex
        ref={cardRef}
        zIndex="1"
        direction="column"
        gap="12px"
        width={showSelection === data?.id ? "90%" : "100%"}
        className="bid-card-selected"
        padding="12px"
        position="relative"
        height="100%"
        minH="130px"
        maxH="fit-content"
        borderRadius="19px"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          setShowSelection(data?.id);
        }}
      >
        <Flex align="center" justify="space-between" w="100%">
          {data?.match_status && (
            <Flex
              width="fit-content"
              align="center"
              gap="3px"
              borderRadius="12px"
              // height="21px"
              bg="black"
              p="4px 6px"
            >
              <Icon
                fontSize="16px"
                as={data?.match_status === "poor" ? ClipboardX : ClipboardCheck}
                color={
                  data?.match_status === "poor"
                    ? theme.color.YELLOW
                    : theme.color.GREEN
                }
              />
              <Text
                fontSize="12px"
                color={
                  data?.match_status === "poor"
                    ? theme.color.YELLOW
                    : theme.color.GREEN
                }
                textTransform="capitalize"
              >
                {data?.match_status} Match
              </Text>
            </Flex>
          )}
          {data?.know_your_policy && (
            <Button
              bg={theme.color.GREEN}
              colorScheme="green"
              size="xs"
              color="white"
              fontSize="11px"
              borderRadius="16px"
              py="2px"
              as="a"
              href={data?.know_your_policy}
              target="_blank"
            >
              Know your Policy
            </Button>
          )}
        </Flex>
        <Flex width="100%" align="center" gap="8px" wrap="wrap">
          <Text fontSize="15px" fontWeight="700" color="white" cursor="default">
            {data?.bidder_company_name}
          </Text>
          {data?.bidder_type !== "Insurance Company" &&
            data?.insurance_company_name && (
              <Text
                cursor="default"
                fontSize="15px"
                fontWeight="700"
                color="rgba(255, 255, 255, 0.50)"
              >
                | {data?.insurance_company_name}
              </Text>
            )}
        </Flex>
        <Flex width="100%" align="center" justify="space-between">
          <Text
            fontSize="12px"
            fontWeight="700"
            color="rgba(131, 131, 131, 1)"
            whiteSpace="nowrap"
            cursor="default"
          >
            ₹{addCommas(data?.premium_amount)}
          </Text>
        </Flex>
        <Flex width="100%" justify="space-between" align="center">
          <Flex align="center" gap="10px">
            <Flex
              align="center"
              gap="5px"
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadPDF(data?.quote_file);
              }}
              cursor="pointer"
            >
              <PdfIcon height="18px" width="auto" fontWeight="700" />
              <Text
                fontWeight="600"
                fontSize="14px"
                color="rgba(255, 255, 255, 0.70)"
              >
                Proposal
              </Text>
            </Flex>
            {data?.policy?.policy_file && (
              <Flex
                align="center"
                gap="5px"
                cursor="pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleDownloadPDF(data?.policy?.policy_file);
                }}
              >
                <PdfIcon height="18px" width="auto" fontWeight="700" />
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  color="rgba(255, 255, 255, 0.70)"
                >
                  Policy
                </Text>
              </Flex>
            )}
          </Flex>
          <Button
            leftIcon={<HiOutlineSparkles />}
            size="xs"
            fontSize="12px"
            borderRadius="16px"
            onClick={() => handleQuoteInsight(data?.id)}
            bg="rgba(255, 255, 255, 1)"
            color="black"
          >
            Insights
          </Button>
        </Flex>
      </Flex>

      {showSelection === data?.id && (
        <Flex
          height={`${cardHeight}px`}
          bg={status === "Rejected" ? "red" : theme.color.GREEN}
          position="absolute"
          right="0px"
          w="100%"
          borderRadius="22px"
          align="center"
          justify="end"
          cursor="pointer"
          transition="background-color 1s ease-in, width 0.3s ease-in"
          padding="0px"
          onClick={() => {
            if (status === "Select") {
              setSelectedQuote({ id: data?.id });
              onOpen();
            }
          }}
        >
          <Flex
            transform="rotate(-90deg)"
            position="relative"
            align="end"
            justify="center"
          >
            <Text
              color="white"
              fontSize="12px"
              fontWeight="500"
              whiteSpace="nowrap"
              pos="absolute"
              textAlign="end"
              pb="5px"
            >
              {status}
            </Text>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default ProposalCard;
