import { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  Input,
  Text,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Spinner,
  Image,
  useToast,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import DocumentThumbnail from "../../components/Thumbnails/DocumentThumbnail";
import CreateFolder from "../../components/CreateFolder/CreateFolder";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useNavigate } from "react-router-dom";
import safeImage from "../../partials/assets/images/safe.png";
import { CiSearch } from "react-icons/ci";
import { useStore } from "../../store/store";
import {
  productMapping,
  reverseProductMaping,
} from "../../partials/utils/Contants";
import toast from "react-hot-toast";

const DocumentVault = () => {
  const { selectedCompany } = useStore();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  //states
  const [isLoading, setIsLoading] = useState(false);
  const [defaultFoldersList, setDefaultFoldersList] = useState([]);
  const [createdFoldersList, setCreatedFoldersList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value.toLowerCase());
  };
  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // trigger click on file input
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
    }
  };
  function fetchVault() {
    setIsLoading(true);
    useFetch
      .get(`api/${selectedCompany}/folders/`)
      .then((response) => {
        console.log("response", response?.data);
        setCreatedFoldersList(response?.data?.created_folder_listing);
        setDefaultFoldersList(response?.data?.default_folder_listing);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    if (selectedCompany) {
      fetchVault();
    }
  }, [selectedCompany]);
  const handleDefaultNavigate = (id: string, name: string) => {
    const parsed = encodeURIComponent(reverseProductMaping[name] || name);
    navigate(`/document-vault/default-listing?name=${parsed}`);
  };
  const handleCreaetedNavigate = (id: string, name: string) => {
    const parsed = encodeURIComponent(name);
    navigate(`/document-vault/created-files?id=${id}&name=${parsed}`);
  };
  const handleDownloadZipFolder = (id: string, name?: string) => {
    const toastId = toast.loading("Downloading...");
    useFetch
      .post(`api/${selectedCompany}/download-folder/?folder_id=${id}`)
      .then((res) => {
        console.log("res.data", res.data);
        const downloadUrl = res.data?.download_url;
        const link = document.createElement("a");
        link.href = downloadUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success("Downloaded Successfully", { id: toastId });
      })
      .catch((error) => {
        console.log("error", error.response.data);
        toast.error(error.response.data?.error || "Something Went wrong", {
          id: toastId,
        });
      });
  };
  const filteredDefaultFolders = defaultFoldersList?.filter((item: any) =>
    item?.product?.toLowerCase().includes(searchQuery)
  );

  const filteredCreatedFolders = createdFoldersList?.filter((item: any) =>
    item?.folder_name?.toLowerCase().includes(searchQuery)
  );
  return (
    <>
      <Flex
        direction="column"
        w="100%"
        gap="30px"
        padding="35px"
        pb="60px"
        overflow="auto"
        height="100%"
        maxH="calc(100dvh - 20px)"
      >
        <Text fontSize="30px" fontWeight="500">
          Document Vault
        </Text>
        <Flex position="relative" w="100%">
          <InputGroup>
            <InputLeftElement>
              <CiSearch />
            </InputLeftElement>
            <Input
              borderRadius="16px"
              type="text"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              placeholder="Search"
              fontSize="12px"
              fontWeight="500"
              value={searchQuery}
              onChange={handleSearch}
            />
          </InputGroup>
        </Flex>
        <Flex w="100%" justify="space-between">
          <Text fontSize="24px" fontWeight="500">
            Default Folders
          </Text>
          {/* <Menu>
            <MenuButton
              as={Button}
              rightIcon={<IoIosArrowDown />}
              borderRadius="16px"
              bg="white"
              border="1px solid #dadada"
              _active={{
                bg: "black",
                color: "white",
                border: "1px solid black",
              }}
            >
              Filter
            </MenuButton>
            <MenuList
              bg="black"
              color="white"
              padding="25px 5px 15px 15px"
              gap="20px"
              borderRadius="16px"
            >
              <Text p="0px 10px 10px 15px">Filter by:</Text>
              {["By Entity", "By Policy", "By Bid"].map((item) => (
                <MenuItem
                  bg="black"
                  color="rgba(246, 246, 246, 0.50)"
                  fontSize="14px"
                  fontWeight="400"
                >
                  <Flex
                    align="center"
                    justify="space-between"
                    gap="8px"
                    w="100%"
                  >
                    <Text fontSize="14px" fontWeight="500">
                      {item}
                    </Text>
                    <Icon as={IoIosArrowForward} fontSize="12px" />
                  </Flex>
                </MenuItem>
              ))}
            </MenuList>
          </Menu> */}
        </Flex>
        {!isLoading && filteredDefaultFolders?.length ? (
          <Flex w="100%" align="center" wrap="wrap" gap="24px">
            {filteredDefaultFolders?.map((item: any, index: number) => {
              const title = productMapping[item?.product] || item?.product;
              return (
                <DocumentThumbnail
                  key={index}
                  title={title}
                  fileCount={item?.total_files}
                  data={item}
                  type="default"
                  handleClick={handleDefaultNavigate}
                  handleDownload={handleDownloadZipFolder}
                  id={item?.product}
                  hideMenu={true}
                />
              );
            })}
          </Flex>
        ) : (
          <Flex
            direction="column"
            w="100%"
            align="center"
            gap="12px"
            justify="center"
            minH="200px"
          >
            {isLoading ? (
              <Spinner />
            ) : (
              <Flex gap="15px" align="center" justify="start" w="100%">
                <Image src={safeImage} w="120px" height="100px" />
                <Flex direction="column" gap="25px" maxW="500px">
                  <Text fontWeight="600">
                    Your secure document vault with all documents appear here.
                    Create your first bid to get started
                  </Text>
                  <Button
                    borderRadius="24px"
                    bg="black"
                    colorScheme="black"
                    color="white"
                    padding="4px 14px"
                    fontSize="12px"
                    fontWeight="500"
                    w="104px"
                    h="24px"
                    onClick={() => navigate("/create-bid")}
                  >
                    Create Bid
                  </Button>
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
        <Flex w="100%" justify="space-between" align="center">
          <Text fontSize="24px" fontWeight="500">
            Created Folders
          </Text>
          <Flex gap="20px" align="center">
            <Button
              borderRadius="16px"
              bg="black"
              color="white"
              colorScheme="black"
              _hover={{ filter: "opacity(0.75)" }}
              onClick={onOpen}
              ref={btnRef}
            >
              Create Folder
            </Button>
            {/* <div>
              <Button
                borderRadius="16px"
                color="black"
                onClick={handleUploadClick}
              >
                Upload Folder
              </Button>
              <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                onChange={handleFileChange}
                accept="*"
              />
            </div> */}
          </Flex>
        </Flex>
        {!isLoading && filteredCreatedFolders?.length ? (
          <Flex w="100%" align="center" wrap="wrap" gap="24px">
            {filteredCreatedFolders.map((item: any, index: number) => {
              return (
                <DocumentThumbnail
                  key={index}
                  title={item?.folder_name}
                  fileCount={item?.total_files}
                  data={item}
                  type="created"
                  handleClick={handleCreaetedNavigate}
                  handleDownload={handleDownloadZipFolder}
                  id={item?.folder_id}
                />
              );
            })}
          </Flex>
        ) : (
          <Flex
            direction="column"
            w="100%"
            align="center"
            gap="12px"
            justify="center"
            minH="200px"
          >
            {isLoading ? <Spinner /> : <Text>No Folders Available</Text>}
          </Flex>
        )}
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent className="black-bg" py="20px" color="white">
          <DrawerCloseButton color="white" />
          <DrawerHeader>Create Folder</DrawerHeader>

          <DrawerBody>
            <CreateFolder
              fetchData={fetchVault}
              onClose={onClose}
              selectedCompany={selectedCompany}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DocumentVault;
