// components/HoverableFormLabel.js
import React from "react";
import { FormLabel } from "@chakra-ui/react";
import { useStore } from "../../store/store";

const HoverableFormLabel = ({ input, children, ...props }: any) => {
  const { hoveredLabel, setHoveredLabel, resetHoveredLabel } = useStore();

  const label = input
    ? input
    : typeof children === "string" && children.endsWith("*")
    ? children.slice(0, -1)
    : children;

  const handleMouseEnter = () => {
    setHoveredLabel(label?.trim());
  };

  const handleMouseLeave = () => {
    resetHoveredLabel();
  };

  return (
    <FormLabel
      {...props}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      w="fit-content"
      cursor="pointer"
    >
      {children}
    </FormLabel>
  );
};

export default HoverableFormLabel;
