import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { theme } from "../../partials/utils/Contants";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";
import { HiOutlineSparkles } from "react-icons/hi2";
import { BiDownload } from "react-icons/bi";
import { addCommas } from "../../partials/utils/helper";
import ProposalCard from "../ProposalCard";
import { useStore } from "../../store/store";
import { FaCheckCircle } from "react-icons/fa";
import QuoteComparison from "../QuoteComparison";
import { downloadUsingURL } from "../../partials/utils/Contants/useDownload";

type ProposalsProps = {
  id: number;
  setInsightType: any;
  setSanbotTabIndex: any;
  onComparisonOpen: () => void;
  onComparisonClose: () => void;
};
const Proposals = ({
  id,
  setInsightType,
  setSanbotTabIndex,
  onComparisonOpen,
  onComparisonClose,
}: ProposalsProps) => {
  const {
    selectedCompany,
    selectedQuotesList,
    setSelectedQuotesList,
    setQoteComparisonBidId,
  } = useStore();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [quoteList, setQuoteList] = useState<any>([]);
  const [showSelection, setShowSelection] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [enableSelect, setEnableSelect] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState({ id: null });
  function fetchBidData() {
    setIsLoading(true);
    useFetch
      .get(`api/${selectedCompany}/get-quote-files/${id}/`)
      .then((res) => {
        setQuoteList(res.data?.quotes);
      })
      .catch((error) => {})
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    if (id) {
      fetchBidData();
    }
  }, [id]);
  // const handleDownloadPDF = (
  //   id: string,
  //   type: "quote-file" | "policy-file"
  // ) => {
  //   useFetch
  //     .get(`download-${type}/${id}`, { responseType: "blob" })
  //     .then((res) => {
  //       const url = window.URL.createObjectURL(new Blob([res.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute("download", `policy_${id}.pdf`);
  //       document.body.appendChild(link);
  //       link.click();
  //       link.remove();
  //       toast({
  //         title: "Downloaded Successfully",
  //         description: "",
  //         status: "success",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     })
  //     .catch((error) => {
  //       console.error("Error downloading the PDF:", error);
  //       toast({
  //         title: "Dowload failed!",
  //         description: `${
  //           error?.response?.data?.message ||
  //           "There was an issue Downloading pdf. Please try again."
  //         }`,
  //         status: "error",
  //         duration: 3000,
  //         isClosable: true,
  //       });
  //     });
  // };

  const handleAcceptQuote = () => {
    setIsSubmitting(true);
    useFetch
      .post(`api/${selectedCompany}/accept-quote/${selectedQuote?.id}/`)
      .then((res) => {
        toast({
          title: "Quote accepted Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });

        onClose();
        fetchBidData();
        setSelectedQuote({ id: null });
      })
      .catch((error) => {
        toast({
          title: "Something Went wrong",
          description: error?.response?.data?.message || "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsSubmitting(false));
  };
  const handleSelectCategory = (id: any) => {
    if (selectedQuotesList.includes(id)) {
      setSelectedQuotesList(
        selectedQuotesList.filter((ids: any) => ids !== id)
      );
    } else {
      setSelectedQuotesList([...selectedQuotesList, id]);
    }
  };
  const hadnleShowComparison = () => {
    if (selectedQuotesList.length < 2) {
      toast({
        title: "Please choose at least two quotes to compare",
        description: "",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }else if (selectedQuotesList.length > 10) {
      toast({
        title: "You can only compare maimum of 10 quotes at a time",
        description: "",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setQoteComparisonBidId(id);
    onComparisonOpen();
  };
  const handleQuoteInsight = (id: string) => {
    setInsightType({ id, type: "quote" });
    setSanbotTabIndex(0);
  };
  useEffect(() => {
    return () => {
      setSelectedQuotesList([]);
    };
  }, []);

  const hasAccepted = quoteList?.find((x: any) => x?.is_accepted === 1);
  return (
    <>
      {isLoading ? (
        <Skeleton height="100px" w="100%" borderRadius="16px" />
      ) : null}
      {!isLoading && !quoteList?.length ? (
        <Flex height="20vh" align="end">
          <Text color="white">No Proposals found for this bid</Text>
        </Flex>
      ) : (
        !isLoading && (
          <Flex
            w="100%"
            gap="30px"
            direction="column"
            p="0px 14px 25px 0px"
            color="white"
            height="auto"
            overflow="auto"
          >
            <Flex w="100%">
              {enableSelect ? (
                <Button
                  borderRadius="24px"
                  bg="white"
                  colorScheme="white"
                  color="black"
                  size="sm"
                  height="24px"
                  fontSize="10px"
                  fontWeight="500"
                  onClick={hadnleShowComparison}
                >
                  Compare
                </Button>
              ) : (
                <Button
                  borderRadius="24px"
                  bg="white"
                  colorScheme="white"
                  color="black"
                  size="sm"
                  height="24px"
                  fontSize="10px"
                  fontWeight="500"
                  onClick={() => setEnableSelect(!enableSelect)}
                >
                  Select
                </Button>
              )}
            </Flex>
            {quoteList.map((data: any) => {
              const isAccepted = data?.is_accepted === 1;
              let status = "Select";
              if (hasAccepted && isAccepted) {
                if (!data?.policy_file) {
                  status = "Policy Pending";
                } else {
                  status = "Policy Complete";
                }
              } else if (hasAccepted && !isAccepted) {
                status = "Declined";
              }
              return (
                <Flex key={data.id} w="100%" gap="10px">
                  {enableSelect && (
                    <>
                      {selectedQuotesList?.includes(data.id) ? (
                        <Icon
                          as={FaCheckCircle}
                          fontSize="21px"
                          color={theme.color.GREEN}
                          onClick={() => handleSelectCategory(data.id)}
                          cursor="pointer"
                        />
                      ) : (
                        <Flex
                          borderRadius="100px"
                          bg="#D9D9D9"
                          width="21px"
                          height="21px"
                          onClick={() => handleSelectCategory(data.id)}
                          cursor="pointer"
                        />
                      )}
                    </>
                  )}

                  <ProposalCard
                    data={data}
                    handleDownloadPDF={downloadUsingURL}
                    onOpen={onOpen}
                    setSelectedQuote={setSelectedQuote}
                    setShowSelection={setShowSelection}
                    showSelection={showSelection}
                    status={
                      data?.quote_status === "Pending"
                        ? "Select"
                        : data?.quote_status
                    }
                    handleQuoteInsight={handleQuoteInsight}
                  />
                </Flex>
              );
            })}
            <Modal isOpen={isOpen} onClose={onClose}>
              <ModalOverlay />
              <ModalContent borderRadius="21px" pb="35px">
                <ModalCloseButton />
                <ModalBody pt="50px">
                  <Flex direction="column" align="center">
                    <Text fontWeight="600">
                      Are you sure you want to Accept this Quote
                    </Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="20px"
                    w="full"
                    justify="center"
                    mt="20px"
                  >
                    <Button
                      borderRadius="21px"
                      onClick={() => {
                        onClose();
                        setSelectedQuote({ id: null });
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      borderRadius="21px"
                      colorScheme="green"
                      background={theme.color.GREEN}
                      onClick={handleAcceptQuote}
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting}
                    >
                      Accept
                    </Button>
                  </Flex>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Flex>
        )
      )}
    </>
  );
};

export default Proposals;
