import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { theme } from "../../partials/utils/Contants";
import { useStore } from "../../store/store";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useEffect, useState } from "react";
import CompanyDropdown from "../CompanyDropdown";
import { useLocation } from "react-router-dom";

type FormData = {
  c_p_name: string;
  c_name: string;
  business_type: string;
  business_type_name: string;
  c_gstin: string;
  c_contact: number | string;
  c_email: string;
  country: string;
  c_description: string;
};

type Field = {
  name: keyof FormData;
  label: string;
  isRequired: boolean;
  placeholder: string;
  type: string;
  field: "input" | "select" | "textarea";
  list?: string[];
  max?: number;
};

const CreateCompanyFrom = ({
  editData,
  isDisable = false,
  isBidMode = false,
  goBack = false,
  onClose,
}: any) => {
  const { pathname } = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<FormData>({
    defaultValues: {
      country: "India",
    },
  });
  const toast = useToast();
  const {
    step,
    setStep,
    countries,
    businessType,
    selectedCompany,
    userCompanies,
    fetchUserCompanies,
  } = useStore();
  const [companyId, setCompanyId] = useState<string | number>();
  const [isSubmitting, setIsSubmitting] = useState(false)
  const CompanyDetailFields: Field[] = [
    {
      name: "c_p_name",
      label: "Contact Person Name",
      isRequired: true,
      placeholder: "Enter contact person name",
      type: "text",
      field: "input",
      max: 255,
    },
    {
      name: "c_name",
      label: "Company Name",
      isRequired: true,
      placeholder: "Enter company name",
      type: "text",
      field: "input",
    },
    {
      name: "business_type",
      label: "Business Type",
      isRequired: true,
      placeholder: "Please select business type",
      type: "text",
      field: "select",
      list: businessType,
    },
    {
      name: "c_gstin",
      label: "Company GSTIN",
      isRequired: false,
      placeholder: "Enter company GSTIN",
      type: "text",
      field: "input",
      max: 255,
    },
    {
      name: "c_contact",
      label: "Company Contact",
      isRequired: true,
      placeholder: "Enter company contact",
      type: "number",
      field: "input",
      max: 10,
    },
    {
      name: "c_email",
      label: "Company Email",
      isRequired: true,
      placeholder: "Enter email",
      type: "email",
      field: "input",
    },
    {
      name: "country",
      label: "Country",
      isRequired: true,
      placeholder: "Please Select Country",
      type: "text",
      field: "select",
      list: countries,
    },
    {
      name: "c_description",
      label: "Business Description",
      isRequired: true,
      placeholder: "",
      type: "text",
      field: "textarea",
    },
  ];

  const onSubmit = handleSubmit((data) => {
    if (selectedCompany && isDisable) {
      setStep(4);
    } else {
      setIsSubmitting(true)
      let Body: any = { ...data };
      if (editData && companyId) {
        Body = { ...data, company_id: companyId };
        useFetch
          .put("api/company/", Body)
          .then((response) => {
            const msg = `Company with ID #${response?.data?.company.id} updated successfully.`;
            toast({
              title: msg,
              description: "",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            pathname === "/profile" && onClose();
            fetchUserCompanies();
            // localStorage.setItem("comp_id", response?.data?.company?.id);
          })
          .catch((error) => {
            console.log("error", error);
            toast({
              title: error?.response?.data?.message || "Something went wrong",
              description: "",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }).finally(()=>setIsSubmitting(false))
      } else {
        setIsSubmitting(true)
        useFetch
          .post("api/company/", Body)
          .then((response) => {
            const msg = `Company created successfully with ID #${response?.data?.company.id}.`;
            toast({
              title: msg,
              description: "",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            localStorage.setItem("comp_id", response?.data?.company?.id);
            setStep(4);
            pathname === "/profile" && onClose();
            fetchUserCompanies();
          })
          .catch((error) => {
            console.log("error", error);
            toast({
              title: error?.response?.data?.message || "Something went wrong",
              description: "",
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }).finally(()=>setIsSubmitting(false))
      }
    }
  });

  useEffect(() => {
    if (!editData && !editData?.id) {
      reset();
    } else {
      setCompanyId(editData?.id);
      setValue("business_type", editData?.business_type);
      setValue("c_contact", editData?.c_contact);
      setValue("c_description", editData?.c_description);
      setValue("c_name", editData?.c_name);
      setValue("country", editData?.country);
      setValue("c_email", editData?.c_email);
      setValue("c_p_name", editData?.c_p_name);
      setValue("c_gstin", editData?.c_gstin);
    }
  }, [editData, selectedCompany]);

  useEffect(() => {
    if (!editData?.id && localStorage.getItem("comp_id")) {
      const comp_id = localStorage.getItem("comp_id");
      if (comp_id) setCompanyId(comp_id);
    }
    fetchUserCompanies();
  }, []);

  return (
    <Flex
      direction="column"
      w="100%"
      height="100%"
      maxH="calc(80vh - 40px)"
      overflow="auto"
      className="hide-scrollbar"
    >
      {isBidMode && (
        <Flex w="100%" justify="end">
          <CompanyDropdown />
        </Flex>
      )}
      <form
        onSubmit={onSubmit}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        <SimpleGrid columns={2} gap="20px" mt="15px">
          {CompanyDetailFields.map((field) => (
            <FormControl
              key={field.name}
              isInvalid={Boolean(errors[field.name])}
            >
              <Flex align="start">
                <FormLabel me="3px" color="rgba(35,28,99,.7)" fontWeight="600">
                  {field.label}
                </FormLabel>{" "}
                {field.isRequired && (
                  <Text color="red.500" fontWeight="500">
                    *
                  </Text>
                )}
              </Flex>
              {field.field === "textarea" ? (
                <Textarea
                  placeholder={field.placeholder}
                  {...register(field.name, {
                    required: field.isRequired,
                  })}
                  title={field.isRequired ? "Please fill out this field" : ""}
                  bg="white"
                  isDisabled={isDisable}
                />
              ) : field.field === "input" ? (
                <Input
                  isDisabled={isDisable}
                  type={field.type}
                  bg="white"
                  borderRadius="21px"
                  placeholder={field.placeholder}
                  color={theme.color.INPUT_FONT}
                  {...register(field.name, {
                    required: field.isRequired,
                    ...(field.name === "c_contact" && {
                      validate: (value) =>
                        String(value).length === 10 ||
                        "Phone number must be 10 digits long",
                    }),
                    ...(field.name === "c_email" && {
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Please enter a valid email address",
                      },
                    }),
                  })}
                  title={field.isRequired ? "Please fill out this field" : ""}
                />
              ) : (
                <Select
                  isDisabled={isDisable}
                  borderRadius="21px"
                  bg="white"
                  placeholder={field.placeholder}
                  {...register(field.name, {
                    required: field.isRequired,
                  })}
                  title={field.isRequired ? "Please fill out this field" : ""}
                >
                  {field.list?.map((item: any) => (
                    <option key={item.id} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </Select>
              )}
              {errors[field.name] && (
                <FormErrorMessage>
                  {errors[field.name]?.message || `${field.label} is required`}
                </FormErrorMessage>
              )}
            </FormControl>
          ))}
        </SimpleGrid>
        <Flex w="100%" justify="space-between">
          {!isDisable ? (
            <Flex minW="30px" />
          ) : (
            <>
              {!goBack && step === 3 ? (
                <Flex minW="30px" />
              ) : (
                <Button onClick={() => setStep(step - 1)} borderRadius="21px">
                  Back
                </Button>
              )}
            </>
          )}
          <Button
            colorScheme="green"
            borderRadius="21px"
            bg={theme.color.GREEN}
            color="white"
            type="submit"
            isDisabled={isSubmitting}
          >
            {isDisable ? "Next" : "Submit"}
          </Button>
        </Flex>
      </form>
    </Flex>
  );
};

export default CreateCompanyFrom;
