import { useEffect, useState } from "react";
import SanbotLogo from "../../partials/assets/images/galaxy-logo.png";
import {
  Flex,
  Icon,
  Image,
  Text,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Skeleton,
} from "@chakra-ui/react";
import { HiOutlineSparkles } from "react-icons/hi2";
import { productMapping, theme } from "../../partials/utils/Contants";
import { AiOutlineSend } from "react-icons/ai";

import useFetch from "../../partials/utils/Interceptor/Interceptor";
import Messages from "../Messages";
import { Insight } from ".";
type InsightProps = {
  selectedBid: any;
  insightType: Insight;
};
export const parseTextForBold = (text: string) => {
  const parsedText = text.split(/(\*\*.*?\*\*)/g).map((segment, index) => {
    if (segment.startsWith("**") && segment.endsWith("**")) {
      const boldText = segment.slice(2, -2);
      return <b key={index}>{boldText}</b>;
    }
    return segment;
  });

  return parsedText;
};
export const InsightCard = ({ text, themeMode, width }: any) => {
  return (
    <Flex
      padding="16px 12px"
      gap="10px"
      backdropBlur="15px"
      bg={themeMode === "dark" ? "rgb(255 255 255 / 10%)" : "#FFF"}
      color={themeMode === "dark" ? "gray.100" : "rgba(115, 115, 115, 1)"}
      borderRadius="16px"
      mt="10px"
      width={width || "100%"}
      boxShadow="sm"
      whiteSpace="pre-line"
    >
      {themeMode === "dark" ? (
        <Icon as={HiOutlineSparkles} fontSize="18px" />
      ) : (
        <Image src={SanbotLogo} height="24px" w="24px" alt="sanbot" />
      )}
      <Text fontSize="13px" fontWeight="500">
        {parseTextForBold(text)}
      </Text>
    </Flex>
  );
};
const Insights = ({ selectedBid, insightType }: InsightProps) => {  
  const [sessionId, setSessionId] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const product =
    productMapping[selectedBid?.data?.product] || selectedBid?.data?.product;
  const policyInsightData = [
    {
      role: "assistant",
      content: `All ${selectedBid?.data?.interests?.length} bidders have provided you that the quotes for you ${product} `,
    },
    ...(selectedBid?.data?.sentences?.[0]
      ? [
          {
            role: "assistant",
            content: selectedBid?.data?.sentences?.[0],
          },
        ]
      : []),
    ...(selectedBid?.data?.sentences?.[1]
      ? [
          {
            role: "assistant",
            content: selectedBid?.data?.sentences?.[1],
          },
        ]
      : []),
  ];
  const [messages, setMessages] = useState<any>([]);
  const [inputMessage, setInputMessage] = useState<string>("Hi");
  const handleStartInsights = () => {
    useFetch
      .post("conversations/start/")
      .then((res) => {
        setSessionId(res.data?.session_id);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleSendMessage = (newMessage: any) => {
    if (!newMessage.trim().length) {
      return;
    }
    const data = newMessage;
    setInputMessage("");
    let callMsgs = [...messages, { role: "user", content: data }];
    setMessages([...callMsgs, { role: "typing", content: "" }]);
    setTimeout(async () => {
      // const bidData= selectedBid?.data?.policy_for_bid?[0] ;
      const url =
        insightType.type === "quote"
          ? `conversations/chat/${sessionId}/quote/${insightType.id}/`
          : insightType.type === "policy"
          ? `conversations/chat/${sessionId}/policy/${insightType.id}/`
          : `conversations/chat/${sessionId}/`;
      let body: any = {
        message: inputMessage,
      };
      if (
        (insightType.type !== "quote" && insightType.type !== "policy") ||
        selectedBid.action === "Insights"
      ) {
        body = {
          ...body,
          product_type: selectedBid?.data?.product,
          bid_id: selectedBid.id,
        };
      }
      useFetch
        .post(url, body)
        .then((res) => {
          let responses: any = [];
          responses.push({
            role: "assistant",
            content: res.data?.reply || res.data?.error,
          });
          setMessages((old: any) => [
            ...old.filter((element: any) => element.role !== "typing"),
            ...responses,
          ]);
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => setIsSending(false));
    }, 0);
  };
  function fetchInsightsHistory() {
    setIsLoading(true);
    const url =
      insightType.type === "quote"
        ? `conversations/?quote_id=${insightType.id}`
        : insightType.type === "policy"
        ? `/conversations/?policy_id=${insightType.id}`
        : `/conversations/?bid_id=${selectedBid.id}`;
    useFetch
      .get(url)
      .then((res) => {
        if (res.data?.length) {
          const messageList = res.data?.reverse();
          setSessionId(res.data[0]?.session_id);
          setMessages(messageList);
        } else {
          setMessages(
            insightType.type === "bid" &&
              selectedBid?.data?.sentences?.length > 0
              ? policyInsightData
              : []
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
        setMessages(
          insightType.type === "bid" && selectedBid?.data?.sentences?.length > 0
            ? policyInsightData
            : []
        );
      })
      .finally(() => setIsLoading(false));
  }

  useEffect(() => {
    fetchInsightsHistory();
  }, [selectedBid, insightType]);
  useEffect(() => {
    return () => {
      setMessages([]);
      setSessionId("");
    };
  }, [selectedBid]);

  return (
    <Flex
      direction="column"
      w="100%"
      align="center"
      gap="12px"
      height="auto"
      maxH="calc(80dvh - 125px)"
      minH="60vh"
      overflowY="auto"
      className="hide-scrollbar"
    >
      {isLoading ? (
        <Flex direction="column" w="100%" align="end" gap="15px">
          {[1, 2, 3].map(() => (
            <Skeleton w="100%" height="100px" borderRadius="16px" />
          ))}
        </Flex>
      ) : (
        <Flex
          direction="column"
          w="100%"
          height="100%"
          justify={!sessionId ? "start" : "space-between"}
          align="end"
          gap="15px"
          minH="60vh"
        >
          <Flex
            w="100%"
            h="100%"
            flexDirection="column"
            p="1"
            minH={!sessionId ? "fit-content" : "65%"}
            maxH={!sessionId ? "fit-content" : "65%"}
            overflow="auto"
            className="hide-scrollbar"
          >
            <Messages messages={messages} isInsight={true} />
          </Flex>
          {!sessionId ? (
            <Flex direction="column" w="100%" align="end" gap="15px">
              <InsightCard
                themeMode="dark"
                text={`Chat with Sanbot AI to get more insights on your proposals for the ${product} bid by sending in your questions in the chat.`}
                width="75%"
              />
              <Button
                borderRadius="24px"
                colorScheme="white"
                bg="#FFF"
                color="black"
                w="104px"
                height="24px"
                onClick={handleStartInsights}
              >
                Start Chat
              </Button>
            </Flex>
          ) : (
            <Flex alignItems="center" padding="2" w="100%">
              <InputGroup size="md" flexGrow={1} color="white">
                <Input
                  pr="4.5rem"
                  boxShadow="0 0 10px #6f6e6e"
                  placeholder="Type Something..."
                  border="none"
                  borderRadius="5px"
                  //   outline={"none"}
                  color="white"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && inputMessage.trim().length > 0) {
                      handleSendMessage(inputMessage);
                    }
                  }}
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                />
                <InputRightElement width="3.5rem">
                  <Button
                    h="1.75rem"
                    borderRadius="full"
                    marginLeft="10px"
                    size="sm"
                    bg={theme.color.GREEN}
                    colorScheme="green"
                    color="white"
                    isDisabled={inputMessage.trim().length <= 0}
                    onClick={() => {
                      handleSendMessage(inputMessage);
                    }}
                  >
                    <AiOutlineSend />
                  </Button>
                </InputRightElement>
              </InputGroup>
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
};

export default Insights;
