import {
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  useToast,
} from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

import ModelIcon from "../../partials/assets/images/mode-icon.jpg";
import { theme } from "../../partials/utils/Contants";
import {
  BidModes,
  CyberList,
  EmployeeBenefitsList,
  LiabilityList,
  MarineList,
  PropertyList,
  TravelList,
} from "../../partials/utils/BidCreation";
import CreateBidCard from "../../components/CreateBidCard/CreateBidCard";
import { useEffect, useMemo, useState } from "react";
import { useStore } from "../../store/store";
import CreateCompanyFrom from "../../components/CreateCompany/CreateCompanyForm";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import Forms from "../../components/Forms/Forms";
import { useLocation, useNavigate } from "react-router-dom";
import { CiSearch } from "react-icons/ci";
import FormInsights from "../../components/Forms/FormInsights";
export type ModeProps = "Power" | "Guided" | "Consulted" | "";
const CreateBid = () => {
  const { pathname } = useLocation();
  const toast = useToast();
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [mode, setMode] = useState<ModeProps>("");
  const [createdBid, setCreatedBid] = useState("");
  const [product, setProduct] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);

  const {
    step,
    setStep,
    selectedCategories,
    setSelectedCategories,
    handleRemoveCategory,
    selectedCompany,
    userCompanies,
  } = useStore();
  const selectedCompanyData = useMemo(
    () => userCompanies.find((u) => u.id === +selectedCompany),
    [selectedCompany, pathname]
  );

  const [tabIndex, setTabIndex] = useState(step - 3);
  const [isBidConfirmed, setIsBidConfirmed] = useState(false);
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };
  useEffect(() => {
    setTabIndex(step - 3);
  }, [step]);
  // const handleRemoveCategory = (category: string) => {
  //   setSelectedCategories((prevCategories: any) =>
  //     prevCategories.filter((item: string) => item !== category)
  //   );
  // };

  const handleConfirmBid = () => {
    setIsConfirming(true);
    useFetch
      .get(`api/${selectedCompany}/confirm-bid/${createdBid}/`)
      .then((res) => {
        localStorage.removeItem("comp_id");
        setStep(1);
        navigate("/bid-centre");
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: error.response.data?.detail || "Something Went wrong",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsConfirming(false));
  };
  const handleComplete = () => {
    if (mode) {
      setStep(step + 1);
    } else {
      toast({
        title: "Please Select a mode",
        description: "",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  const handleSelectMode = (value: ModeProps) => {
    if (value === "Consulted") {
      toast({
        title: "Comming soon........",
        description: "",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } else {
      setMode(value as ModeProps);
      setStep(step + 1);
    }
  };
  useEffect(() => {
    if (step < 2) {
      localStorage.removeItem("comp_id");
      setMode("");
    }
  }, [step]);
  const allItems = [
    ...EmployeeBenefitsList,
    ...PropertyList,
    ...MarineList,
    ...LiabilityList,
    ...CyberList,
    ...TravelList,
  ];
  const filterItems = (query: string) => {
    if (!query) return allItems;

    return allItems.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase())
    );
  };
  const filteredItems = filterItems(query);

  return (
    <Flex
      direction="column"
      w="100%"
      h="100%"
      overflow="auto"
      ps="0px"
      p={step > 2 ? "0px" : "10px"}
    >
      {step <= 2 && (
        <Flex direction="column" w="100%" padding="40px" gap="20px">
          <Flex align="center" gap="30px" w="100%">
            <Text fontSize="24px" fontWeight="500">
              Select the type of insurance you would like to create a bid for
            </Text>
            {step === 2 && (
              <Tag
                colorScheme="green"
                bg={theme.color.GREEN}
                color="white"
                borderRadius="24px"
                padding="4px 17px"
                cursor="pointer"
                onClick={handleComplete}
              >
                Completed
              </Tag>
            )}
          </Flex>

          {step === 1 && (
            <Flex direction="column" w="100%" gap="20px">
              <InputGroup>
                <InputLeftElement>
                  <CiSearch />
                </InputLeftElement>
                <Input
                  borderRadius="16px"
                  type="text"
                  w="100%"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  placeholder=" Search"
                  fontSize="12px"
                  fontWeight="500"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </InputGroup>
              {!query?.length && (
                <Tabs variant="unstyled" width="100%">
                  <TabList gap="23px">
                    <Tab
                      height="47px"
                      fontSize="15px"
                      fontWeight="500"
                      color={theme.color.GRAY_LABEL}
                      _selected={{
                        color: "black",
                      }}
                    >
                      Employee Benefits
                    </Tab>
                    <Tab
                      height="47px"
                      fontSize="15px"
                      fontWeight="500"
                      color={theme.color.GRAY_LABEL}
                      _selected={{
                        color: "black",
                      }}
                    >
                      Property
                    </Tab>
                    <Tab
                      height="47px"
                      fontSize="15px"
                      fontWeight="500"
                      color={theme.color.GRAY_LABEL}
                      _selected={{
                        color: "black",
                      }}
                    >
                      Marine
                    </Tab>
                    <Tab
                      height="47px"
                      fontSize="15px"
                      fontWeight="500"
                      color={theme.color.GRAY_LABEL}
                      _selected={{
                        color: "black",
                      }}
                    >
                      Liability
                    </Tab>
                    <Tab
                      height="47px"
                      fontSize="15px"
                      fontWeight="500"
                      color={theme.color.GRAY_LABEL}
                      _selected={{
                        color: "black",
                      }}
                    >
                      Cyber
                    </Tab>
                    <Tab
                      height="47px"
                      fontSize="15px"
                      fontWeight="500"
                      color={theme.color.GRAY_LABEL}
                      _selected={{
                        color: "black",
                      }}
                    >
                      Travel
                    </Tab>
                  </TabList>
                  <TabIndicator
                    mt="-1.5px"
                    height="2px"
                    bg="black"
                    borderRadius="1px"
                  />
                  <TabPanels>
                    <TabPanel px="0px">
                      <Grid
                        templateColumns="repeat(auto-fill, 260px)"
                        w="100%"
                        gap="30px"
                        //  maxH="70dvh"
                        // overflowY="auto"
                        // className="hide-scrollbar"
                      >
                        {EmployeeBenefitsList.map((item) => (
                          <GridItem w="100%" h="205px" key={item.id}>
                            <CreateBidCard
                              item={item}
                              selectedCategories={selectedCategories}
                              setSelectedCategories={setSelectedCategories}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel px="0px">
                      <Grid
                        templateColumns="repeat(auto-fill, 260px)"
                        w="100%"
                        gap="30px"
                        // maxH="70dvh"
                        // overflowY="auto"
                        // className="hide-scrollbar"
                      >
                        {PropertyList.map((item) => (
                          <GridItem w="100%" h="205px" key={item.id}>
                            <CreateBidCard
                              key={item.id}
                              item={item}
                              selectedCategories={selectedCategories}
                              setSelectedCategories={setSelectedCategories}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel px="0px">
                      <Grid
                        templateColumns="repeat(auto-fill, 260px)"
                        w="100%"
                        gap="30px"
                        // maxH="70dvh"
                        // overflowY="auto"
                        // className="hide-scrollbar"
                      >
                        {MarineList.map((item) => (
                          <GridItem w="100%" h="205px" key={item.id}>
                            <CreateBidCard
                              item={item}
                              selectedCategories={selectedCategories}
                              setSelectedCategories={setSelectedCategories}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel px="0px">
                      <Grid
                        templateColumns="repeat(auto-fill, 260px)"
                        w="100%"
                        gap="30px"
                        // maxH="70dvh"
                        // overflowY="auto"
                        // className="hide-scrollbar"
                      >
                        {LiabilityList.map((item) => (
                          <GridItem w="100%" h="205px" key={item.id}>
                            <CreateBidCard
                              item={item}
                              selectedCategories={selectedCategories}
                              setSelectedCategories={setSelectedCategories}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel px="0px">
                      <Grid
                        templateColumns="repeat(auto-fill, 260px)"
                        w="100%"
                        gap="30px"
                        // maxH="70dvh"
                        // overflowY="auto"
                        // className="hide-scrollbar"
                      >
                        {CyberList.map((item) => (
                          <GridItem w="100%" h="205px" key={item.id}>
                            <CreateBidCard
                              item={item}
                              selectedCategories={selectedCategories}
                              setSelectedCategories={setSelectedCategories}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    </TabPanel>
                    <TabPanel px="0px">
                      <Grid
                        templateColumns="repeat(auto-fill, 260px)"
                        w="100%"
                        gap="30px"
                        // maxH="70dvh"
                        // overflowY="auto"
                        // className="hide-scrollbar"
                      >
                        {TravelList.map((item) => (
                          <GridItem w="100%" h="205px" key={item.id}>
                            <CreateBidCard
                              item={item}
                              selectedCategories={selectedCategories}
                              setSelectedCategories={setSelectedCategories}
                            />
                          </GridItem>
                        ))}
                      </Grid>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
              )}
              {query?.length && filteredItems?.length ? (
                <Grid
                  templateColumns="repeat(auto-fill, 260px)"
                  w="100%"
                  gap="30px"
                  maxH="75vh"
                  // overflowY="auto"
                  // className="hide-scrollbar"
                >
                  {filteredItems.map((item) => (
                    <GridItem w="100%" h="205px" key={item.id}>
                      <CreateBidCard
                        item={item}
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                      />
                    </GridItem>
                  ))}
                </Grid>
              ) : (
                <>
                  {query?.length && !filteredItems?.length ? (
                    <Flex
                      height="30vh"
                      w="100%"
                      align="center"
                      justify="center"
                    >
                      <Text fontWeight="600" fontSize="16px">
                        No matching record found
                      </Text>
                    </Flex>
                  ) : null}
                </>
              )}
            </Flex>
          )}
          {step === 2 && (
            <Flex direction="column" w="100%" padding="40px" gap="35px">
              <Flex w="100%" gap="15px" flexWrap="wrap">
                {selectedCategories.map((category: string) => (
                  <Tag key={category} borderRadius="full" variant="solid">
                    <TagLabel>{category}</TagLabel>
                    {/* <TagCloseButton
                      onClick={() => handleRemoveCategory(category)}
                    /> */}
                  </Tag>
                ))}
              </Flex>
              <Text fontSize="24px" fontWeight="500" mt="10px">
                How would you like to proceed with creating your bid details and
                data?
              </Text>

              <Flex w="100%" gap="50px" align="center" flexWrap="wrap">
                {BidModes.map((item) => (
                  <Flex
                    direction="column"
                    align="center"
                    height="237px"
                    width="260px"
                    className="create-bid-card-bg"
                    borderRadius="12px"
                    padding="13px 13px 16px 16px"
                    //   gap="15px"
                    onClick={() => handleSelectMode(item.value as ModeProps)}
                    cursor="pointer"
                    _hover={{ scale: 1.2 }}
                  >
                    <Flex w="100%" justify="end" align="center">
                      {mode === item.value ? (
                        <Icon
                          as={FaCheckCircle}
                          fontSize="21px"
                          color={theme.color.GREEN}
                        />
                      ) : (
                        <Flex
                          borderRadius="100px"
                          bg="#D9D9D9"
                          width="21px"
                          height="21px"
                        />
                      )}
                    </Flex>
                    <Flex direction="column" gap="15px" align="center">
                      <Text
                        fontSize="16px"
                        fontWeight="500"
                        color="rgba(46, 46, 46, 1)"
                      >
                        {item.title}
                      </Text>
                      <Image
                        src={item?.icon}
                        height="55px"
                        w="60px"
                        alt="model-icon"
                      />
                      <Text textAlign="center" fontSize="10px" fontWeight="400">
                        {item.desc}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          )}
        </Flex>
      )}
      {step > 2 && (
        <Flex w="100%" height="100%">
          <Flex
            direction="column"
            gap="20px"
            background="#f8f8f8"
            borderRadius="16px"
            padding="30px"
            // w="100%"
            flex={2}
          >
            <Text fontSize="1.2rem" fontWeight="700" color="#25396f">
              New Bid
            </Text>
            <Tabs
              index={tabIndex}
              onChange={handleTabsChange}
              variant="unstyled"
              width="100%"
            >
              <TabList gap="23px">
                <Tab
                  height="47px"
                  fontSize="15px"
                  fontWeight="500"
                  color={theme.color.GRAY_LABEL}
                  _selected={{
                    color: "black",
                  }}
                  isDisabled={step !== 3}
                >
                  Company Details
                </Tab>
                <Tab
                  height="47px"
                  fontSize="15px"
                  fontWeight="500"
                  color={theme.color.GRAY_LABEL}
                  _selected={{
                    color: "black",
                  }}
                  isDisabled={step !== 4}
                >
                  Bid Information
                </Tab>
                <Tab
                  height="47px"
                  fontSize="15px"
                  fontWeight="500"
                  color={theme.color.GRAY_LABEL}
                  _selected={{
                    color: "black",
                  }}
                  isDisabled={step !== 5}
                >
                  Confirmation
                </Tab>
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="black"
                borderRadius="1px"
              />
              <TabPanels>
                <TabPanel>
                  <CreateCompanyFrom
                    isDisable={true}
                    isBidMode={true}
                    editData={selectedCompanyData}
                    goBack={true}
                  />
                </TabPanel>
                <TabPanel>
                  <Forms
                    mode={mode}
                    createdBid={createdBid}
                    setCreatedBid={setCreatedBid}
                    product={product}
                    setProduct={setProduct}
                  />
                </TabPanel>
                <TabPanel>
                  <Flex direction="column" gap="30px" py="10px">
                    <Text>
                      I/We, the undersigned, do hereby unconditionally agree,
                      understand and consent as follows:
                    </Text>
                    <Text>
                      I/We give Sanctuari permission to gather and use the data
                      you or your company has provided, as specified in the
                      privacy policy and the terms of use. I/We declare and
                      acknowledge that I/We have read and understood the terms
                      of the and shall be bound by the same.I/we give permission
                      to the platform to override the DNC in order to share
                      information.
                    </Text>
                    <Checkbox
                      isChecked={isBidConfirmed}
                      onChange={(e) => setIsBidConfirmed(e.target.checked)}
                    >
                      {" "}
                      I agree with the Terms and Conditions.
                    </Checkbox>
                    <Flex
                      w="100%"
                      justify="space-between"
                      align="center"
                      mt="20px"
                    >
                      <Button
                        type="button"
                        borderRadius="21px"
                        onClick={() => setStep(step - 1)}
                      >
                        Previous
                      </Button>
                      <Button
                        onClick={handleConfirmBid}
                        borderRadius="21px"
                        colorScheme="green"
                        isLoading={isConfirming}
                        isDisabled={isConfirming || !isBidConfirmed}
                      >
                        Submit
                      </Button>
                    </Flex>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
          <FormInsights product={product} />
        </Flex>
      )}
    </Flex>
  );
};

export default CreateBid;
