import {
  Flex,
  Icon,
  Image,
  Text,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
  Avatar,
  VStack,
  HStack,
} from "@chakra-ui/react";
import BeatLoader from "../Beatloader";
import { InsightCard } from "../SanbotAI/Insights";
import { useEffect, useRef } from "react";
import moment from "moment";

export const AlwaysScrollToBottom = () => {
  const elementRef: any = useRef();
  useEffect(() => elementRef.current && elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const Messages = ({ messages, user, isInsight = false }: any) => {
  return (
    <>
      {messages?.map((item: any, index: number) => {
        if (
          item.role === "user" ||
          (user && item?.sender_id === user?.user_id)
        ) {
          return (
            <>
              {isInsight ? (
                <VStack key={index} align="flex-end">
                  <InsightCard
                    themeMode="dark"
                    text={item.content}
                    width="fit-content"
                  />
                </VStack>
              ) : (
                <VStack key={index} align="flex-end">
                  <HStack w="full" justify="flex-end">
                    <Text fontWeight={"bold"}>You</Text>
                    <Avatar size="sm" bg="gray" />
                  </HStack>
                  <Flex justify="flex-end" key={index} w={["50", "50", "100%"]}>
                    <Flex
                      direction="column"
                      bg="rgba(41, 41, 41, 1)"
                      color="rgba(255, 255, 255, 1)"
                      minW="100px"
                      maxW="50vw"
                      my="1"
                      p="3"
                      borderRadius={"md"}
                    >
                      {item?.file_url ? (
                        <Flex direction="column" pos="relative" gap="5px">
                          <Flex
                            height="100px"
                            w="100%"
                            position="absolute"
                            cursor="pointer"
                            onClick={() =>
                              window.open(item?.file_url, "_blank")
                            }
                          />
                          <iframe
                            src={item?.file_url}
                            style={{
                              height: "100px",
                              width: "auto",
                              overflow: "hidden",
                            }}
                          />
                          <Text fontWeight={"bold"} align="start">
                            {item?.message}
                          </Text>
                        </Flex>
                      ) : (
                        <Text
                          fontWeight={"bold"}
                          align="start"
                          maxW={["29vh", "50vh"]}
                        >
                          {item.message}
                        </Text>
                      )}
                      <Text
                        w="100%"
                        textAlign="end"
                        fontSize="13px"
                        fontWeight="400"
                        whiteSpace="pre-line"
                      >
                        {item.timestamp === "Just now"
                          ? "Just Now"
                          : moment(item.timestamp).fromNow()}
                      </Text>
                    </Flex>
                  </Flex>
                </VStack>
              )}
            </>
          );
        } else {
          return (
            <>
              {isInsight ? (
                item.role === "typing" ? (
                  <BeatLoader />
                ) : (
                  <InsightCard text={item.content} themeMode="light" />
                )
              ) : (
                <VStack align={"start"}>
                  <HStack w="full">
                    {/* <Avatar size="sm" bg="gray" name={item?.sender} /> */}
                    <Text fontWeight={"bold"} fontSize="11px" color="white">
                      {item?.sender}
                    </Text>
                  </HStack>

                  <Flex key={index} w={["50", "50", "100%"]}>
                    <Flex
                      direction="column"
                      wrap={"wrap"}
                      bg="rgba(227, 228, 232, 1)"
                      w=""
                      color="black"
                      my="1"
                      p="3"
                      borderRadius={"md"}
                    >
                      {item.role === "typing" ? (
                        <BeatLoader />
                      ) : (
                        <>
                          {item?.file_url ? (
                            <Flex direction="column" pos="relative" gap="5px">
                              <Flex
                                height="100px"
                                w="100%"
                                position="absolute"
                                cursor="pointer"
                                onClick={() =>
                                  window.open(item?.file_url, "_blank")
                                }
                              />
                              <iframe
                                src={item?.file_url}
                                style={{
                                  height: "100px",
                                  width: "auto",
                                  overflow: "hidden",
                                }}
                              />
                              <Text fontWeight={"bold"} align="start">
                                {item?.message}
                              </Text>
                            </Flex>
                          ) : (
                            <Text
                              fontWeight={"bold"}
                              align="start"
                              maxW={["29vh", "50vh"]}
                            >
                              {item.message}
                            </Text>
                          )}
                        </>
                      )}
                      <Text
                        w="100%"
                        textAlign="end"
                        fontSize="13px"
                        fontWeight="400"
                        whiteSpace="pre-line"
                      >
                        {item.timestamp === "Just now"
                          ? "Just Now"
                          : moment(item.timestamp).fromNow()}
                      </Text>
                    </Flex>
                  </Flex>
                </VStack>
              )}
            </>
          );
        }
      })}
      <AlwaysScrollToBottom />
    </>
  );
};

export default Messages;
