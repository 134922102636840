import React, { useEffect, useState } from "react";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { useParams } from "react-router-dom";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  Icon,
  Button,
  useToast,
  Badge,
} from "@chakra-ui/react";
import { TbGridDots } from "react-icons/tb";
import { FaCloudDownloadAlt } from "react-icons/fa";
import { BEProductMap } from "../../partials/utils/helper";
import BackButtton from "../../components/BackButton";

const ClaimListing = () => {
  const toast = useToast();
  const { product } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const fetchData = useFetch;
  function fetchListingData() {
    if (!product) return;
    const parsedProduct = BEProductMap[product] || product;
    const encodedProductName = encodeURIComponent(parsedProduct);

    fetchData(`api/claim-form/?name=${encodedProductName}`)
      .then((response) => {
        setData(response?.data?.claim_forms);
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: error?.response?.data?.error,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    fetchListingData();
  }, []);

  return (
    <Flex
      w="100%"
      direction="column"
      gap="40px"
      bg="ghostwhite"
      padding="20px"
      borderRadius="21px"
    >
      <Flex align="center" gap="12px">
        <BackButtton />
        <Text fontSize="1.2rem" fontWeight="700" color="#25396f" ps="5px">
          Claim Forms
        </Text>
      </Flex>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>
                <TbGridDots />
              </Th>

              <Th>insurer</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item: any, index: number) => (
              <Tr key={index}>
                <Td>
                  <TbGridDots />
                </Td>

                <Td>{item?.["Insurance Company"]}</Td>
                <Td>
                  <Button
                    bg="green"
                    padding="5px"
                    borderRadius="10px"
                    colorScheme="green"
                    as="a"
                    href={item?.["Claim Form URL"]}
                    target="_blank"
                  >
                    <Icon
                      as={FaCloudDownloadAlt}
                      fontSize="20px"
                      color="white"
                    />
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Flex>
  );
};

export default ClaimListing;
