import Cookies from "js-cookie";
import { handleLogoutLocalStorage } from "../partials/utils/helper";
import useFetch from "../partials/utils/Interceptor/Interceptor";
const useLogout = () => {
  const logoutHandler = () => {
    handleLogoutLocalStorage();
    useFetch
      .get("logout")
      .then((response) => {
        handleLogoutLocalStorage();
        Cookies.remove("jwt");
        Cookies.remove("user");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return { logoutHandler };
};

export default useLogout;
