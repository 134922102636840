import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../../store/store";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import {
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Tooltip,
} from "@chakra-ui/react";
import { CiFilter } from "react-icons/ci";
import { FcCancel } from "react-icons/fc";

import { ReactComponent as CirclerCheck } from "../../partials/assets/icons/circle-check.svg";
import { productMapping } from "../../partials/utils/Contants";
import { number } from "yup";
import { addCommas } from "../../partials/utils/helper";
// import jsPDF from "jspdf";
type QuoteComparisonProps = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

const QuoteComparison = ({ isOpen, onOpen, onClose }: QuoteComparisonProps) => {
  const { selectedQuotesList, quoteComparisonBidId } = useStore();
  const [bidDetail, setBidDetail] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [quoteList, setQuoteList] = useState([]);
  const [scale, setScale] = useState(1);

  const zoomIn = () => setScale((prevScale) => prevScale * 1.1);
  const zoomOut = () => setScale((prevScale) => prevScale / 1.1);

  function fetchQotesData() {
    setIsLoading(true);
    const queryParams = selectedQuotesList
      ?.map((q: number, index: number) => `quote${index + 1}_id=${q}`)
      .join("&");
    useFetch
      .post(`api/quote-compare/?bid_id=${quoteComparisonBidId}&${queryParams}`)
      .then((res) => {
        setBidDetail(res.data?.bid_details);
        setQuoteList(res.data?.quotes_to_compare);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  const contentRef = useRef<HTMLDivElement>(null);

  // const downloadPDF = () => {
  //   const doc = new jsPDF({
  //     orientation: "portrait",
  //     unit: "mm",
  //     format: "a4",
  //   });

  //   if (contentRef.current) {
  //     doc.html(contentRef.current, {
  //       callback: function (doc) {
  //         // Save the PDF
  //         doc.save("downloaded_component.pdf");
  //       },
  //       x: 5, // Horizontal margin
  //       y: 4, // Vertical margin
  //       html2canvas: {
  //         scale: 0.2, // Zoom out the content to fit in the page
  //         logging: true, // Enable logging for debugging
  //         useCORS: true, // Handle CORS issues for images
  //       },
  //       width: 180, // Use 180mm width (210mm total width minus margins)
  //       windowWidth: 800, // Set the window width for rendering
  //     });
  //   }
  // };

  useEffect(() => {
    if (isOpen) {
      fetchQotesData();
    }
  }, [selectedQuotesList, isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="full">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          {isLoading ? (
            <Flex minH="20vh" align="center" justify="center">
              <Spinner size="xl" />
            </Flex>
          ) : (
            <Flex
              direction="column"
              w="100%"
              gap="14px"
              ref={contentRef}
              overflow="auto"
              padding="12px"
            >
              {/* <Button
            colorScheme="teal"
            onClick={downloadPDF}
            mt={4}
            w="fit-content"
          >
            Download as PDF
          </Button> */}
              <Flex align="center" gap="10px">
                <Text fontSize="18px" fontWeight="bold">
                  {bidDetail?.["Product Name"]
                    ? productMapping[bidDetail?.["Product Name"]]
                    : ""}
                </Text>
              </Flex>
              <Flex direction="column" w="100%" gap="10px">
                <Text fontWeight="bold" color="rgba(8, 8, 8, 0.6)">
                  Policy Details
                </Text>
                <SimpleGrid columns={3} spacing="10px">
                  {bidDetail &&
                    Object.entries(bidDetail)?.map(([key, value]: any) => {
                      if (key == "Product Name") return;
                      return (
                        <Flex align="center" gap="8px">
                          <Text
                            textTransform="capitalize"
                            fontWeight="bold"
                            color="gray.400"
                            whiteSpace="nowrap"
                            fontSize="13px"
                          >
                            {" "}
                            {key?.replace(/_/g, " ")}:
                          </Text>
                          <Text fontSize="13px">{value}</Text>
                        </Flex>
                      );
                    })}
                </SimpleGrid>
              </Flex>

              <Flex justify="end" height="20px" />
              <Flex
                w="auto"
                justify="end"
                gap="10px"
                position="fixed"
                right="25px"
                top="15%"
                zIndex="50"
              >
                <Button onClick={zoomOut} borderRadius="16px">
                  -
                </Button>
                <Button
                  onClick={zoomIn}
                  borderRadius="16px"
                  bg="black"
                  colorScheme="black"
                  color="white"
                  _hover={{
                    opacity: 0.7,
                  }}
                >
                  +
                </Button>
              </Flex>
              <Flex
                w="100%"
                height="100%"
                style={{
                  transform: `scale(${scale})`,
                  transformOrigin: "top left",
                }}
              >
                {quoteList?.length &&
                  quoteList.map((q: any, index: number) => (
                    <Flex direction="column" w="fit-content" key={index}>
                      <Flex
                        w="100%"
                        height="115px"
                        bg="black"
                        borderRadius={
                          index === 0
                            ? "24px 0px 0px 24px"
                            : index === quoteList.length - 1
                            ? "0px 24px 24px 0px"
                            : "0px"
                        }
                        // padding="12px"
                        align="center"
                      >
                        {index === 0 && (
                          <Flex
                            align="center"
                            gap="10px"
                            w="300px"
                            maxW="300px"
                            ps="12px"
                          >
                            <Text
                              color="white"
                              fontWeight="600"
                              fontSize="16px"
                            >
                              Requirements
                            </Text>
                            <CiFilter color="white" />
                          </Flex>
                        )}

                        <Flex
                          direction="column"
                          key="insurance_company_name"
                          w="300px"
                          maxW="300px"
                        >
                          <Text
                            color="white"
                            fontWeight="600"
                            fontSize="16px"
                            textAlign="center"
                            w="300px"
                            maxW="300px"
                          >
                            {q?.insurance_company_name}
                          </Text>
                        </Flex>
                      </Flex>
                      {Object.entries(q).map(([key, value]: any) => {
                        if (key === "insurance_company_name") return null;
                        return (
                          <Flex key={key} w="100%" align="center" h="100px">
                            {index === 0 && (
                              <Text
                                textTransform="capitalize"
                                fontWeight="500"
                                w="300px"
                                maxW="300px"
                                // py="4px"
                                // overflow="auto"
                              >
                                {key.replace(/_/g, " ")}{" "}
                              </Text>
                            )}

                            <Flex
                              align="center"
                              w="300px"
                              maxW="300px"
                              bg={index % 2 === 0 ? "orange.50" : "green.50"}
                              justify="center"
                              height="100%"
                              py="4px"
                            >
                              {value === true ? (
                                <Icon as={CirclerCheck} fontSize="22px" />
                              ) : value === false ? (
                                <Icon as={FcCancel} fontSize="22px" />
                              ) : (
                                <Tooltip
                                  hasArrow
                                  label={value}
                                  bg="gray.300"
                                  color="black"
                                >
                                  <Text px="5px" whiteSpace="break-spaces">
                                    {typeof value === "number"
                                      ? addCommas(value)
                                      : value?.length > 100
                                      ? `${value.slice(0, 100)}...`
                                      : value}
                                  </Text>
                                </Tooltip>
                              )}
                            </Flex>
                          </Flex>
                        );
                      })}
                    </Flex>
                  ))}
              </Flex>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QuoteComparison;
