import toast from "react-hot-toast";
import useFetch from "../Interceptor/Interceptor";

export const downloadUsingURL = async (url: string) => {
  try {
    const link = document.createElement("a");
    link.href = url;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // toast({
    //   title: "Downloaded Successfully",
    //   description: "",
    //   status: "success",
    //   duration: 3000,
    //   isClosable: true,
    // });
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};
export const downloadRFQ = async (
  selectedCompany: string | number,
  id: string
) => {
  const toastId = toast.loading("Downloading...");

  try {
    const response = await useFetch.get(
      `api/${selectedCompany}/documents-download/?doc_id=${id}`
    );
    const presignedUrl = response.data?.presigned_url;
    if (presignedUrl) {
      const link = document.createElement("a");
      link.href = presignedUrl;
      link.download = "";
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      toast.success("Downloaded Successfully", { id: toastId });
    }
  } catch (error: any) {
    console.error("Error downloading file:", error);
    toast.error(error.response.data?.error || "Something Went wrong", {
      id: toastId,
    });
  }
};
