import { Navigate, Outlet, useLocation } from "react-router-dom";
import { LinkItemsBidder } from "../../../routes";
import Sidebar from "../../../components/Sidebar";
import { useFetchtoken, useFetchUser } from "../../../hooks/User";
import { useEffect, useState } from "react";
import LoadingScreen from "../../../pages/LoadingScreen";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import Cookies from "js-cookie";

const PrivateRoutes = () => {
  const { pathname } = useLocation();
  const token = useFetchtoken();
  const user = useFetchUser();
  const [isProfileComplete, setIsProfileComplete] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    if (token) {
      const bidder_profiling: any = localStorage.getItem("bidder_profiling");
      if (bidder_profiling && bidder_profiling !== null) {
        const complete =
          JSON.parse(bidder_profiling) === "False" ||
          JSON.parse(bidder_profiling) === false
            ? false
            : true;
        setIsProfileComplete(complete);
      }
    } else {
      setIsProfileComplete(true);
    }
  }, [token, pathname]);
  function fetchUerData() {
    useFetch
      .get(`api/bidder-details/`)
      .then((res) => {
        Cookies.set("user", JSON.stringify({ ...user, ...res.data?.bidder }));
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  useEffect(() => {
    if (!user?.company_name) {
      fetchUerData();
    }
  }, [user]);

  if (token === null) {
    return <Navigate to="/login" />;
  }

  if (isProfileComplete === null) {
    return <LoadingScreen />;
  }

  return token ? (
    isProfileComplete ? (
      <Sidebar sidebaroptions={LinkItemsBidder}>
        <Outlet />
      </Sidebar>
    ) : (
      <Navigate to="/setupProfile" />
    )
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoutes;
