import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Flex,
  Text,
  useDisclosure,
  Modal,
  ModalHeader,
  ModalOverlay,
  FormControl,
  FormLabel,
  ModalBody,
  Input,
  Select,
  FormErrorMessage,
  Button,
  useToast,
  Icon,
  ModalContent,
  ModalCloseButton,
  Spinner,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { RiEditCircleLine } from "react-icons/ri";
import { FaRegTrashCan } from "react-icons/fa6";
import { TbUserBolt } from "react-icons/tb";
import moment from "moment";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { useStore } from "../../../store/store";
import { CiSearch } from "react-icons/ci";

type FormValues = {
  name: string;
  email: string;
  mobile: string;
  location: string;
  country: string;
  bidder_role_id: string;
};
const BidderTeamMembers = () => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>();
  const toast = useToast();
  const { countries } = useStore();
  const [selectedItem, setSelectedItem] = useState<any>();
  const [searchText, setSearchText] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onClose: onDeleteClose,
    onOpen: onDeleteOpen,
  } = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [rolesList, setRolesList] = useState<any>([]);
  const [teamMembersList, setTeamMembersList] = useState([]);
  function fetchRolesList() {
    useFetch
      .get(`api/add-bidder-role/`)
      .then((res) => {
        setRolesList(res?.data);
      })
      .catch((error) => console.log(error));
  }
  function fetchTeamMembers() {
    setIsLoading(true);
    useFetch
      .get(`api/add-bidder-member/`)
      .then((res) => {
        setIsLoading(false);
        setTeamMembersList(res?.data);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }
  const onSubmit = (data: any) => {
    setIsSubmitting(true);
    let body = { ...data };
    console.log("body", body);

    if (selectedItem?.id) {
      body = {
        ...body,
        bidder_member_id: selectedItem?.id,
        role: data.bidder_role_id,
      };
      useFetch
        .patch(`api/add-bidder-member/`, body)
        .then((res) => {
          setIsSubmitting(false);
          toast({
            title: `Updated successfully`,
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          onClose();
          setSelectedItem(null);
          fetchTeamMembers();
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log(error);
          toast({
            title: `Something went wrong`,
            description: "",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    } else {
      useFetch
        .post(`api/add-bidder-member/`, body)
        .then((res) => {
          setIsSubmitting(false);
          toast({
            title: `Your invite to ${data?.email} has been seen sent successfully`,
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          onClose();
          fetchTeamMembers();
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log(error);
          toast({
            title: `Something went wrong`,
            description: "",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    }
  };

  const handleDelete = () => {
    setIsSubmitting(true);
    useFetch
      .delete(`api/add-bidder-member/?bidder_member_id=${selectedItem?.id}`)
      .then((res) => {
        setIsSubmitting(false);

        toast({
          title: `Deleted Successfully.`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onDeleteClose();
        fetchTeamMembers();
        setSelectedItem(null);
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log(error);
        toast({
          title: `Something went wrong`,
          description: "",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      });
  };
  const filteredData = useMemo(
    () =>
      (searchText === ""
        ? [...teamMembersList]
        : teamMembersList.filter((team: any) =>
            team?.name?.toLowerCase().includes(searchText.toLowerCase())
          )
      ).sort((a: any, b: any) =>
        a?.name?.toLowerCase().localeCompare(b?.name?.toLowerCase())
      ),
    [teamMembersList, searchText]
  );
  useEffect(() => {
    fetchRolesList();
    fetchTeamMembers();
  }, []);
  useEffect(() => {
    if (selectedItem?.id) {
      setValue("name", selectedItem?.name);
      setValue("email", selectedItem?.email);
      setValue("country", selectedItem?.country);
      setValue("location", selectedItem?.location);
      setValue("mobile", selectedItem?.mobile);
      setValue("bidder_role_id", selectedItem?.role);
    }
  }, [selectedItem]);

  return (
    <>
      {!isLoading ? (
        <Flex
          w="100%"
          direction="column"
          gap="40px"
          padding="20px"
          borderRadius="21px"
        >
          <Flex w="100%" justify="space-between" align="center">
            <Text fontSize="1.2rem" fontWeight="700" color="#25396f" ps="5px">
              Team Management
            </Text>
            <Button
              rightIcon={<TbUserBolt />}
              borderRadius="24px"
              bg="black"
              colorScheme="black"
              color="white"
              padding="0px 11px"
              fontSize="12px"
              fontWeight="500"
              w="142px"
              h="24px"
              onClick={onOpen}
            >
              Add new member
            </Button>
          </Flex>
          <InputGroup>
            <InputLeftElement>
              <CiSearch />
            </InputLeftElement>
            <Input
              borderRadius="16px"
              type="text"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              placeholder="Search"
              fontSize="12px"
              fontWeight="500"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
          <TableContainer borderRadius="21px" bg="gray.50">
            <Table variant="striped" colorScheme="gray">
              <Thead bg="black" color="white">
                <Tr>
                  <Th color="white">Name</Th>
                  <Th color="white">Email</Th>
                  <Th color="white">Mobile</Th>
                  <Th color="white">Location</Th>
                  <Th color="white">Country</Th>
                  <Th color="white">Role</Th>
                  <Th color="white">Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {filteredData?.map((item: any) => {
                  const role = rolesList?.find(
                    (r: any) => r.id === item?.role
                  )?.name;
                  return (
                    <Tr key={item?.id}>
                      <Td>{item?.name}</Td>
                      <Td>{item?.email}</Td>
                      <Td>{item?.mobile}</Td>
                      <Td>{item?.location}</Td>
                      <Td>{item?.country}</Td>
                      <Td>{role}</Td>
                      <Td>
                        <Flex align="center" gap="10px">
                          <Icon
                            as={RiEditCircleLine}
                            fontSize="18px"
                            cursor="pointer"
                            onClick={() => {
                              setSelectedItem(item);
                              onOpen();
                            }}
                          />
                          <Icon
                            as={FaRegTrashCan}
                            fontSize="18px"
                            cursor="pointer"
                            onClick={() => {
                              setSelectedItem(item);
                              onDeleteOpen();
                            }}
                          />
                        </Flex>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Flex>
      ) : (
        <Flex w="100%" align="end" justify="center" h="30vh">
          {isLoading && !teamMembersList?.length ? (
            <Spinner size="lg" />
          ) : (
            <Text>
              {!rolesList?.length
                ? "You need to create Roles before you can manage your team"
                : "No Team Members found"}
            </Text>
          )}
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalHeader>
            {selectedItem?.id ? "Edit" : "Add"} Team Member
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody py="20px">
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" gap="12px" align="center">
                <Flex
                  w="100%"
                  gap="10px"
                  align="center"
                  justify="space-between"
                >
                  <FormControl isInvalid={Boolean(errors.name)}>
                    <FormLabel htmlFor="name">Contact Person*</FormLabel>
                    <Input
                      id="name"
                      type="name"
                      placeholder="Enter Contact Person name"
                      {...register("name", { required: "Name is required" })}
                      borderRadius="21px"
                    />
                    {errors.name && (
                      <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.email)}>
                    <FormLabel htmlFor="email">Email*</FormLabel>
                    <Input
                      type="email"
                      id="email"
                      placeholder="Enter email"
                      borderRadius="21px"
                      {...register("email", {
                        required: "Email is required",
                      })}
                    />
                    {errors.email && (
                      <FormErrorMessage>
                        {errors.email.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Flex
                  w="100%"
                  gap="10px"
                  align="center"
                  justify="space-between"
                >
                  <FormControl isInvalid={Boolean(errors.mobile)}>
                    <FormLabel htmlFor="mobile">Mobile*</FormLabel>
                    <Input
                      id="mobile"
                      type="name"
                      placeholder="Enter Contact Person name"
                      {...register("mobile", {
                        required: "Mobile no. is required",
                      })}
                      borderRadius="21px"
                    />
                    {errors.mobile && (
                      <FormErrorMessage>
                        {errors.mobile.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.bidder_role_id)}>
                    <FormLabel htmlFor="role">Role*</FormLabel>
                    <Select
                      id="role"
                      borderRadius="21px"
                      placeholder="Select Role"
                      {...register("bidder_role_id", {
                        required: "Role is required",
                      })}
                    >
                      {rolesList?.map((item: any) => (
                        <option key={item?.id} value={item?.id}>
                          {item?.name}
                        </option>
                      ))}
                    </Select>
                    {errors.bidder_role_id && (
                      <FormErrorMessage>
                        {errors.bidder_role_id.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Flex
                  w="100%"
                  gap="10px"
                  align="center"
                  justify="space-between"
                >
                  <FormControl isInvalid={Boolean(errors.country)}>
                    <FormLabel htmlFor="country">Country*</FormLabel>
                    <Select
                      id="country"
                      borderRadius="21px"
                      placeholder="Select country"
                      {...register("country", {
                        required: "country is required",
                      })}
                    >
                      {countries?.map((item: any) => (
                        <option key={item?.name} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                    </Select>

                    {errors.country && (
                      <FormErrorMessage>
                        {errors.country.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                  <FormControl isInvalid={Boolean(errors.location)}>
                    <FormLabel htmlFor="location">Location*</FormLabel>
                    <Input
                      id="location"
                      type="name"
                      placeholder="Enter Location"
                      {...register("location", {
                        required: "location is required",
                      })}
                      borderRadius="21px"
                    />
                    {errors.location && (
                      <FormErrorMessage>
                        {errors.location.message}
                      </FormErrorMessage>
                    )}
                  </FormControl>
                </Flex>
                <Button
                  type="submit"
                  w="fit-content"
                  borderRadius="21px"
                  colorScheme="green"
                  mt="12px"
                  isDisabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              </Flex>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isDeleteOpen} onClose={onDeleteClose}>
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalCloseButton />
          <ModalBody pt="50px">
            <Flex direction="column" align="center">
              <Text fontWeight="600">
                Are you sure you want to Delete this Team member
              </Text>
              {selectedItem?.name && (
                <Text>
                  <b>Name :</b> {selectedItem?.name}
                </Text>
              )}
              {selectedItem?.email && (
                <Text>
                  <b>Email :</b> {selectedItem?.email}
                </Text>
              )}
            </Flex>
            <Flex align="center" gap="20px" w="full" justify="center" mt="20px">
              <Button borderRadius="21px" onClick={onDeleteClose}>
                Cancel
              </Button>
              <Button
                borderRadius="21px"
                colorScheme="red"
                onClick={handleDelete}
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Delete
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BidderTeamMembers;
