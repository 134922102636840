import { Icon } from "@chakra-ui/react";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

const BackButtton = () => {
  const navigate = useNavigate();
  return (
    <Icon
      as={IoArrowBackCircleOutline}
      onClick={() => navigate(-1)}
      fontSize="28px"
      cursor="pointer"
    />
  );
};

export default BackButtton;
