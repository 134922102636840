import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  FormControl,
  Input,
  Select,
  FormErrorMessage,
  Box,
  SimpleGrid,
  HStack,
  Button,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { useStore } from "../../../store/store";
import "../form.css";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";

type FormData = {
  Type_of_Liablity: string;
  Coverage_type: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Location_Type: string;
  Description_of_Operation: string;
  Territory: string;
  Surrounding_areas: string;
  Nature_of_Work1: string;
  Address1: string;
  Surrounding_areas2: string;
  Nature_of_Work2: string;
  Address2: string;
  Type_of_construction: string;
  Age_of_Building: number;
  Address3: string;
  Building_Heightin_meter: string;
  Description_of_elevators: string;
  occupants_and_activities: string;
  Description_of_the_Product: string;
  Business_Turn_Over: number;
  Export: number;
  Destination: string;
  Retroactive_Date: string;
  AOA: number;
  AOY: number;
  Annual_Projected: number;
  Annual_Currently: number;
  Annual_last_year: number;
  Annual_Canada_Projected: number;
  Annual_Canada_Currently: number;
  Annual_USA_Canada_last_year: number;
  Annual_Rest_of_the_world_Projected: number;
  Annual_Rest_of_the_world_Currently: number;
  Annual_Rest_of_the_world_last_year: number;
  Number_of_employees_in_India: number;
  Number_of_employees_Usa_Canada: number;
  Number_of_employees_Rest_world: number;
  Number_of_premises_In_India: number;
  Number_of_premises_USA_Canada: number;
  Number_of_premises_rest_world: number;
  Vendors_Liability_Extension: number;
  Technical_collaborators_liability: number;
  Accidental_Pollution_Extension: number;
  Liability_arisingout_of_Transportation: number;
  arising_Transportation: number;
  Act_of_God_Extension: number;
  Terrorism_Extension: number;
  Goods_kept_in_Custody: number;
  Food_and_Beverages_Extension: number;
  Swimming_pools_Extension: number;
  Sports_facilities_extension: number;
  Personal_Advertising_Injury: number;
  Defense_Costs: number;
  legal_liability_renovation: number;
  Lifts_Elevators_Escalators: number;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer: string;
  previous_policy_expiry: string;
};

const CommercialLiability = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const toast = useToast();
  const { step, setStep,setHoveredLabel,resetHoveredLabel } = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      Age_of_Building: 0,
      Business_Turn_Over: 0,
      Export: 0,
      AOA: 0,
      AOY: 0,
      Annual_Projected: 0,
      Annual_Currently: 0,
      Annual_last_year: 0,
      Annual_Canada_Projected: 0,
      Annual_Canada_Currently: 0,
      Annual_USA_Canada_last_year: 0,
      Annual_Rest_of_the_world_Projected: 0,
      Annual_Rest_of_the_world_Currently: 0,
      Annual_Rest_of_the_world_last_year: 0,
      Number_of_employees_in_India: 0,
      Number_of_employees_Usa_Canada: 0,
      Number_of_employees_Rest_world: 0,
      Number_of_premises_In_India: 0,
      Number_of_premises_USA_Canada: 0,
      Number_of_premises_rest_world: 0,
      Vendors_Liability_Extension: 0,
      Technical_collaborators_liability: 0,
      Accidental_Pollution_Extension: 0,
      Liability_arisingout_of_Transportation: 0,
      arising_Transportation: 0,
      Act_of_God_Extension: 0,
      Terrorism_Extension: 0,
      Goods_kept_in_Custody: 0,
      Food_and_Beverages_Extension: 0,
      Swimming_pools_Extension: 0,
      Sports_facilities_extension: 0,
      Personal_Advertising_Injury: 0,
      Defense_Costs: 0,
      legal_liability_renovation: 0,
      Lifts_Elevators_Escalators: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      expected_premium: 0,
      sum_insured: 0,
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormData, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const AOA = +watch("AOA");
  const AOY = +watch("AOY");

  useEffect(() => {
    const sum = AOA + AOY;
    setValue("sum_insured", sum);
  }, [AOA, AOY]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <SimpleGrid columns={2} spacing={4}>
        <FormControl isInvalid={Boolean(errors?.Type_of_Liablity)}>
          <HoverableFormLabel>Type of Liability*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select Liability Type"
            {...register("Type_of_Liablity", { required: true })}
            onFocus={() =>
              setHoveredLabel("Type of Liability")
            }
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Public Liablity">Public Liability</option>
            <option value="Product liablity">Product Liability</option>
          </Select>
          <FormErrorMessage>
            {errors.Type_of_Liablity && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Coverage_type)}>
          <Flex align="start">
            <HoverableFormLabel> Coverage Type*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title={
                  watch("Coverage_type") === "Occurrence"
                    ? "Occurrence policies cover incidents that happen during the policy period, regardless of when a claim is made. The trigger for coverage in Occurrence policies is the occurrence of an event, not the timing of the claim report"
                    : "In a Claims-Made policy, coverage is triggered when a claim is made against the insured during the policy period, regardless of when the incident occurred"
                }
              />
            )}
          </Flex>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select Coverage Type"
            {...register("Coverage_type", { required: true })}
          >
            <option value="Occurrence">Occurrence</option>
            <option value="Claims Made">Claims Made</option>
          </Select>
          <FormErrorMessage>
            {errors.Coverage_type && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.policytype)}>
          <HoverableFormLabel>Policy Type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Select policy type"
            {...register("policytype", { required: true })}
          >
            <option value="New">New</option>
            <option value="Renewal">Renewal</option>
          </Select>
          <FormErrorMessage>
            {errors.policytype && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.insurer)}>
          <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Preference"
            {...register("insurer", { required: true })}
          >
            <option value="Both">Both</option>
            <option value="Brokers">Brokers</option>
            <option value="INS_COMP">Insurance Company</option>
          </Select>
          <FormErrorMessage>
            {errors.insurer && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.comp_location)}>
          <HoverableFormLabel>
            Where is your company located?*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("comp_location", { required: true })}
          />
          <FormErrorMessage>
            {errors.comp_location && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Location_Type)}>
          <HoverableFormLabel>Location Type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Location Type"
            {...register("Location_Type", { required: true })}
          >
            <option value="Single">Single</option>
            <option value="Multiple">Multiple</option>
          </Select>
          <FormErrorMessage>
            {errors.Location_Type && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Description_of_Operation)}>
          <HoverableFormLabel>Description of Operation*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Description_of_Operation", { required: true })}
          />
          <FormErrorMessage>
            {errors.Description_of_Operation && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Territory)}>
          <HoverableFormLabel>Territory*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            placeholder="Territory"
            {...register("Territory", { required: true })}
          >
            <option value="India Only">India Only</option>
            <option value="Worldwide excluding US Canada">
              Worldwide excluding US Canada
            </option>
            <option value="Worldwide">Worldwide</option>
          </Select>
          <FormErrorMessage>
            {errors.Territory && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <HoverableFormLabel color="darkorchid" fontWeight="600">
            Manufacturing Units
          </HoverableFormLabel>
        </Box>

        <FormControl>
          <HoverableFormLabel>
            Surrounding areas – Industrial/ Agricultural/ Residential
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Surrounding_areas")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Nature of Work</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Nature_of_Work1")}
          />
        </FormControl>

        <FormControl gridColumn="span 2">
          <HoverableFormLabel>Address</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Address1")}
          />
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <HoverableFormLabel color="darkorchid">
            Warehouses/Godowns/Shops/Depots/ Tank Farms/Offices
          </HoverableFormLabel>
        </Box>

        <FormControl>
          <HoverableFormLabel>
            Surrounding areas – Industrial/ Agricultural/ Residential
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Surrounding_areas2")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Nature of Work</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Nature_of_Work2")}
          />
        </FormControl>

        <FormControl gridColumn="span 2">
          <HoverableFormLabel>Address</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Address2")}
          />
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <HoverableFormLabel color="darkorchid">
            Non-manufacturing premises for business
          </HoverableFormLabel>
        </Box>

        <FormControl>
          <HoverableFormLabel>
          Type of Construction (RCC, Temporary, Sheds)
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Type_of_construction")}
            onFocus={() =>
              setHoveredLabel("Type of Construction (RCC, Temporary, Sheds)")
            }
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Age of Building</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Age_of_Building")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Address</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Address3")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
          Building Height in Meter/Floor
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Building_Heightin_meter")}
            onFocus={() =>
              setHoveredLabel("Building Height in Meter/Floor")
            }
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Description of elevators/escalators with make & capacity
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Description_of_elevators")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Details of other occupants and activities in the building
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("occupants_and_activities")}
          />
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <HoverableFormLabel color="darkorchid" fontWeight="600">
            Product Information
          </HoverableFormLabel>
        </Box>

        <FormControl>
          <HoverableFormLabel>Description of the Product</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Description_of_the_Product")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Business Turnover</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Business_Turn_Over")}
            onFocus={() =>
              setHoveredLabel("Business Turnover")
            }
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Export</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Export")}
            onFocus={() =>
              setHoveredLabel("Export")
            }
            onBlur={() => resetHoveredLabel()}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Destination</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="text"
            {...register("Destination")}
          />
        </FormControl>
        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <HoverableFormLabel color="darkorchid" fontWeight="600">
            Special Conditions
          </HoverableFormLabel>
        </Box>
        <FormControl isInvalid={Boolean(errors.Retroactive_Date)}>
          <HoverableFormLabel>Retroactive Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            {...register("Retroactive_Date", { required: true })}
          />
          <FormErrorMessage>
            {errors.Retroactive_Date && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.AOA)}>
          <Flex align="start">
            <HoverableFormLabel>AOA (Any One Accident)*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Maximum payment amount for a single policy-covered an accident or incident"
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("AOA", { required: true })}
          />
          <FormErrorMessage>
            {errors.AOA && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.AOY)}>
          <Flex align="start">
            <HoverableFormLabel>AOY (Any One Year)*</HoverableFormLabel>

            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Maximum payout amount that the insurance provider will make for claims filed in a calendar year"
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("AOY")}
            onFocus={() =>
              setHoveredLabel("AOY (Any One Year)")
            }
            onBlur={() => resetHoveredLabel()}
          />
          <FormErrorMessage>
            {errors.AOY && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Projected)}>
          <HoverableFormLabel>
            Annual turnover In India (Projected)*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Projected")}
          />
          <FormErrorMessage>
            {errors.Annual_Projected && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Currently)}>
          <HoverableFormLabel>
            Annual turnover In India (Currently)*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Currently")}
          />
          <FormErrorMessage>
            {errors.Annual_Currently && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_last_year)}>
          <HoverableFormLabel>
            Annual turnover In India (last year) *
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_last_year")}
          />
          <FormErrorMessage>
            {errors.Annual_last_year && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Canada_Projected)}>
          <HoverableFormLabel>
            Annual turnover In USA Canada (Projected)*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Canada_Projected")}
          />
          <FormErrorMessage>
            {errors.Annual_Canada_Projected && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_Canada_Currently)}>
          <HoverableFormLabel>
            Annual turnover In USA Canada (Currently)*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Canada_Currently")}
          />
          <FormErrorMessage>
            {errors.Annual_Canada_Currently && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Annual_USA_Canada_last_year)}>
          <HoverableFormLabel>
            Annual turnover In USA Canada (last year) *
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_USA_Canada_last_year")}
          />
          <FormErrorMessage>
            {errors.Annual_USA_Canada_last_year && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.Annual_Rest_of_the_world_Projected)}
        >
          <HoverableFormLabel>
            Annual turnover In Rest of the world (Projected)*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Rest_of_the_world_Projected")}
          />
          <FormErrorMessage>
            {errors.Annual_Rest_of_the_world_Projected &&
              "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.Annual_Rest_of_the_world_Projected)}
        >
          <HoverableFormLabel>
            Annual turnover In Rest of the world (Currently)*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Rest_of_the_world_Currently")}
          />
          <FormErrorMessage>
            {errors.Annual_Rest_of_the_world_Projected &&
              "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors.Annual_Rest_of_the_world_last_year)}
        >
          <HoverableFormLabel>
            Annual turnover In Rest of the world (last year) *
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Annual_Rest_of_the_world_last_year")}
          />
          <FormErrorMessage>
            {errors.Annual_Rest_of_the_world_last_year &&
              "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_employees_in_India)}>
          <HoverableFormLabel>Number of employees in India*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_employees_in_India")}
          />
          <FormErrorMessage>
            {errors.Number_of_employees_in_India && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_employees_Usa_Canada)}>
          <HoverableFormLabel>
            Number of employees in Usa & Canada*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_employees_Usa_Canada")}
          />
          <FormErrorMessage>
            {errors.Number_of_employees_Usa_Canada && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_employees_Rest_world)}>
          <HoverableFormLabel>
            Number of employees in Rest of the world*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_employees_Rest_world")}
          />
          <FormErrorMessage>
            {errors.Number_of_employees_Rest_world && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_premises_In_India)}>
          <HoverableFormLabel>Number of premises In India*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_premises_In_India")}
          />
          <FormErrorMessage>
            {errors.Number_of_premises_In_India && "This field is required"}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.Number_of_premises_USA_Canada)}>
          <HoverableFormLabel>
            Number of premises in USA & Canada*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_premises_USA_Canada")}
          />
          <FormErrorMessage>
            {errors.Number_of_premises_USA_Canada && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={Boolean(errors.Number_of_premises_rest_world)}>
          <HoverableFormLabel>
            Number of premises in rest of the world*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Number_of_premises_rest_world")}
          />
          <FormErrorMessage>
            {errors.Number_of_premises_rest_world && "This field is required"}
          </FormErrorMessage>
        </FormControl>

        <Box borderTop="1px solid" mt={2} gridColumn="span 2">
          <HoverableFormLabel color="darkorchid">
            Optional Coverages
            <span style={{ fontSize: "11px", color: "gray" }}>(Sub limit)</span>
          </HoverableFormLabel>
        </Box>

        <FormControl>
          <HoverableFormLabel>Vendors Liability Extension</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Vendors_Liability_Extension")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Technical collaborators liability
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Technical_collaborators_liability")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Accidental Pollution Extension
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Accidental_Pollution_Extension")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Liability arising out of Transportation
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("arising_Transportation")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Act of God Extension</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Act_of_God_Extension")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Terrorism Extension</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Terrorism_Extension")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Goods kept in Custody</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Goods_kept_in_Custody")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Food and Beverages Extension</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Food_and_Beverages_Extension")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Swimming pools Extension</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Swimming_pools_Extension")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Sports facilities extension</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Sports_facilities_extension")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Personal & Advertising Injury</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Personal_Advertising_Injury")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Defense Costs</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Defense_Costs")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Legal liability arising out of performance of any renovation
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("legal_liability_renovation")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>
            Lifts, Elevators & Escalators Liability Extension
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            min={0}
            {...register("Lifts_Elevators_Escalators")}
          />
        </FormControl>
        <Box borderTop="1px solid" mt={2} gridColumn="span 2" />
        <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
          <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("start_date", {
              required: "Start Date is required",
            })}
          />
          {errors?.start_date && (
            <FormErrorMessage>{errors?.start_date?.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
          <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch('start_date')}
            {...register("end_date", { required: "End Date is required" })}
          />
          {errors?.end_date && (
            <FormErrorMessage>{errors?.end_date?.message}</FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="policy_start_date">
          <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("policy_start_date")}
          />
        </FormControl>

        <FormControl id="policy_end_date">
          <HoverableFormLabel>Policy End Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch('policy_start_date')}
            {...register("policy_end_date")}
          />
        </FormControl>

        <FormControl
          id="expected_premium"
          isInvalid={Boolean(errors?.expected_premium)}
        >
          <Flex align="start">
            <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Anticipated premium for acquiring this policy."
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("expected_premium", {
              required: "Anticipated premium is required",
            })}
          />
          {errors?.expected_premium && (
            <FormErrorMessage>
              {errors?.expected_premium?.message}
            </FormErrorMessage>
          )}
        </FormControl>

        <FormControl id="sum_insured" isInvalid={Boolean(errors?.sum_insured)}>
          <HoverableFormLabel>Limit of Indemnity*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("sum_insured", {
              required: "Sum insured is required",
            })}
          />
          {errors?.sum_insured && (
            <FormErrorMessage>{errors?.sum_insured?.message}</FormErrorMessage>
          )}
        </FormControl>
        {watch("policytype") === "Renewal" && (
          <HStack gridColumn="span 2" gap="20px">
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <FormErrorMessage>
                  {errors?.previous_insurer?.message}
                </FormErrorMessage>
              )}
            </FormControl>

            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <FormErrorMessage>
                  {errors?.previous_policy_expiry?.message}
                </FormErrorMessage>
              )}
            </FormControl>
          </HStack>
        )}
      </SimpleGrid>
      <HStack w="100%" justify="space-between" align="center" mt="20px">
        <Button
          type="button"
          borderRadius="21px"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          type="submit"
          borderRadius="21px"
          colorScheme="green"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Next
        </Button>
      </HStack>
    </form>
  );
};
export default CommercialLiability;
