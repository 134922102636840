import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import {
  FormControl,
  Select,
  Input,
  Button,
  Box,
  Grid,
  useToast,
  Flex,
  Text,
} from "@chakra-ui/react";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";

type FormValues = {
  profession: string;
  Profession_name_other?: string;
  specialization?: string;
  policytype: string;
  insurer: string;
  Limit_of_Indemnity: string;
  comp_location: string;
  Registration_start_date?: string;
  Licence_Obtain_date?: string;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
};

const ProfessionalIndemnity = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toast = useToast();
  const { step, setStep, setHoveredLabel, resetHoveredLabel } = useStore();
  const currentDate = new Date();
  const futureDate = new Date();
  futureDate.setDate(currentDate.getDate() + 10);
  const formattedFutureDate = futureDate.toISOString().split("T")[0];

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      sum_insured: 0,
      expected_premium: 0,
      start_date: new Date().toISOString().split("T")[0],
      end_date: formattedFutureDate,
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }
    formData.append("comp_id", selectedCompany.toString() || "");

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };

  const watchProfession = watch("profession");
  const watchPolicyType = watch("policytype");
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <FormControl isInvalid={Boolean(errors?.profession)}>
          <HoverableFormLabel>Profession*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("profession", {
              required: "Please select Profession",
            })}
            placeholder="Select Profession"
            onFocus={() => setHoveredLabel("Profession")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Doctor">Doctor</option>
            <option value="Medical Establishment">Medical Establishment</option>
            <option value="Engineer">Engineer</option>
            <option value="Architech">Architech</option>
            <option value="Charted accountent">Charted accountent</option>
            <option value="Lawyer">Lawyer</option>
            <option value="Others">Others</option>
          </Select>
          {errors?.profession && (
            <Text color="red.500" fontSize="sm">
              {errors?.profession?.message}
            </Text>
          )}
        </FormControl>
        {watchProfession === "Others" && (
          <FormControl isInvalid={Boolean(errors?.Profession_name_other)}>
            <HoverableFormLabel>Profession Name*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="text"
              {...register("Profession_name_other", {
                required: "Profession Name is required",
              })}
            />
            {errors?.Profession_name_other && (
              <Text color="red.500" fontSize="sm">
                {errors?.Profession_name_other?.message}
              </Text>
            )}
          </FormControl>
        )}
        {watchProfession === "Doctor" && (
          <FormControl isInvalid={Boolean(errors?.specialization)}>
            <HoverableFormLabel>Specialization*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("specialization", {
                required: "Please select Specialization",
              })}
              placeholder="Select Specialization"
              onFocus={() => setHoveredLabel("Specialization")}
              onBlur={() => resetHoveredLabel()}
            >
              <option value="General Medicine">General Medicine</option>
              <option value="General Surgery">General Surgery</option>
              <option value="Otorhinolaryngology">Otorhinolaryngology</option>
              <option value="Internal Medicine">Internal Medicine</option>
              <option value="Immunology">Immunology</option>
              <option value="Critical Care Medicine">
                Critical Care Medicine
              </option>
              <option value="Critical Care Medicine">
                Critical Care Medicine
              </option>
              <option value="Infectious Diseases">Infectious Diseases</option>
              <option value="Clinical Pharmocology">
                Clinical Pharmocology
              </option>
              <option value="Anaesthesiology">Anaesthesiology</option>
              <option value="Radiology">Radiology</option>
              <option value="Sonology">Sonology</option>
              <option value="Biochemistry">Biochemistry</option>
              <option value="Virology">Virology</option>
              <option value="Bacteriology">Bacteriology</option>
              <option value="Oncology">Oncology</option>
              <option value="Geriatric medicine">Geriatric medicine</option>
              <option value="Epidemiology">Epidemiology</option>
              <option value="Communicative Diseases">
                Communicative Diseases
              </option>
              <option value="Dermatology">Dermatology</option>
              <option value="Trichology">Trichology</option>
              <option value="Psychiatry">Psychiatry</option>
              <option value="Neurology">Neurology</option>
              <option value="Otolaryngology (ENT)">Otolaryngology (ENT)</option>
              <option value="Otology">Otology</option>
              <option value="Rhinology">Rhinology</option>
              <option value="Ophthalmology">Ophthalmology</option>
              <option value="Cardiology">Cardiology</option>
              <option value="Pulmonology">Pulmonology</option>
              <option value="Angiology">Angiology</option>
              <option value="Haematology">Haematology</option>
              <option value="Endocrinology">Endocrinology</option>
              <option value="Gastroenterology">Gastroenterology</option>
              <option value="Hepatology">Hepatology</option>
              <option value="Diabetology">Diabetology</option>
              <option value="Pediatrics">Pediatrics</option>
              <option value="Obstetrics">Obstetrics</option>
              <option value="Sexology/Venereology">Sexology/Venereology</option>
              <option value="Gynecology">Gynecology</option>
              <option value="Andrology">Andrology</option>
              <option value="Nephrology">Nephrology</option>
              <option value="Urology">Urology</option>
              <option value="Osteopathy/ Orthopedy">
                Osteopathy/ Orthopedy
              </option>
              <option value="Rheumatology">Rheumatology</option>
              <option value="Arthrology">Arthrology</option>
              <option value="Syndesmology">Syndesmology</option>
              <option value="Myology/ Sarcology">Myology/ Sarcology</option>
              <option value="Sports Medicine">Sports Medicine</option>
              <option value="Dentistry">Dentistry</option>
              <option value="Odontology">Odontology</option>
              <option value="Veterinary science">Veterinary science</option>
            </Select>
            {errors?.specialization && (
              <Text color="red.500" fontSize="sm">
                {errors?.specialization?.message}
              </Text>
            )}
          </FormControl>
        )}

        <FormControl isInvalid={Boolean(errors?.policytype)}>
          <HoverableFormLabel>Policy Type*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("policytype", { required: "Policy type is required" })}
            placeholder="Select policy type"
            onFocus={() => setHoveredLabel("Policy Type")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="New">New</option>
            <option value="Renewal">Renewal</option>
          </Select>
          {errors?.policytype && (
            <Text color="red.500" fontSize="sm">
              {errors?.policytype?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.insurer)}>
          <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("insurer", {
              required: "Bidder preference is required",
            })}
            placeholder="Preference"
            onFocus={() => setHoveredLabel("Bidder Preference")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="Both">Both</option>
            <option value="Brokers">Brokers</option>
            <option value="INS_COMP">Insurance Company</option>
          </Select>
          {errors?.insurer && (
            <Text color="red.500" fontSize="sm">
              {errors?.insurer?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.Limit_of_Indemnity)}>
          <Flex align="start">
            <HoverableFormLabel>
              Limit of Indemnity Ratio (AOA)*
            </HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Establish the indemnity cover limit. For example, a 1:1 limit indicates that the maximum liability is equal to the entire amount insured. A 1:2 limit indicates that the maximum compensation is liable for half of the total insured, with the remaining half being used for other claims."
              />
            )}
          </Flex>
          <Select
            bg="white"
            borderRadius="21px"
            {...register("Limit_of_Indemnity", {
              required: "Limit of indemnity is required",
            })}
            placeholder="(AOA:AOY)"
            onFocus={() => setHoveredLabel("Limit of Indemnity Ratio (AOA)")}
            onBlur={() => resetHoveredLabel()}
          >
            <option value="1:1">1:1</option>
            <option value="1:2">1:2</option>
            <option value="1:3">1:3</option>
            <option value="1:4">1:4</option>
          </Select>
          {errors?.Limit_of_Indemnity && (
            <Text color="red.500" fontSize="sm">
              {errors?.Limit_of_Indemnity?.message}
            </Text>
          )}
        </FormControl>

        <FormControl
          isInvalid={Boolean(errors?.comp_location)}
          gridColumn={
            watchProfession === "Others" || watchProfession === "Doctor"
              ? "span 1"
              : "span 2"
          }
          w={
            watchProfession === "Others" || watchProfession === "Doctor"
              ? "100%"
              : "50%"
          }
        >
          <HoverableFormLabel>
            What is your communication address?*
          </HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            {...register("comp_location", {
              required: "Company address is required",
            })}
            placeholder="Enter your address"
            onFocus={() =>
              setHoveredLabel("What is your communication address?")
            }
            onBlur={() => resetHoveredLabel()}
          />
          {errors?.comp_location && (
            <Text color="red.500" fontSize="sm">
              {errors?.comp_location?.message}
            </Text>
          )}
        </FormControl>

        {watchProfession === "Doctor" && (
          <>
            <FormControl isInvalid={Boolean(errors?.Registration_start_date)}>
              <HoverableFormLabel>Registration date</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                onFocus={() => setHoveredLabel("Registration date")}
                // onBlur={() => resetHoveredLabel()}
                {...register("Registration_start_date", {
                  required: "Registration date is required",
                })}
              />
              {errors?.Registration_start_date && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Registration_start_date?.message}
                </Text>
              )}
            </FormControl>
            <FormControl isInvalid={Boolean(errors?.Licence_Obtain_date)}>
              <HoverableFormLabel>License Obtain Date</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("Licence_Obtain_date", {
                  required: "Licence obtain date is required",
                })}
              />
              {errors?.Licence_Obtain_date && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Licence_Obtain_date?.message}
                </Text>
              )}
            </FormControl>
          </>
        )}

        <FormControl isInvalid={Boolean(errors?.start_date)}>
          <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("start_date", {
              required: "Bid start date is required",
            })}
          />
          {errors?.start_date && (
            <Text color="red.500" fontSize="sm">
              {errors?.start_date?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.end_date)}>
          <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch("start_date")}
            {...register("end_date", { required: "Bid end date is required" })}
          />
          {errors?.end_date && (
            <Text color="red.500" fontSize="sm">
              {errors?.end_date?.message}
            </Text>
          )}
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={todayDate}
            {...register("policy_start_date")}
          />
        </FormControl>

        <FormControl>
          <HoverableFormLabel>Policy End Date</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="date"
            min={watch("policy_start_date")}
            {...register("policy_end_date")}
          />
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.expected_premium)}>
          <Flex align="start">
            <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Anticipated premium for acquiring this policy."
              />
            )}
          </Flex>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("expected_premium", {
              required: "Anticipated premium is required",
            })}
            min="0"
          />
          {errors?.expected_premium && (
            <Text color="red.500" fontSize="sm">
              {errors?.expected_premium?.message}
            </Text>
          )}
        </FormControl>

        <FormControl isInvalid={Boolean(errors?.sum_insured)}>
          <HoverableFormLabel>Coverage</HoverableFormLabel>
          <Input
            bg="white"
            borderRadius="21px"
            type="number"
            {...register("sum_insured", {
              required: "Coverage is required",
            })}
            min="0"
          />
          {errors?.sum_insured && (
            <Text color="red.500" fontSize="sm">
              {errors?.sum_insured?.message}
            </Text>
          )}
        </FormControl>

        {watchPolicyType === "Renewal" && (
          <>
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>

            {/* Previous Policy Expiry */}
            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </>
        )}
      </Grid>
      <Flex w="100%" justify="space-between" align="center" mt="20px">
        <Button
          type="button"
          borderRadius="21px"
          onClick={() => setStep(step - 1)}
        >
          Previous
        </Button>
        <Button
          type="submit"
          borderRadius="21px"
          colorScheme="green"
          isDisabled={isSubmitting}
          isLoading={isSubmitting}
        >
          Next
        </Button>
      </Flex>
    </form>
  );
};

export default ProfessionalIndemnity;
