import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingScreen from "../LoadingScreen";

const AcceptInvitation = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(search);

  const companyId = searchParams.get("company_id");
  if (!companyId) return <LoadingScreen />;
  else if (companyId) {
    localStorage.setItem("acceptInvite", companyId);
    navigate("/login-client");
  }
  return <div>Accept PAge</div>;
};

export default AcceptInvitation;
