import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  HStack,
  Input,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { useStore } from "../../../store/store";
import "../form.css";
import { BEProductMap, flattenObject } from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";
interface FormValues {
  insured: string;
  policytype: string;
  insurer: string;
  comp_location: string;
  Floater: string;
  Location1: string;
  Location2: string;
  Security_type: string;
  Watchman: string;
  Alarm_System: string;
  CCTV: string;
  Storage: string;
  Heightened_boundary_walls: string;
  watchman_available: string;
  doors_and_windows: string;
  Content: number;
  Stock_value: number;
  Plant_and_Machinery: number;
  Furniture_Fixture_Fittings: number;
  OtherItemsvalue: number;
  Item_description: string;
  First_Loss_Sum_Insured: number;
  Absolute_Amount: number;
  Loss_of_money_Limit: number;
  Clearing_up_Expenses: number;
  Temporary_Property_Protection: number;
  Theft_Extension: number;
  Riot_Strike_Malicious: number;
  Injury_during_Burglary: number;
  start_date: string;
  end_date: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer?: string;
  previous_policy_expiry?: string;
  policy_start_date: string;
  policy_end_date: string;
}

const BurglaryForm = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const toast = useToast();
  const { step, setStep,setHoveredLabel,resetHoveredLabel } = useStore();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      Loss_of_money_Limit: 0,
      Clearing_up_Expenses: 0,
      Temporary_Property_Protection: 0,
      Theft_Extension: 0,
      Riot_Strike_Malicious: 0,
      Injury_during_Burglary: 0,
      First_Loss_Sum_Insured: 100,
      Absolute_Amount: 0,
      expected_premium: 0,
      sum_insured: 0,
    },
  });

  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);

      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);

      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const stockValue = +watch("Stock_value");
  const contentValue = +watch("Content");
  const plantAndMachinery = +watch("Plant_and_Machinery");
  const furnitureFixtureFittings = +watch("Furniture_Fixture_Fittings");
  const otherItemsValue = +watch("OtherItemsvalue");

  useEffect(() => {
    const sum =
      contentValue +
      stockValue +
      plantAndMachinery +
      furnitureFixtureFittings +
      otherItemsValue;
    setValue("sum_insured", sum);
  }, [
    contentValue,
    stockValue,
    plantAndMachinery,
    furnitureFixtureFittings,
    otherItemsValue,
    setValue,
  ]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        {/* Section 1: Insured Details */}
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.insured}>
            <HoverableFormLabel>
            Occupation (Whom do you want to insure?)*
            </HoverableFormLabel>
            <Controller
              name="insured"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Whom do you want to insure ?"
                  onFocus={() =>
                    setHoveredLabel("Occupation (Whom do you want to insure?)")
                  }
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="Business">Business</option>
                  <option value="Home">Home</option>
                </Select>
              )}
            />
            {errors.insured && (
              <FormErrorMessage>{errors.insured.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.policytype}>
            <HoverableFormLabel>Policy Type*</HoverableFormLabel>
            <Controller
              name="policytype"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Select policy type"
                >
                  <option value="New">New</option>
                  <option value="Renewal">Renewal</option>
                </Select>
              )}
            />
            {errors.policytype && (
              <FormErrorMessage>{errors.policytype.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.insurer}>
            <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
            <Controller
              name="insurer"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Preference"
                >
                  <option value="Both">Both</option>
                  <option value="Brokers">Brokers</option>
                  <option value="INS_COMP">Insurance Company</option>
                </Select>
              )}
            />
            {errors.insurer && (
              <FormErrorMessage>{errors.insurer.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.comp_location}>
            <HoverableFormLabel>
              Where is your company located?*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("comp_location")}
              placeholder="Company location"
            />
            {errors.comp_location && (
              <FormErrorMessage>
                {errors.comp_location.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Floater}>
            <HoverableFormLabel>Floater*</HoverableFormLabel>
            <Controller
              name="Floater"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Floater"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              )}
            />
            {errors.Floater && (
              <FormErrorMessage>{errors.Floater.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Location 1</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Location1")}
              placeholder="Location 1"
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Location 2</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Location2")}
              placeholder="Location 2"
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={2} borderTop="1px solid" color="darkorchid">
          <Text
            gridColumn="span 2"
            color="darkorchid"
            fontWeight="600"
            fontSize="16px"
          >
            Special Conditions
          </Text>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Security_type}>
            <HoverableFormLabel>Security type*</HoverableFormLabel>
            <Controller
              name="Security_type"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Security type"
                >
                  <option value="Armed">Armed</option>
                  <option value="Unarmed">Unarmed</option>
                  <option value="Not Available">Not Available</option>
                </Select>
              )}
            />
            {errors.Security_type && (
              <FormErrorMessage>
                {errors.Security_type.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Watchman}>
            <HoverableFormLabel>Watchman*</HoverableFormLabel>
            <Controller
              name="Watchman"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Watchman"
                >
                  <option value="Common">Common</option>
                  <option value="Dedicated">Dedicated</option>
                  <option value="Not Available">Not Available</option>
                </Select>
              )}
            />
            {errors.Watchman && (
              <FormErrorMessage>{errors.Watchman.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Alarm_System}>
            <HoverableFormLabel>Alarm System*</HoverableFormLabel>
            <Controller
              name="Alarm_System"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Alarm System"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              )}
            />
            {errors.Alarm_System && (
              <FormErrorMessage>{errors.Alarm_System.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.CCTV}>
            <HoverableFormLabel>CCTV Availability*</HoverableFormLabel>
            <Controller
              name="CCTV"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="CCTV Availability"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              )}
            />
            {errors.CCTV && (
              <FormErrorMessage>{errors.CCTV.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Storage}>
            <HoverableFormLabel>Storage*</HoverableFormLabel>
            <Controller
              name="Storage"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Storage"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              )}
            />
            {errors.Storage && (
              <FormErrorMessage>{errors.Storage.message}</FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Heightened_boundary_walls}>
            <HoverableFormLabel>Heightened Boundary Walls*</HoverableFormLabel>
            <Controller
              name="Heightened_boundary_walls"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Heightened Boundary Walls"
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              )}
            />
            {errors.Heightened_boundary_walls && (
              <FormErrorMessage>
                {errors.Heightened_boundary_walls.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.watchman_available}>
            <HoverableFormLabel>
            Is 24-hour watchman available?*
            </HoverableFormLabel>
            <Controller
              name="watchman_available"
              control={control}
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Is 24 hour watchman available"
                  onFocus={() =>
                    setHoveredLabel("Is 24-hour watchman available?")
                  }
                  onBlur={() => resetHoveredLabel()}
                >
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Select>
              )}
            />
            {errors.watchman_available && (
              <FormErrorMessage>
                {errors.watchman_available.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.doors_and_windows}>
            <HoverableFormLabel>
              Is Security of doors and windows*
            </HoverableFormLabel>
            <Controller
              name="doors_and_windows"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <Select
                  bg="white"
                  borderRadius="21px"
                  {...field}
                  placeholder="Is Security of doors and windows"
                >
                  <option value="Not secured">Not secured</option>
                  <option value="Secured">Secured</option>
                </Select>
              )}
            />
            {errors.doors_and_windows && (
              <FormErrorMessage>
                {errors.doors_and_windows.message}
              </FormErrorMessage>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={2} borderTop="1px solid" color="darkorchid">
          <Text
            gridColumn="span 2"
            color="darkorchid"
            fontWeight="600"
            fontSize="16px"
          >
            Value of the property
          </Text>
        </GridItem>
        {/* Content */}
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Content}>
            <HoverableFormLabel>Content*</HoverableFormLabel>
            <Controller
              name="Content"
              control={control}
              defaultValue={0}
              rules={{ required: "Content is required" }}
              render={({ field }) => (
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  {...field}
                  placeholder="0"
                  onFocus={() =>
                    setHoveredLabel("Content")
                  }
                  onBlur={() => resetHoveredLabel()}
                />
              )}
            />
            <FormErrorMessage>
              {errors.Content && errors.Content.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        {/* Stock Value */}
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Stock_value}>
            <HoverableFormLabel>Stock value*</HoverableFormLabel>
            <Controller
              name="Stock_value"
              control={control}
              defaultValue={0}
              rules={{ required: "Stock value is required" }}
              render={({ field }) => (
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  {...field}
                  placeholder="0"
                />
              )}
            />
            <FormErrorMessage>
              {errors.Stock_value && errors.Stock_value.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        {/* Plant and Machinery */}
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Plant_and_Machinery}>
            <HoverableFormLabel>Plant and Machinery*</HoverableFormLabel>
            <Controller
              name="Plant_and_Machinery"
              control={control}
              defaultValue={0}
              rules={{ required: "Plant and Machinery is required" }}
              render={({ field }) => (
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  {...field}
                  placeholder="0"
                />
              )}
            />
            <FormErrorMessage>
              {errors.Plant_and_Machinery && errors.Plant_and_Machinery.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        {/* Furniture, Fixture & Fittings */}
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Furniture_Fixture_Fittings}>
            <HoverableFormLabel>
              Furniture, Fixture & Fittings*
            </HoverableFormLabel>
            <Controller
              name="Furniture_Fixture_Fittings"
              control={control}
              defaultValue={0}
              rules={{ required: "Furniture, Fixture & Fittings is required" }}
              render={({ field }) => (
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  {...field}
                  placeholder="0"
                />
              )}
            />
            <FormErrorMessage>
              {errors.Furniture_Fixture_Fittings &&
                errors.Furniture_Fixture_Fittings.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        {/* Other Items Value */}
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.OtherItemsvalue}>
            <HoverableFormLabel>Other Items value*</HoverableFormLabel>
            <Controller
              name="OtherItemsvalue"
              control={control}
              defaultValue={0}
              rules={{ required: "Other Items value is required" }}
              render={({ field }) => (
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  {...field}
                  placeholder="0"
                />
              )}
            />
            <FormErrorMessage>
              {errors.OtherItemsvalue && errors.OtherItemsvalue.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>

        {/* Item Description */}
        <GridItem colSpan={1}>
          <FormControl isInvalid={!!errors.Item_description}>
            <HoverableFormLabel>Item description*</HoverableFormLabel>
            <Controller
              name="Item_description"
              control={control}
              defaultValue=""
              rules={{ required: "Item description is required" }}
              render={({ field }) => (
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="text"
                  {...field}
                  placeholder="Item description"
                />
              )}
            />
            <FormErrorMessage>
              {errors.Item_description && errors.Item_description.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2} borderTop="1px solid" color="darkorchid">
          <Flex align="start">
            <Text
              gridColumn="span 2"
              color="darkorchid"
              fontWeight="600"
              fontSize="16px"
            >
              First Loss
            </Text>
            {mode === "Guided" && (
              <FaInfoCircle
                fontSize="11px"
                color="gray.500"
                title="Provide financial coverage to specific sum insured instead of complete coverages."
              />
            )}
          </Flex>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>
            First Loss Sum Insured (Total Value at Risk %)
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("First_Loss_Sum_Insured")}
              onFocus={() =>
                setHoveredLabel("First Loss Sum Insured (Total Value at Risk %)")
              }
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>
              First Loss Basis in terms of Absolute Amount
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Absolute_Amount")}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={2} borderTop="1px solid" color="darkorchid">
          <Text
            gridColumn="span 2"
            color="darkorchid"
            fontWeight="600"
            fontSize="16px"
          >
            Optional Coverages
            <span style={{ color: "rgba(0, 0, 0, 0.5)", fontSize: "0.7rem" }}>
              (Sublimit)
            </span>
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Loss of Money Limit</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Loss_of_money_Limit")}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Clearing Up Expenses</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Clearing_up_Expenses")}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>
              Temporary Property Protection
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Temporary_Property_Protection")}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Theft Extension</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Theft_Extension")}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Riot, Strike, Malicious Damage Cover</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Riot_Strike_Malicious")}
              onFocus={() =>
                setHoveredLabel("Riot, Strike, Malicious Damage Cover")
              }
              onBlur={() => resetHoveredLabel()}
            />
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Injury during Burglary</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Injury_during_Burglary")}
            />
          </FormControl>
        </GridItem>

        {/* Section 4: Policy Period */}
        <GridItem colSpan={2} borderTop="1px solid" color="darkorchid" />

        <GridItem colSpan={1}>
          <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
            <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("start_date", {
                required: "Bid start date is required",
              })}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>
        </GridItem>

        <GridItem colSpan={1}>
          <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
            <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch('start_date')}
              {...register("end_date", {
                required: "Bid's end date is required",
              })}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("policy_start_date")}
            />
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl>
            <HoverableFormLabel>Policy End Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch('policy_start_date')}
              {...register("policy_end_date")}
            />
          </FormControl>
        </GridItem>

        {/* Section 5: Expected Premium */}
        <GridItem colSpan={1}>
          <FormControl
            id="expected_premium"
            isInvalid={Boolean(errors?.expected_premium)}
          >
            <Flex align="start">
              <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              min="0"
              defaultValue="0"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors?.expected_premium?.message}
              </Text>
            )}
          </FormControl>
        </GridItem>
        <GridItem colSpan={1}>
          <FormControl
            id="sum_insured"
            isInvalid={Boolean(errors?.sum_insured)}
          >
            <HoverableFormLabel>Sum Insured</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              defaultValue="0"
              {...register("sum_insured")}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>
        </GridItem>

        {/* Submit Button */}
        {watch("policytype") === "Renewal" && (
          <GridItem colSpan={1}>
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>
          </GridItem>
        )}
        {watch("policytype") === "Renewal" && (
          <GridItem colSpan={1}>
            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </GridItem>
        )}
        <GridItem colSpan={2}>
          <HStack w="100%" justify="space-between" align="center" mt="20px">
            <Button
              type="button"
              borderRadius="21px"
              onClick={() => setStep(step - 1)}
            >
              Previous
            </Button>
            <Button
              type="submit"
              borderRadius="21px"
              colorScheme="green"
              isDisabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Next
            </Button>
          </HStack>
        </GridItem>
      </Grid>
    </form>
  );
};

export default BurglaryForm;
