import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";

import FullDarkLogo from "../../partials/assets/images/full_logo_dark.svg";
import { ReactComponent as GoogleIcon } from "../../partials/assets/icons/google-icon.svg";
import { ReactComponent as WhatsAppIcon } from "../../partials/assets/icons/whatsapp-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Authenticate } from "../../initOTPless";
import LoadingScreen from "../LoadingScreen";
import useFetch from "../../partials/utils/Interceptor/Interceptor";

const Login = () => {
  const API_URL = process.env.REACT_APP_BACKEND_URL;
  const { search, pathname } = useLocation();

  const searchParams = new URLSearchParams(search);

  const code = searchParams.get("code");
  const navigate = useNavigate();
  const toast = useToast();
  const localValue = pathname === "/login-bidder" ? "3" : "2";
  const [loginType, setLoginType] = useState<string>(localValue);
  const [emailOrPhone, setEmailOrPhone] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleContinue = () => {
    if (isValidEmail(emailOrPhone)) {
      const excludedEmailDomains = /@(?!gmail\.com|ymail\.com|yahoo\.com)/;
      if (loginType === "3" && !excludedEmailDomains.test(emailOrPhone)) {
        toast({
          title: `Invalid Email`,
          description: "Please Enter a Valid Business Email",
          status: "error",
          isClosable: true,
        });

        return;
      }
      emailAuth();
      // } else if (isValidPhone(emailOrPhone)) {
      //   phoneAuth();
    } else {
      toast({
        title: "Invalid email or phone format",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isValidEmail = (input: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);

  const isValidPhone = (input: string) => /^\+?[1-9]\d{1,14}$/.test(input);
  const toggleLoginType = () => {
    if (loginType === "2") {
      setLoginType("3");
    } else {
      setLoginType("2");
    }
  };
  const phoneAuth = () => {
    Authenticate({ channel: "PHONE", phone: emailOrPhone });
    toast({
      title: "Email sent",
      description: `A message has been sent to ${emailOrPhone}. Please check your inbox.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const emailAuth = () => {
    setIsSubmitting(true);
    const body = {
      email: emailOrPhone,
      role_id: Number.parseInt(loginType),
      channel: "email",
    };
    axios
      .post(`${API_URL}generate-magic-link/`, body)
      .then((res) => {
        toast({
          title: "Email sent",
          description: `An email has been sent to ${emailOrPhone}. Please check your inbox.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Something went wrong ",
          description: `Please try again later.`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {
    localStorage.setItem("loginType", loginType);
    const newTypeString = loginType === "2" ? "client" : "bidder";
    navigate(`/login-${newTypeString}`);
  }, [loginType]);

  const acceptInvite = (companyId: string | number) => {
    setIsLoading(true);
    const body = {
      company_id: companyId,
    };
    useFetch
      .post(`/api/accept-invitation/`, body)
      .then((res) => {
        localStorage.removeItem("acceptInvite");
        navigate("/home");
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (code && !isLoading) {
      setIsLoading(true);
      axios
        .post(`${API_URL}verify-magic-link/`, { code })
        .then((res) => {
          Cookies.set("user", JSON?.stringify(res?.data));
          localStorage.setItem(
            "bidder_profiling",
            JSON.stringify(res?.data?.bidder_profiling)
          );
          const token = res?.data?.token || res?.data?.Token;
          Cookies.set("jwt", token);
          const isInvitedUser = localStorage.getItem("acceptInvite");
          if (isInvitedUser) {
            acceptInvite(isInvitedUser);
          } else {
            if (res.data?.role === 2 && !res?.data?.company_created) {
              navigate("/profile?company=0");
            } else {
              navigate("/home");
            }
          }
        })
        .catch((error) => {
          toast({
            title: "Something went wrong ",
            description: `Please try again later.`,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsLoading(false));
    }
  }, [code]);
  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <Flex
      w="100%"
      height="100vh"
      className="login-gradient-bg"
      padding={{ base: "20px", lg: "0px" }}
    >
      <Flex
        justify="end"
        position="fixed"
        top="40px"
        right="40px"
        display={{ base: "flex", lg: "none" }}
      >
        <Button
          // leftIcon={<CompassIcon />}
          w="200px"
          bg="rgb(121 119 119 / 30%)"
          fontSize="16px"
          _hover={{ bg: "rgb(121 119 119 / 20%)" }}
          borderRadius="12px"
          onClick={toggleLoginType}
          type="button"
        >
          {loginType === "3" ? "Client" : "Bidder"} Portal
        </Button>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "45%" }}
        height="100%"
        direction="column"
        align="center"
        justify="center"
        gap="18px"
        bg="white"
        borderRadius={{ base: "24px", lg: "0px 24px 24px 0px" }}
      >
        <Image src={FullDarkLogo} height="60px" width="150px" />
        <Flex
          direction="column"
          gap="15px"
          align="center"
          width="100%"
          maxW="346px"
        >
          <Flex direction="column" gap="4px" align="center" width="100%">
            <Text fontSize="24px" fontWeight="600">
              {loginType === "3" ? "Bidder" : "Client"} Platform
            </Text>
            <Text>Let's get started</Text>
          </Flex>
          <Input
            type="text"
            placeholder="Enter phone or email"
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
            w="100%"
            height="46px"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleContinue();
              }
            }}
          />
          <Button
            w="100%"
            colorScheme="black"
            bg="black"
            isDisabled={!emailOrPhone || isSubmitting}
            isLoading={isSubmitting}
            onClick={handleContinue}
          >
            Continue
          </Button>
          {/* <HStack w="full" justify="space-evenly">
            <Divider orientation="horizontal" border="1.5px solid black" />
            <Text
              fontSize="14px"
              fontWeight="600"
              textAlign="center"
              letterSpacing="-0.12px"
            >
              OR
            </Text>
            <Divider orientation="horizontal" border="1.5px solid black" />
          </HStack>
          <Button
            width="100%"
            bg="white"
            border="1px solid gray"
            leftIcon={<GoogleIcon />}
            fontSize="16px"
            justifyContent="start"
            // onClick={() =>
            //   Authenticate({ channel: "OAUTH", channelType: "GOOGLE" })
            // }
          >
            Continue with Google
          </Button>
          <Button
            width="100%"
            bg="white"
            border="1px solid gray"
            leftIcon={<WhatsAppIcon />}
            fontSize="16px"
            justifyContent="start"
            // onClick={() =>
            //   Authenticate({ channel: "OAUTH", channelType: "WHATSAPP" })
            // }
          >
            Continue with WhatsApp
          </Button> */}
        </Flex>
        <Text fontSize="13px" mt="55px">
          By signing in, you agree to{" "}
          <Link textDecoration="underline" target="_blank" href="/terms">
            Terms and policy
          </Link>
        </Text>
      </Flex>
      <Flex
        flex={3}
        height="100%"
        direction="column"
        padding="27px 31px"
        display={{ base: "none", lg: "flex" }}
      >
        <Flex justify="end">
          <Button
            // leftIcon={<CompassIcon />}
            w="200px"
            bg="rgb(121 119 119 / 30%)"
            fontSize="16px"
            _hover={{ bg: "rgb(121 119 119 / 20%)" }}
            borderRadius="12px"
            onClick={toggleLoginType}
            type="button"
          >
            {loginType === "3" ? "Client" : "Bidder"} Portal
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Login;
