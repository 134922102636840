import { useEffect, useState } from "react";
import { Flex, Skeleton, Text } from "@chakra-ui/react";
import {
  addMissingFieldsToLabelMapping,
  flattenObject,
} from "../../../partials/utils/helper";
import {
  fieldCategories,
  fieldsToExcludeBidder,
  labelMapping,
} from "../../../partials/utils/tranlator";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { ReactComponent as PdfIcon } from "../../../partials/assets/icons/pdf-icon.svg";
import { productMapping } from "../../../partials/utils/Contants";
import toast from "react-hot-toast";

const categorizeFields = (
  data: { [key: string]: any },
  labelMapping: { [key: string]: string },
  fieldCategories: { [category: string]: string[] },
  fieldsToExcludeBidder: string[]
) => {
  const categorizedData: { [category: string]: { key: string; value: any }[] } =
    {};

  Object.entries(data).forEach(([key, value]) => {
    if (value !== null && !fieldsToExcludeBidder.includes(key)) {
      if (!(key in labelMapping)) {
        labelMapping[key] = key.replace(/_/g, " ");
      }

      const category =
        Object.keys(fieldCategories).find((category) =>
          fieldCategories[category].includes(labelMapping[key])
        ) || "Other Details";

      if (!categorizedData[category]) {
        categorizedData[category] = [];
      }
      categorizedData[category].push({ key, value });
    }
  });

  return categorizedData;
};

const BidderBidDetails = ({ id }: any) => {
  const [data, setData] = useState<any>();
  const [categorizedData, setCategorizedData] = useState<any>({});
  const [bidData, setBidData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  function fetchBidData() {
    setIsLoading(true);
    useFetch
      .get(`api/bid-detail-bidder/?bid_id=${id}`)
      .then((res) => {
        setBidData(res.data?.data);
        const data = res?.data?.data?.form_properties
          ? typeof res?.data?.data?.form_properties === "string"
            ? JSON.parse(res?.data?.data?.form_properties)
            : res?.data?.data?.form_properties
          : res?.data?.data;
        const flattenedData = flattenObject({ ...data, interests: null });
        addMissingFieldsToLabelMapping(flattenedData, labelMapping);
        setData(flattenedData);
      })
      .catch((error) => {})
      .finally(() => setIsLoading(false));
  }
  useEffect(() => {
    fetchBidData();
  }, [id]);
  useEffect(() => {
    if (data) {
      setCategorizedData(
        categorizeFields(
          data,
          labelMapping,
          fieldCategories,
          fieldsToExcludeBidder
        )
      );
    }
  }, [data]);
  const downloadFile = async (id: string) => {
    const toastId = toast.loading("Downloading...");

    try {
      const response = await useFetch.get(`api/download-file/?doc_id=${id}`);
      const presignedUrl = response.data?.presigned_url;
      if (presignedUrl) {
        const link = document.createElement("a");
        link.href = presignedUrl;
        link.download = "";
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        toast.success("Downloaded Successfully", { id: toastId });
      }
    } catch (error: any) {
      console.error("Error downloading file:", error);
      toast.error(error.response.data?.error || "Something Went wrong", {
        id: toastId,
      });
    }
  };

  return (
    <>
      {isLoading ? (
        <Flex
          w="100%"
          gap="10px"
          direction="column"
          p="0px 14px 25px 0px"
          color="white"
        >
          {[1, 2, 3].map((e) => (
            <Skeleton height="20px" w="90%" key={e} />
          ))}
        </Flex>
      ) : (
        <Flex
          w="100%"
          gap="10px"
          direction="column"
          p="0px 14px 25px 0px"
          color="white"
        >
          {bidData?.RFQ && (
            <Flex w="100%" px="10px" pt="5px" justify="end">
              <Flex
                align="center"
                gap="5px"
                onClick={() => downloadFile(bidData?.RFQ)}
                cursor="pointer"
                border="1px solid #dadada"
                borderRadius="16px"
                padding="3px 8px"
              >
                <PdfIcon height="18px" width="18px" fontWeight="700" />
                <Text
                  fontWeight="600"
                  fontSize="14px"
                  color="rgba(255, 255, 255, 0.70)"
                >
                  RFQ
                </Text>
              </Flex>
            </Flex>
          )}
          {Object.entries(categorizedData)
            .sort(([a], [b]) => {
              if (a === "Company Details") return -1;
              if (b === "Company Details") return 1;
              if (a === "Policy Details") return -1;
              if (b === "Policy Details") return 1;
              if (a === "Other Details") return 1;
              if (b === "Other Details") return -1;
              return a.localeCompare(b);
            })
            .map(([category, fields]: any) => {
              if (category && fields?.length >= 1)
                return (
                  <Flex
                    direction="column"
                    key={category}
                    gap="12px"
                    pb="30px"
                    pt="20px"
                    borderBottom="1px solid rgba(246, 246, 246, 0.6)"
                  >
                    <Text fontSize="16px" fontWeight="600">
                      {category}
                    </Text>
                    <Flex gap="20px" wrap="wrap">
                      {fields?.map(({ key, value, index }: any) => {
                        if (value || value !== null)
                          return (
                            <Flex key={index} gap="6px" fontSize="12px">
                              <Text
                                textTransform="capitalize"
                                fontWeight="bold"
                                color="rgba(246, 246, 246, 0.60)"
                                whiteSpace="nowrap"
                              >
                                {labelMapping[key] || key}:{" "}
                              </Text>
                              <Text>
                                {value !== null
                                  ? labelMapping[key] || key === "Product"
                                    ? productMapping[value] || value?.toString()
                                    : value.toString()
                                  : "N/A"}
                              </Text>
                            </Flex>
                          );
                      })}
                    </Flex>
                  </Flex>
                );
            })}
        </Flex>
      )}
    </>
  );
};

export default BidderBidDetails;
