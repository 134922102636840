import { Navigate, Route, Routes } from "react-router-dom";
import PrivateRoutes from "./PrivateRoute";
import NotFoundPage from "../../pages/NotFound";
import BidderBidCentre from "../../pages/Bidder/Bidder-BidCenter";
import BidderRolesPage from "../../pages/Bidder/Bidder-Roles";
import BidderTeamMembers from "../../pages/Bidder/Bidder-TeamMembers";
import SetUpProfile from "../../pages/Bidder/SetupProfile";
import BidderHome from "../../pages/Bidder/BidderHome";
import Layout from "./Layout";
import BidderQuote from "../../pages/Bidder/Bidder-Quote";
import Subscription from "../../pages/Subscription";
import BidderChat from "../../pages/BidderChat";

export const BidderRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* we want to protect these routes */}
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<Navigate to="/home" replace />} />

          <Route path="/home" element={<BidderHome />} />
          <Route path="/bid-centre" element={<BidderBidCentre />} />
          <Route path="/bid-centre/quote/:id" element={<BidderQuote />} />
          <Route path="/roles" element={<BidderRolesPage />} />
          <Route path="/team-members" element={<BidderTeamMembers />} />
          <Route path="/conversations" element={<BidderChat />} />
          <Route path="/subscription" element={<Subscription />} />
        </Route>
        <Route path="/setupProfile" element={<SetUpProfile />} />

        {/* catch all */}
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};
