import { Flex, Text } from "@chakra-ui/react";
import moment from "moment";
import { productMapping, theme } from "../../../partials/utils/Contants";

const ConversationCard = ({
  selectedBidder,
  setSelectedBidder,
  data,
  type,
}: any) => {
  const product = productMapping[data?.product] || data?.product;
  const startChat = () => {
    if (type === "bidder") {
      setSelectedBidder({
        id: data?.client_id,
        bid_id: data?.bid_id,
        bidData: data,
      });
    } else {
      setSelectedBidder({
        id: data?.bidder_id,
        bid_id: data?.bid_id,
        bidData: data,
      });
    }
  };

  const isCardSelected = !!(type === "bidder"
    ? selectedBidder?.id === data?.client_id &&
      selectedBidder?.bid_id === data?.bid_id
    : selectedBidder?.id === data?.bidder_id &&
      selectedBidder?.bid_id === data?.bid_id);

  return (
    <Flex
      key={data?.bidder_id}
      direction="column"
      gap="13px"
      width="100%"
      className={isCardSelected ? "bid-card-selected" : "bid-card-bg"}
      padding="12px"
      position="relative"
      boxShadow="md"
      cursor="pointer"
      onClick={startChat}
    >
      <Flex width="100%" justify="space-between" align="center">
        <Flex width="100%" align="center" gap="8px">
          <Flex
            bg={theme.color.GREEN}
            height="15px"
            width="15px"
            borderRadius="full"
          />
          <Text
            fontSize="11px"
            fontWeight="400"
            color={
              isCardSelected ? "rgba(131, 131, 131, 1)" : "rgba(0, 0, 0, 0.50)"
            }
          >
            Active |{" "}
            {data?.bid_start_date
              ? moment(data?.bid_start_date).format("D MMMM, yyyy")
              : "N/A"}
            -
            {data?.bid_end_date
              ? moment(data?.bid_end_date).format("D MMMM, yyyy")
              : "N/A"}
          </Text>
        </Flex>
        <Text
          bg={theme.color.GREEN}
          color={isCardSelected ? "white" : "#F6F6F6"}
          shadow="md"
          padding="2px 10px 3px 10px"
          borderRadius="12px"
          height="21px"
          width="auto"
          fontWeight="500"
          textAlign="center"
          whiteSpace="nowrap"
          fontSize="11px"
          cursor="pointer"
          onClick={startChat}
        >
          Chat
        </Text>
      </Flex>
      <Flex direction="column" w="100%" gap="4px">
        <Flex width="100%" align="center" gap="8px">
          <Text
            fontSize="15px"
            fontWeight="700"
            color={isCardSelected ? "white" : "black"}
            whiteSpace="nowrap"
          >
            #{data?.bid_id} {product} |
          </Text>
          <Text
            fontSize="12px"
            fontWeight="700"
            color={
              isCardSelected ? "rgba(131, 131, 131, 1)" : theme.color.GRAY_LABEL
            }
            whiteSpace="nowrap"
          >
            Expected Premium: ₹{data?.expected_premium}
          </Text>
        </Flex>
        <Text
          fontSize="14px"
          fontWeight="700"
          color={isCardSelected ? "rgba(131, 131, 131, 1)" : "gray.500"}
          // whiteSpace="nowrap"
        >
          {data?.bidder_company_name || data?.client_company_name}
        </Text>
      </Flex>
      <Flex width="100%" align="center" justify="space-between">
        <Flex width="fit-content" align="center" gap="6px">
          {data?.bidder_interest_date && (
            <Flex width="fit-content" align="center" gap="6px">
              <Text
                color={isCardSelected ? "gray.400" : "gray.600"}
                fontSize="12px"
                fontWeight="500"
              >
                Bid Date:
              </Text>
              <Text
                fontSize="12px"
                color={isCardSelected ? "#dadada" : "black"}
                fontWeight="700"
              >
                {moment(data?.bidder_interest_date).format("D MMMM, yyyy")}
              </Text>
            </Flex>
          )}
        </Flex>
        {/* <Flex
          w="auto"
          px="5px"
          borderRadius="24px"
          borderWidth="1px"
          bg="black"
          color="white"
          align="center"
          cursor="pointer"
          fontSize="12px"
        >
          Know your bidder
        </Flex> */}
      </Flex>
    </Flex>
  );
};

export default ConversationCard;
