import {
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Skeleton,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";

import { useEffect, useMemo, useState } from "react";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { productMapping, theme } from "../../../partials/utils/Contants";
import BidderBidCard from "../../../components/BidderBidCard";
import BidderSanbotAI from "../../../components/Bidder/Bidder-Sanbot";
import { useFetchUser } from "../../../hooks/User";
import { CiSearch } from "react-icons/ci";

const statusOrder: any = {
  Accepted: 1,
  "Quote Accepted": 1,
  rejected: 2,
  "Quote Rejected": 2,
  "Waiting for Quote Approval": 3,
  pending: 4,
};
const BidderBidCentre = () => {
  const user = useFetchUser();
  const [selectedBid, setSelectedBid] = useState({ id: null, action: "" });
  const [liveBidsList, setLiveBidsList] = useState([]);
  const [completedBidsList, setCompletedBidsList] = useState<any>([]);
  const [engagedBidsList, setEngagedBidsList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const [isLiveLoading, setIsLiveLoading] = useState(false);
  const [isCompletedLoading, setIsCompletedLoading] = useState(false);
  const [isEngagedLoading, setIsEngagedLoading] = useState(false);
  function fetchLiveBids() {
    setIsLiveLoading(true);
    useFetch
      .get("api/live-bids-bidder/")
      .then((response) => {
        setLiveBidsList(response?.data?.live_bids);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsLiveLoading(false));
  }

  function fetchCompletedBids() {
    setIsCompletedLoading(true);
    useFetch
      .get("api/completed-bids-bidder")
      .then((response) => {
        setCompletedBidsList(response?.data?.completed_bids);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsCompletedLoading(false));
  }
  function fetchEngagedBids() {
    setIsEngagedLoading(true);
    useFetch
      .get("api/engaged_bids-bidder/")
      .then((response) => {
        const data = response?.data?.engaged;
        const engaged = data?.map((item: any) => {
          const isAccepted = item?.interest?.status === "accepted";
          let status = item?.interest?.status || "pending";
          if (isAccepted) {
            status = "Accepted";
            if (item?.quote_status === "pending") {
              status = "Waiting for Quote Approval";
            } else if (item?.quote_status === "accepted") {
              status = "Quote Accepted";
            } else if (item?.quote_status === "rejected") {
              status = "Quote Rejected";
            }
          }
          return { ...item, status };
        });
        const sortedEngaged = engaged?.sort((a: any, b: any) => {
          return statusOrder[a.status] - statusOrder[b.status];
        });
        setEngagedBidsList(sortedEngaged);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => setIsEngagedLoading(false));
  }

  function fetchAll() {
    fetchLiveBids();
    fetchCompletedBids();
    fetchEngagedBids();
  }
  useEffect(() => {
    fetchAll();
  }, []);
  useEffect(() => {
    setSearchText("");
  }, [selectedIndex]);
  const filteredLiveBids = useMemo(
    () =>
      searchText === ""
        ? [...liveBidsList]
        : liveBidsList.filter((bid: any) => {
            const product = productMapping[bid?.product] || bid?.product;

            return (
              (product &&
                product.toLowerCase().includes(searchText.toLowerCase())) ||
              (bid?.id && bid.id.toString().includes(searchText))
            );
          }),
    [liveBidsList, searchText]
  );
  const filteredEngagedBids = useMemo(
    () =>
      searchText === ""
        ? [...engagedBidsList]
        : engagedBidsList.filter((bid: any) => {
            const product = productMapping[bid?.product] || bid?.product;

            return (
              (product &&
                product.toLowerCase().includes(searchText.toLowerCase())) ||
              (bid?.id && bid.id.toString().includes(searchText))
            );
          }),
    [engagedBidsList, searchText]
  );
  const filteredCompletedBids = useMemo(
    () =>
      searchText === ""
        ? [...completedBidsList]
        : completedBidsList.filter((bid: any) => {
            const product = productMapping[bid?.product] || bid?.product;

            return (
              (product &&
                product.toLowerCase().includes(searchText.toLowerCase())) ||
              (bid?.id && bid.id.toString().includes(searchText))
            );
          }),
    [completedBidsList, searchText]
  );

  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding="30px 80px 30px 30px"
        flex={2}
        gap="10px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="500">
            Bid Centre
          </Text>
          <InputGroup w="50%">
            <InputLeftElement>
              <CiSearch />
            </InputLeftElement>
            <Input
              borderRadius="16px"
              type="text"
              w="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              placeholder="Search"
              fontSize="12px"
              fontWeight="500"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </InputGroup>
        </Flex>
        <Tabs
          variant="unstyled"
          width="100%"
          index={selectedIndex}
          onChange={setSelectedIndex}
        >
          <TabList gap="40px">
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Live Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Engaged Bids
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              onClick={() => setSelectedBid({ id: null, action: "" })}
            >
              Completed Bids
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="black"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel px="0px">
              {!isLiveLoading && liveBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  overflowY="auto"
                  height="calc(100dvh - 190px)"
                  className="hide-scrollbar"
                >
                  {filteredLiveBids?.length ? (
                    filteredLiveBids?.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};
                      return (
                        <BidderBidCard
                          fetchAll={fetchAll}
                          data={item}
                          type="live"
                          fetchData={fetchLiveBids}
                          setSelectedBid={setSelectedBid}
                          selectedBid={selectedBid}
                          bidId={item?.id}
                          bidderCount={item?.bidders}
                          form={form}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Flex
                        minH="40vh"
                        justify="center"
                        align="center"
                        w="100%"
                      >
                        <Text fontSize="16px" fontWeight="500">
                          No matching record found
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isLiveLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Live Bids Available</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isEngagedLoading && engagedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  overflowY="auto"
                  height="calc(100dvh - 190px)"
                  className="hide-scrollbar"
                >
                  {filteredEngagedBids?.length ? (
                    filteredEngagedBids?.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};

                      const quote = item?.quote.find(
                        (q: any) => q?.quote_status === "Accepted"
                      );
                      const isAccepted = item?.interest?.status === "accepted";
                      let status = item?.interest?.status || "pending";
                      if (isAccepted) {
                        status = "Accepted";
                        if (item?.quote_status === "pending") {
                          status = "Waiting for Quote Approval";
                        } else if (item?.quote_status === "accepted") {
                          status = "Quote Accepted";
                        } else if (item?.quote_status === "rejected") {
                          status = "Quote Rejected";
                        }
                      }

                      return (
                        <BidderBidCard
                          fetchAll={fetchAll}
                          data={item}
                          type="engaged"
                          fetchData={fetchEngagedBids}
                          bidId={item?.id}
                          pdfFile={item?.pdf_file}
                          quoteFile={item?.policy_file}
                          setSelectedBid={setSelectedBid}
                          selectedBid={selectedBid}
                          quote={quote}
                          engagedStatus={status}
                          form={form}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Flex
                        minH="40vh"
                        justify="center"
                        align="center"
                        w="100%"
                      >
                        <Text fontSize="16px" fontWeight="500">
                          No matching record found
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isEngagedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Engaged Bids Available</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
            <TabPanel px="0px">
              {!isCompletedLoading && completedBidsList?.length ? (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  height="82vh"
                  overflowY="auto"
                  className="hide-scrollbar"
                >
                  {filteredCompletedBids?.length ? (
                    filteredCompletedBids?.map((item: any) => {
                      const form = item?.form_properties
                        ? typeof item?.form_properties === "string"
                          ? JSON.parse(item?.form_properties)
                          : item?.form_properties
                        : {};
                      return (
                        <BidderBidCard
                          fetchAll={fetchAll}
                          data={item}
                          type="completed"
                          fetchData={fetchEngagedBids}
                          bidId={item?.id}
                          pdfFile={item?.policy?.policy_file}
                          quoteFile={item?.quote?.quote_file}
                          setSelectedBid={setSelectedBid}
                          selectedBid={selectedBid}
                          bidderCount={item?.bidders}
                          form={form}
                          quote={item?.quote}
                        />
                      );
                    })
                  ) : (
                    <>
                      <Flex
                        minH="40vh"
                        justify="center"
                        align="center"
                        w="100%"
                      >
                        <Text fontSize="16px" fontWeight="500">
                          No matching record found
                        </Text>
                      </Flex>
                    </>
                  )}
                </Flex>
              ) : (
                <Flex
                  direction="column"
                  w="100%"
                  align="center"
                  gap="12px"
                  justify="center"
                  minH="200px"
                >
                  {isCompletedLoading ? (
                    <Skeleton height="110px" w="100%" borderRadius="16px" />
                  ) : (
                    <Text>No Completed Bids Available</Text>
                  )}
                </Flex>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
      <BidderSanbotAI
        selectedBid={selectedBid}
        setSelectedBid={setSelectedBid}
      />
    </Flex>
  );
};

export default BidderBidCentre;
