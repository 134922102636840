import {
  Button,
  Flex,
  FormControl,
  Input,
  Select,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useStore } from "../../../store/store";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import "../form.css";
import { useEffect, useState } from "react";
import {
  BEProductMap,
  convertProductName,
  flattenObject,
} from "../../../partials/utils/helper";
import { FaInfoCircle } from "react-icons/fa";
import { changeSattusToDraft } from "../Forms";
import HoverableFormLabel from "../../../partials/elements/HoverableLabel";
import { todayDate } from "../../../partials/utils/Contants";
type FormValues = {
  policytype: string;
  insurer: string;
  comp_location: string;
  Type_of_Transport: string;
  Distance_of_carrying_money: number;
  Transit_details: string;
  Floater: string;
  Location1: string;
  Location2: string;
  Security: string;
  Alarm_System: string;
  CCTV: string;
  Security_of_doors: string;
  Money_In_Transit: number;
  Money_In_Safe: number;
  Money_In_Counter: number;
  Estimated_Annual_Turnover: number;
  SRCC_Coverage?: number;
  Terrorism?: number;
  start_date: string;
  end_date: string;
  policy_start_date?: string;
  policy_end_date?: string;
  expected_premium: number;
  sum_insured: number;
  previous_insurer: string;
  previous_policy_expiry: string;
};

const MoneyForm = ({
  product,
  editData,
  mode,
  selectedCompany,
  setCreatedBid,
}: any) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { step, setStep } = useStore();
  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      expected_premium: 0,
      sum_insured: 0,
    },
  });
  const toast = useToast();
  const onSubmit = (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", selectedCompany.toString() || "");
    const parsedProduct = BEProductMap[product];

    if (parsedProduct) {
      formData.append("product", parsedProduct);
    } else {
      formData.append("product", product);
    }

    formData.append("form_properties", JSON.stringify({ ...data }));
    if (editData?.id) {
      setIsSubmitting(true);
      useFetch
        .put(
          `/api/${selectedCompany}/drafted-bids/?bid_id=${editData?.id}`,
          formData
        )
        .then((res) => {
          setCreatedBid(res.data?.data?.id);
          toast({
            title: `Bid updated successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    } else {
      setIsSubmitting(true);
      useFetch
        .post(`/api/${selectedCompany}/store-bid/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setCreatedBid(res.data?.id);
          toast({
            title: `Bid created successfully`,
            description: "Bid will be in draft till confirmation",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setStep(5);
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: error.response.data?.detail || "Something Went wrong",
            description: "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        })
        .finally(() => setIsSubmitting(false));
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof FormValues, flattenedData[key]);
        }
      }
      changeSattusToDraft(selectedCompany, editData.id);
    }
  }, [editData]);
  const Money_In_Transit = +watch("Money_In_Transit");
  const Money_In_Safe = +watch("Money_In_Safe");
  const Money_In_Counter = +watch("Money_In_Counter");

  useEffect(() => {
    const sum = Money_In_Transit + Money_In_Safe + Money_In_Counter;
    setValue("sum_insured", sum);
  }, [Money_In_Transit, Money_In_Safe, Money_In_Counter]);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" gap="20px" w="100%">
        <SimpleGrid columns={2} gap="20px">
          <FormControl id="policytype" isInvalid={Boolean(errors?.policytype)}>
            <HoverableFormLabel>Policy Type*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("policytype", {
                required: "Policy Type is required",
              })}
              placeholder="Select policy type"
            >
              <option value="New">New</option>
              <option value="Renewal">Renewal</option>
            </Select>
            {errors?.policytype && (
              <Text color="red.500" fontSize="sm">
                {errors?.policytype?.message}
              </Text>
            )}
          </FormControl>

          {/* Bidder Preference */}
          <FormControl id="insurer" isInvalid={Boolean(errors?.insurer)}>
            <HoverableFormLabel>Bidder Preference*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("insurer", {
                required: "Bidder Preference is required",
              })}
              placeholder="Preference"
            >
              <option value="Both">Both</option>
              <option value="Brokers">Brokers</option>
              <option value="INS_COMP">Insurance Company</option>
            </Select>
            {errors?.insurer && (
              <Text color="red.500" fontSize="sm">
                {errors?.insurer?.message}
              </Text>
            )}
          </FormControl>

          {/* Where is your company located? */}
          <FormControl
            id="comp_location"
            isInvalid={Boolean(errors?.comp_location)}
          >
            <HoverableFormLabel>
              Where is your company located?*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("comp_location", {
                required: "Company Location is required",
              })}
            />
            {errors?.comp_location && (
              <Text color="red.500" fontSize="sm">
                {errors?.comp_location?.message}
              </Text>
            )}
          </FormControl>

          {/* Type of Transport */}
          <FormControl
            id="Type_of_Transport"
            isInvalid={Boolean(errors?.Type_of_Transport)}
          >
            <HoverableFormLabel>Type of Transport*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Type_of_Transport", {
                required: "Type of Transport is required",
              })}
              placeholder="Transport"
            >
              <option value="Taxi">Taxi</option>
              <option value="Own vehicle">Own vehicle</option>
              <option value="Two wheeler">Two wheeler</option>
            </Select>
            {errors?.Type_of_Transport && (
              <Text color="red.500" fontSize="sm">
                {errors?.Type_of_Transport?.message}
              </Text>
            )}
          </FormControl>

          {/* Distance of carrying money */}
          <FormControl
            id="Distance_of_carrying_money"
            isInvalid={Boolean(errors?.Distance_of_carrying_money)}
          >
            <HoverableFormLabel>
              Distance of carrying money (In KM)*
            </HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Distance_of_carrying_money", {
                required: "Distance is required",
              })}
            />
            {errors?.Distance_of_carrying_money && (
              <Text color="red.500" fontSize="sm">
                {errors?.Distance_of_carrying_money?.message}
              </Text>
            )}
          </FormControl>

          {/* Transit details */}
          <FormControl
            id="Transit_details"
            isInvalid={Boolean(errors?.Transit_details)}
          >
            <HoverableFormLabel>Transit details*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              {...register("Transit_details", {
                required: "Transit details are required",
              })}
            />
            {errors?.Transit_details && (
              <Text color="red.500" fontSize="sm">
                {errors?.Transit_details?.message}
              </Text>
            )}
          </FormControl>

          {/* Floater */}
          <FormControl id="Floater" isInvalid={Boolean(errors?.Floater)}>
            <HoverableFormLabel>Floater*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Floater", { required: "Floater is required" })}
              placeholder="Floater"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Floater && (
              <Text color="red.500" fontSize="sm">
                {errors?.Floater?.message}
              </Text>
            )}
          </FormControl>

          {/* Location 1 */}
          <FormControl id="Location1">
            <HoverableFormLabel>Location 1</HoverableFormLabel>
            <Input bg="white" borderRadius="21px" {...register("Location1")} />
          </FormControl>

          {/* Location 2 */}
          <FormControl id="Location2">
            <HoverableFormLabel>Location 2</HoverableFormLabel>
            <Input bg="white" borderRadius="21px" {...register("Location2")} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap="20px">
          <Text
            gridColumn="span 2"
            color="darkorchid"
            fontWeight="600"
            fontSize="16px"
            w="100%"
            borderTop="1px solid darkorchid"
          >
            Special Conditions
          </Text>
          <FormControl id="Security" isInvalid={Boolean(errors?.Security)}>
            <HoverableFormLabel>
              Security at premises out of business hours*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Security", { required: "Security is required" })}
              placeholder="Security"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Security && (
              <Text color="red.500" fontSize="sm">
                {errors?.Security?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            id="Alarm_System"
            isInvalid={Boolean(errors?.Alarm_System)}
          >
            <HoverableFormLabel>Alarm System*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Alarm_System", {
                required: "Alarm System is required",
              })}
              placeholder="Alarm System"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Alarm_System && (
              <Text color="red.500" fontSize="sm">
                {errors?.Alarm_System?.message}
              </Text>
            )}
          </FormControl>

          <FormControl id="CCTV" isInvalid={Boolean(errors?.CCTV)}>
            <HoverableFormLabel>CCTV*</HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("CCTV", { required: "CCTV is required" })}
              placeholder="CCTV availbility"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.CCTV && (
              <Text color="red.500" fontSize="sm">
                {errors?.CCTV?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            id="Security_of_doors"
            isInvalid={Boolean(errors?.Security_of_doors)}
          >
            <HoverableFormLabel>
              Is Security of doors and windows*
            </HoverableFormLabel>
            <Select
              bg="white"
              borderRadius="21px"
              {...register("Security_of_doors", {
                required: "Security of doors is required",
              })}
              placeholder="Security of doors"
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </Select>
            {errors?.Security_of_doors && (
              <Text color="red.500" fontSize="sm">
                {errors?.Security_of_doors?.message}
              </Text>
            )}
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap="20px">
          <Text
            gridColumn="span 2"
            color="darkorchid"
            fontWeight="600"
            fontSize="16px"
            w="100%"
            borderTop="1px solid darkorchid"
          >
            Risk Details
          </Text>
          <FormControl
            id="Money_In_Transit"
            isInvalid={Boolean(errors?.Money_In_Transit)}
          >
            <Flex align="start">
              <HoverableFormLabel> Money in Transit*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Protection of business money while it is being transported from one location to another."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Money_In_Transit", {
                required: "Money in Transit is required",
              })}
            />
            {errors?.Money_In_Transit && (
              <Text color="red.500" fontSize="sm">
                {errors?.Money_In_Transit?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            id="Money_In_Safe"
            isInvalid={Boolean(errors?.Money_In_Safe)}
          >
            <Flex align="start">
              <HoverableFormLabel> Money in safe*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="valuables that are securely stored in a locked safe"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Money_In_Safe", {
                required: "Money in Safe is required",
              })}
            />
            {errors?.Money_In_Safe && (
              <Text color="red.500" fontSize="sm">
                {errors?.Money_In_Safe?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            id="Money_In_Counter"
            isInvalid={Boolean(errors?.Money_In_Counter)}
          >
            <Flex align="start">
              <HoverableFormLabel> Money in counter*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Money or currency that is physically located at a certain place, like a point-of-sale counter or cash register"
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Money_In_Counter", {
                required: "Money in Counter is required",
              })}
            />
            {errors?.Money_In_Counter && (
              <Text color="red.500" fontSize="sm">
                {errors?.Money_In_Counter?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            id="Estimated_Annual_Turnover"
            isInvalid={Boolean(errors?.Estimated_Annual_Turnover)}
          >
            <HoverableFormLabel>Estimated Annual Turnover*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Estimated_Annual_Turnover", {
                required: "Annual Turnover is required",
              })}
            />
            {errors?.Estimated_Annual_Turnover && (
              <Text color="red.500" fontSize="sm">
                {errors?.Estimated_Annual_Turnover?.message}
              </Text>
            )}
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap="20px">
          <Text
            gridColumn="span 2"
            color="darkorchid"
            fontWeight="600"
            fontSize="16px"
            w="100%"
            borderTop="1px solid darkorchid"
          >
            Optional Coverage{" "}
            <span style={{ fontSize: "11px", color: "rgba(0, 0, 0, 0.5)" }}>
              (Sub limit)
            </span>
          </Text>
          <FormControl id="SRCC_Coverage">
            <HoverableFormLabel>SRCC Coverage</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("SRCC_Coverage")}
            />
          </FormControl>

          <FormControl id="Terrorism">
            <HoverableFormLabel>Terrorism</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("Terrorism")}
            />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap="20px">
          <Text
            gridColumn="span 2"
            color="darkorchid"
            fontWeight="600"
            fontSize="16px"
            w="100%"
            borderTop="1px solid darkorchid"
          >
            Policy Dates
          </Text>
          <FormControl id="start_date" isInvalid={Boolean(errors?.start_date)}>
            <HoverableFormLabel>Bid's Start Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("start_date", {
                required: "Start Date is required",
              })}
            />
            {errors?.start_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.start_date?.message}
              </Text>
            )}
          </FormControl>

          <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
            <HoverableFormLabel>Bid's End Date*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch('start_date')}
              {...register("end_date", { required: "End Date is required" })}
            />
            {errors?.end_date && (
              <Text color="red.500" fontSize="sm">
                {errors?.end_date?.message}
              </Text>
            )}
          </FormControl>

          <FormControl id="policy_start_date">
            <HoverableFormLabel>Policy Start Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={todayDate}
              {...register("policy_start_date")}
            />
          </FormControl>

          <FormControl id="policy_end_date">
            <HoverableFormLabel>Policy End Date</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="date"
              min={watch('policy_start_date')}
              {...register("policy_end_date")}
            />
          </FormControl>

          <FormControl
            id="expected_premium"
            isInvalid={Boolean(errors?.expected_premium)}
          >
            <Flex align="start">
              <HoverableFormLabel> Anticipated premium*</HoverableFormLabel>
              {mode === "Guided" && (
                <FaInfoCircle
                  fontSize="11px"
                  color="gray.500"
                  title="Anticipated premium for acquiring this policy."
                />
              )}
            </Flex>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("expected_premium", {
                required: "Anticipated premium is required",
              })}
            />
            {errors?.expected_premium && (
              <Text color="red.500" fontSize="sm">
                {errors?.expected_premium?.message}
              </Text>
            )}
          </FormControl>

          <FormControl
            id="sum_insured"
            isInvalid={Boolean(errors?.sum_insured)}
          >
            <HoverableFormLabel>Sum insured*</HoverableFormLabel>
            <Input
              bg="white"
              borderRadius="21px"
              type="number"
              {...register("sum_insured", {
                required: "Sum insured is required",
              })}
            />
            {errors?.sum_insured && (
              <Text color="red.500" fontSize="sm">
                {errors?.sum_insured?.message}
              </Text>
            )}
          </FormControl>
        </SimpleGrid>
        {watch("policytype") === "Renewal" && (
          <SimpleGrid columns={2} gap="20px">
            <FormControl
              id="previous_insurer"
              isInvalid={Boolean(errors?.previous_insurer)}
            >
              <HoverableFormLabel>Previous Insurer*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                {...register("previous_insurer", {
                  required: "Previous insurer is required",
                })}
              />
              {errors?.previous_insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_insurer?.message}
                </Text>
              )}
            </FormControl>

            <FormControl
              id="previous_policy_expiry"
              isInvalid={Boolean(errors?.previous_policy_expiry)}
            >
              <HoverableFormLabel>Previous policy expiry*</HoverableFormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="date"
                {...register("previous_policy_expiry", {
                  required: "Previous policy expiry is required",
                })}
              />
              {errors?.previous_policy_expiry && (
                <Text color="red.500" fontSize="sm">
                  {errors?.previous_policy_expiry?.message}
                </Text>
              )}
            </FormControl>
          </SimpleGrid>
        )}
        <Flex w="100%" justify="space-between" align="center" mt="20px">
          <Button
            type="button"
            borderRadius="21px"
            onClick={() => setStep(step - 1)}
          >
            Previous
          </Button>
          <Button
            type="submit"
            borderRadius="21px"
            colorScheme="green"
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Next
          </Button>
        </Flex>
      </Flex>
    </form>
  );
};

export default MoneyForm;
