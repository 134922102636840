import React, { useState } from "react";
import SanbotAI from "../../components/SanbotAI";
import { Flex, Image, Text } from "@chakra-ui/react";
import Clock from "../../partials/assets/images/clock.svg";
const Orchestration = () => {
  const [selectedBid, setSelectedBid] = useState({
    id: null,
    bidId: null,
    action: "",
  });
  return (
    <Flex height="100%" width="100%">
      <Flex
        direction="column"
        padding="30px 60px 30px 30px"
        flex={2}
        gap="10px"
      >
        <Flex align="center" w="100%" justify="space-between">
          <Text as="h2" fontSize="24px" fontWeight="500">
            Orchestration
          </Text>
        </Flex>
        <Flex w="100%" direction='column' align='center' justify="center" gap='14px' height='80%'>
          <Image src={Clock} w="auto" height="220px" />
          <Text fontSize='22px' fontWeight='600'>Coming Soon...</Text>
        </Flex>
      </Flex>
      {/* <SanbotAI selectedBid={selectedBid} setShowQuoteComparison={false} /> */}
    </Flex>
  );
};

export default Orchestration;
