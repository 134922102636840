import { Flex, Text, Button, Icon, useToast } from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

import { theme } from "../../partials/utils/Contants";
import { useStore } from "../../store/store";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

const CreateBidCard = ({
  item,
  selectedCategories,
  setSelectedCategories,
}: any) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { setStep } = useStore();
  const comingSoonList = [
    "Employee Deposit Linked Insurance",
    "Employee Liability Insurance",
    "Group Critical Illness Insurance",
    "Employee Wellness Programs",
    "Time Policy",
    "Group Travel",
    "Cyber Insurance",
  ];
  const handleSelectCategory = () => {
    if (comingSoonList.includes(item?.title)) {
      toast({
        title: "Coming soon.....",
        description: "",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    }
    //  else if (selectedCategories.includes(item?.title)) {
    //     setSelectedCategories(
    //       selectedCategories.filter((category: any) => category !== item?.title)
    //     );

    //   }
    else {
      setSelectedCategories([...selectedCategories, item?.title]);
      setStep(2);
      if (pathname !== "/create-bid") {
        navigate("/create-bid");
      }
    }
  };

  return (
    <Flex
      direction="column"
      height="220px"
      width="260px"
      className="create-bid-card-bg"
      borderRadius="12px"
      padding="13px 13px 16px 16px"
      //   gap="15px"
      justify="space-between"
    >
      <Flex direction="column" gap="8px">
        <Flex w="100%" justify="space-between" align="start">
          <Text
            fontSize="14px"
            fontWeight="500"
            color="rgba(46, 46, 46, 1)"
            w="210px"
            textAlign="start"
          >
            {item.title}
          </Text>

          {/* {selectedCategories?.includes(item.title) ? (
          <Icon
            as={FaCheckCircle}
            fontSize="21px"
            color={theme.color.GREEN}
            onClick={handleSelectCategory}
            cursor="pointer"
          />
        ) : (
          <Flex
            borderRadius="100px"
            bg="#D9D9D9"
            width="21px"
            height="21px"
            onClick={handleSelectCategory}
            cursor="pointer"
          />
        )} */}
        </Flex>
        <Text
          fontSize="10px"
          fontWeight="400"
          color="rgba(0, 0, 0, 0.5)"
          textAlign="start"
        >
          {item.desc}
        </Text>
      </Flex>
      <Flex w="100%" justify="space-between" align="center">
        {comingSoonList.includes(item.title) ? (
          <Flex
            fontSize="10px"
            fontWeight="500"
            borderRadius="12px"
            bg={theme.color.GREEN}
            color="#F6F6F6"
            padding="2px 4px"
            // w="75px"
            // height="18px"
            whiteSpace="nowrap"
          >
            Coming Soon
          </Flex>
        ) : (
          <Button
            leftIcon={<FaPlus />}
            borderRadius="24px"
            bg="black"
            colorScheme="black"
            color="white"
            padding="4px 14px"
            fontSize="12px"
            fontWeight="500"
            w="104px"
            h="24px"
            onClick={() => {
              handleSelectCategory();
            }}
          >
            Create Bid
          </Button>
        )}
        {/* <Text fontSize="9px" fontWeight="500"  color="black">
          30+ Bids
        </Text> */}
      </Flex>
    </Flex>
  );
};

export default CreateBidCard;
