import { productMapping } from "./Contants";

export const handleLogoutLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("data");
  localStorage.removeItem("profilepercentage");
  localStorage.clear();
};
export const flattenObject = (obj: any, res: any = {}) => {
  for (let key in obj) {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      flattenObject(obj[key], res);
    } else {
      res[key] = obj[key];
    }
  }
  return res;
};
export const addMissingFieldsToLabelMapping = (
  data: { [key: string]: any },
  labelMapping: { [key: string]: string }
) => {
  Object.keys(data).forEach((key) => {
    if (!(key in labelMapping)) {
      labelMapping[key] = key.replace(/_/g, " "); // Adds the key as label, replacing underscores with spaces
    }
  });
};
export const addCommas = (num: any) =>
  num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const removeNonNumeric = (num: any) =>
  num?.toString()?.replace(/[^0-9]/g, "");

export const convertProductName = (productName: string): string | null => {
  const reverseMapping: Record<string, string> = Object.fromEntries(
    Object.entries(productMapping).map(([beName, displayName]) => [
      displayName,
      beName,
    ])
  );

  if (productMapping[productName]) {
    return productMapping[productName];
  } else if (reverseMapping[productName]) {
    return reverseMapping[productName];
  } else {
    return productName;
  }
};
export const BEProductMap: Record<string, string> = {
  "Group Health Insurance": "GHI",
  // "Group Mediclaim": "GMC",
  "Group Personal Accident": "GPA",
  "Group Term Life": "GTL",
  "Standard Fire": "Standard Fire",
  Burglary: "Burglary",
  Money: "Money",
  "Commercial General Liability Insurance": "Commercial General Liability",
  "Contractors All Risk": "Contractor All Risk",
  "Contractors Plant and Machinery": "Contractor Plant And Machinery",
  "Directors & Offficers Insurance": "Directors & Officers Insurance",
  "Employees/Workmen Compensation": "Employee Compensation",
  "Lift Liability": "Lift Liability",
  "Professional Indemnity Insurance": "Professional Indemnity",
  "Specific Marine Insurance": "Marine",
};
