import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import "./App.css";
import useFetch from "./partials/utils/Interceptor/Interceptor";
import { useEffect } from "react";
import { useStore } from "./store/store";

import PublicRoutes from "./routes/PublicRoutes";
import { BidderRoutes } from "./routes/bidder-routes";
import { ClientRoutes } from "./routes/client-routes";
import { useFetchUser } from "./hooks/User";
import Cookies from "js-cookie";
import masterData from "../src/partials/utils/master-data.json";
import AcceptInvitation from "./pages/AcceptInvitation";

function App() {
  const { pathname } = useLocation();
  const user = useFetchUser();

  const {
    countries,
    setCountries,
    setProduct,
    setOccupancy,
    setBusinessType,
    setCompanyType,
  } = useStore();
  // const handleLogout = () => {
  //   Cookies.remove("jwt");
  //   Cookies.remove("user");
  //   window.location.href = "/login";
  // };
  useEffect(() => {
    if (!countries?.length && masterData) {
      setCountries(masterData?.country);
      setProduct(masterData?.product);
      setOccupancy(masterData?.occupancy);
      setBusinessType(masterData?.businesstype);
      setCompanyType(masterData?.companytype);
    }
  }, [masterData]);
  function fetchMasterData() {
    useFetch
      .get(`masterdata`)
      .then((res) => {})
      .catch((error) => {});
  }

  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="login" element={<Login />} />
        <Route path="login-bidder" element={<Login />} />
        <Route path="login-client" element={<Login />} />
        <Route path="accept-invitation" element={<AcceptInvitation />} />
      </Route>
      {user?.role === 3 || user?.role_id === 3 ? (
        <Route path="/*" element={<BidderRoutes />} />
      ) : (
        <Route path="/*" element={<ClientRoutes />} />
      )}
    </Routes>
  );
}

export default App;
