import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select as ChakraSelect,
  FormErrorMessage,
  Button,
  useToast,
  Image,
  Text,
  Icon,
} from "@chakra-ui/react";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import FullDarkLogo from "../../../partials/assets/images/full_logo_dark.svg";
import Cookies from "js-cookie";
import { useFetchUser } from "../../../hooks/User";
import {
  brokersCompany,
  InsuranceCompany,
} from "../../../partials/utils/BidCreation";
import Select from "react-select";
import "./setup.css";
import { useStore } from "../../../store/store";
import { IoArrowBackCircleOutline } from "react-icons/io5";
type FormValues = {
  name: string;
  company_name: string;
  email: string;
  mobile: string;
  location: string;
  country: string;
  company_type: string;
};
interface OptionType {
  label: string;
  value: string;
}
const SetUpProfile = () => {
  const user = useFetchUser();
  const navigate = useNavigate();
  const toast = useToast();
  const {
    register,
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormValues>();
  const { countries, companyType, bidderCompanies, fetchBidderCompanies } =
    useStore();
  const [id, setId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const filteredCompanyList: any = useMemo(() => {
    const companyList =
      watch("company_type") === "Insurance Company"
        ? bidderCompanies.insurance_companies
        : watch("company_type") === "Broker"
        ? bidderCompanies.broker_companies
        : [];
    return companyList.sort();
  }, [watch("company_type"), bidderCompanies]);

  const onSubmit = (data: any) => {
    // const excludedEmailDomains = /@(?!gmail\.com|ymail\.com|yahoo\.com)/;
    // if (
    //   !excludedEmailDomains.test(data.email)
    // ) {
    //   toast({
    //     title: `Invalid Email`,
    //     description: "Please Enter a Valid Work Email",
    //     status: "error",
    //     variant: "top-accent",
    //     isClosable: true,
    //   });

    //   return;
    // }
    const body = {
      type: data.company_type,
      contact_person_name: data.name,
      contact_number: data.mobile,
      country: data.country,
      location: data.location,
    };
    const companyId = filteredCompanyList.find(
      (c: any) => c?.c_name === data.company_name
    )?.id;
    setIsSubmitting(true);
    if (user?.company_name) {
      useFetch
        .put(`api/add-bidder-to-company/`, body)
        .then((res) => {
          Cookies.set("user", JSON.stringify(res?.data?.user));
          setIsSubmitting(false);
          localStorage.setItem("bidder_profiling", JSON.stringify(true));
          toast({
            title: `Profile Updated Successfuly`,
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          // navigate("/home");
        })
        .catch((error) => {
          setIsSubmitting(false);
          const msg = Array.isArray(error?.response?.data)
            ? error.response.data.join(", ")
            : "An unexpected error occurred.";
          toast({
            title: msg,
            description: "",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    } else {
      useFetch
        .post(`api/add-bidder-to-company/?company_id=${companyId}`, body)
        .then((res) => {
          Cookies.set("user", JSON.stringify(res?.data?.user));
          setIsSubmitting(false);
          localStorage.setItem("bidder_profiling", JSON.stringify(true));
          toast({
            title: `Profile Completed Successfuly`,
            description: "",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/home");
        })
        .catch((error) => {
          setIsSubmitting(false);
          console.log(error);
          const msg = Array.isArray(error?.response?.data)
            ? error.response.data.join(", ")
            : "An unexpected error occurred.";
          toast({
            title: msg,
            description: "",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    fetchBidderCompanies();
  }, []);
  useEffect(() => {
    if (user?.company_name) {
      const { company_name, company_type, country, location, mobile, name } =
        user;
      setValue("company_name", company_name);
      setValue("company_type", company_type);
      setValue("country", country);
      setValue("location", location);
      setValue("mobile", mobile);
      setValue("name", name);
      setValue("email", user?.email);
      setId(user?.id);
    } else if (user?.email && !user?.company_name) {
      setValue("email", user?.email);
      setId(user?.id);
    }
  }, [user]);

  return (
    <Flex
      w="100%"
      direction="row-reverse"
      height="100vh"
      className="login-gradient-bg"
      padding={{ base: "20px", lg: "0px" }}
    >
      <Flex
        width={{ base: "100%", lg: "45%" }}
        height="100%"
        direction="column"
        align="center"
        justify="center"
        gap="18px"
        bg="white"
        borderRadius={{ base: "24px", lg: "24px 0px 0px 24px" }}
        padding="14px"
      >
        {user?.company_name && (
          <Flex w="100%" gap="10px" align="center">
            <Icon
              as={IoArrowBackCircleOutline}
              onClick={() => navigate("/home")}
              fontSize="28px"
              cursor="pointer"
            />
            <Flex w="100%" justify="center">
              <Text fontWeight="bold" fontSize="18px">
                Edit Details
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex w="100%">
          <Image src={FullDarkLogo} height="71px" width="177px" />
        </Flex>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
          <Flex direction="column" gap="12px" align="center" px="16px">
            <FormControl isInvalid={Boolean(errors.name)}>
              <FormLabel htmlFor="name">Contact person name*</FormLabel>
              <Input
                id="name"
                type="name"
                placeholder="Enter Contact Person name"
                {...register("name", { required: "Name is required" })}
                borderRadius="21px"
              />
              {errors.name && (
                <FormErrorMessage>{errors.name.message}</FormErrorMessage>
              )}
            </FormControl>
            <Flex w="100%" gap="10px" align="center" justify="space-between">
              <FormControl isInvalid={Boolean(errors.mobile)}>
                <FormLabel htmlFor="mobile">Contact Number*</FormLabel>
                <Input
                  id="mobile"
                  type="number"
                  placeholder="Enter Contact Number"
                  {...register("mobile", {
                    required: "Mobile no. is required",
                    validate: (value) =>
                      String(value).length === 10 ||
                      "Phone number must be 10 digits long",
                  })}
                  borderRadius="21px"
                />
                {errors.mobile && (
                  <FormErrorMessage>{errors.mobile.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.email)}>
                <FormLabel htmlFor="email">Email*</FormLabel>
                <Input
                  type="email"
                  id="email"
                  placeholder="Enter email"
                  borderRadius="21px"
                  {...register("email", {
                    required: "Email is required",
                  })}
                  isDisabled
                />
                {errors.email && (
                  <FormErrorMessage>{errors.email.message}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex w="100%" gap="10px" align="center" justify="space-between">
              <FormControl isInvalid={Boolean(errors.company_type)}>
                <FormLabel htmlFor="role">Company Type*</FormLabel>
                <ChakraSelect
                  id="role"
                  borderRadius="21px"
                  isDisabled={user?.company_name}
                  placeholder="Select Company Type"
                  {...register("company_type", {
                    required: "Please Select Company Type",
                  })}
                >
                  <option value="Broker">Broker</option>
                  <option value="Insurance Company">Insurance Company</option>
                </ChakraSelect>
                {errors.company_type && (
                  <FormErrorMessage>
                    {errors.company_type.message}
                  </FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.company_name)}>
                <FormLabel htmlFor="company_name">Company Name*</FormLabel>
                <Controller
                  name="company_name"
                  control={control}
                  defaultValue=""
                  rules={{ required: "Please select a company name" }}
                  render={({ field }) => (
                    <Select<OptionType, false>
                      {...field}
                      placeholder="Search Company Name"
                      isDisabled={!watch("company_type") || user?.company_name}
                      isSearchable={true}
                      options={filteredCompanyList.map((q: any) => ({
                        label: q?.c_name,
                        value: q?.c_name,
                        id: q?.id,
                      }))}
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          borderRadius: "21px",
                          borderColor: errors.company_name
                            ? "red"
                            : provided.borderColor,
                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          textAlign: "start",
                        }),
                        menu: (provided) => ({
                          ...provided,
                          borderRadius: "21px",
                          background: "white",
                          textAlign: "start",
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          padding: "5px",
                        }),
                        singleValue: (provided) => ({
                          ...provided,
                          fontSize: "12px",
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: "11px",
                          padding: "5px",
                          textAlign: "start",
                        }),
                      }}
                      onChange={(option) =>
                        field.onChange(option ? option.value : "")
                      }
                      value={
                        filteredCompanyList.find(
                          (option: any) => option?.c_name === field.value
                        )
                          ? { label: field.value, value: field.value }
                          : null
                      }
                    />
                  )}
                />
                {errors.company_name && (
                  <FormErrorMessage>
                    {errors.company_name.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex w="100%" gap="10px" align="center" justify="space-between">
              <FormControl isInvalid={Boolean(errors.country)}>
                <FormLabel htmlFor="country">Country*</FormLabel>
                <ChakraSelect
                  id="country"
                  borderRadius="21px"
                  placeholder="Select country"
                  {...register("country", {
                    required: "Country is required",
                  })}
                >
                  {countries?.map((item: any) => (
                    <option key={item?.name} value={item?.name}>
                      {item?.name}
                    </option>
                  ))}
                </ChakraSelect>

                {errors.country && (
                  <FormErrorMessage>{errors.country.message}</FormErrorMessage>
                )}
              </FormControl>
              <FormControl isInvalid={Boolean(errors.location)}>
                <FormLabel htmlFor="location">Location</FormLabel>
                <Input
                  id="location"
                  type="name"
                  placeholder="Enter Location"
                  {...register("location")}
                  borderRadius="21px"
                />
                {errors.location && (
                  <FormErrorMessage>{errors.location.message}</FormErrorMessage>
                )}
              </FormControl>
            </Flex>
            <Flex w="100%" justify="end">
              <Button
                type="submit"
                w="fit-content"
                borderRadius="21px"
                colorScheme="green"
                mt="12px"
                isDisabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </form>
      </Flex>
    </Flex>
  );
};

export default SetUpProfile;
