import {
  Button,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Tab,
  TabIndicator,
  Table,
  TableContainer,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { theme } from "../../partials/utils/Contants";
import { CompanyType, useStore } from "../../store/store";
import { RiEditCircleLine } from "react-icons/ri";
import { FaRegTrashCan } from "react-icons/fa6";
import CreateCompanyFrom from "../../components/CreateCompany/CreateCompanyForm";
import { useLocation } from "react-router-dom";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { CiSearch } from "react-icons/ci";

const Profile = () => {
  const toast = useToast();
  const { search } = useLocation();
  const useQuery = () => {
    return new URLSearchParams(search);
  };
  const query = useQuery();
  const hasCompany = query.get("company");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [isCompanyLoading, setIsCompanyLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [editCompany, setEditCompany] = useState<CompanyType | null>(null);
  const { userCompanies, fetchUserCompanies } = useStore();
  const filteredData = useMemo(
    () =>
      searchText === ""
        ? [...userCompanies]
        : userCompanies.filter((team) =>
            team?.c_name?.toLowerCase().includes(searchText.toLowerCase())
          ),
    [userCompanies, searchText]
  );
  const handleDeleteCompany = (id: string | number) => {
    useFetch
      .delete(`/api/company/?company_id=${id}`)
      .then((res) => {
        fetchUserCompanies();
        toast({
          title: `Deleted Successfully.`,
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log("error", error);
        const msg =
          error?.response?.data?.join(",") || error?.response?.data?.error;
        toast({
          title: msg || `Something went wrong`,
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  useEffect(() => {
    fetchUserCompanies();
  }, []);
  useEffect(() => {
    if (hasCompany && hasCompany === "0") {
      onOpen();
    }
  }, [hasCompany]);
  return (
    <>
      <Flex
        w="100%"
        direction="column"
        gap="40px"
        bg="white"
        padding="20px"
        borderRadius="21px"
      >
        <Flex w="100%" justify="space-between" align="center">
          <Text fontSize="1.2rem" fontWeight="700" color="#25396f" ps="5px">
            Company Management
          </Text>
          <Button
            // rightIcon={<TbUserBolt />}
            borderRadius="24px"
            bg="black"
            colorScheme="black"
            color="white"
            // padding="4px 10spx"
            fontSize="14px"
            fontWeight="500"
            // w="142px"
            // h="24px"
            onClick={() => {
              onOpen();
              setEditCompany(null);
            }}
          >
            Add Company
          </Button>
        </Flex>
        {!isCompanyLoading ? (
          <Flex
            w="100%"
            direction="column"
            gap="40px"
            bg="white"
            padding="20px"
            borderRadius="21px"
          >
            <InputGroup>
              <InputLeftElement>
                <CiSearch />
              </InputLeftElement>
              <Input
                borderRadius="16px"
                type="text"
                w="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                placeholder="Search"
                fontSize="12px"
                fontWeight="500"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </InputGroup>
            <TableContainer borderRadius="21px" bg="gray.50">
              <Table variant="striped" colorScheme="gray">
                <Thead>
                  <Tr>
                    <Th>#</Th>
                    <Th>Name</Th>
                    <Th>Email</Th>
                    <Th>Contact Person Name</Th>
                    <Th>Action</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredData?.map((item: CompanyType, index: number) => {
                    return (
                      <Tr key={item?.id}>
                        <Td>{index + 1}</Td>
                        <Td>{item?.c_name}</Td>
                        <Td>{item?.c_email}</Td>
                        <Td>{item?.c_p_name}</Td>
                        <Td>
                          <Flex align="center" gap="10px">
                            <Icon
                              as={RiEditCircleLine}
                              fontSize="18px"
                              cursor="pointer"
                              onClick={() => {
                                setEditCompany(item);
                                onOpen();
                              }}
                            />
                            <Icon
                              as={FaRegTrashCan}
                              fontSize="18px"
                              cursor="pointer"
                              onClick={() => {
                                handleDeleteCompany(item?.id);
                              }}
                            />
                          </Flex>
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        ) : (
          <Flex w="100%" align="end" justify="center" h="30vh">
            {isCompanyLoading ? (
              <Spinner size="lg" />
            ) : (
              <Text>No Companies found</Text>
            )}
          </Flex>
        )}
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalHeader>{editCompany ? "Edit" : "Add"} Comapny</ModalHeader>
          {hasCompany && hasCompany === "0" ? "" : <ModalCloseButton />}
          <ModalBody>
            <CreateCompanyFrom
              editData={editCompany}
              isDisable={false}
              isBidMode={false}
              onClose={onClose}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Profile;
